import { Fragment, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import EventSquare from '../Calendars/EventSquare';

export default function CustomCombobox({ data, needIdx, slot }) {
  const [selected, setSelected] = useState(data[0]);
  const [query, setQuery] = useState('');

  const filteredData = query === ''
    ? data
    : data.filter(el => el.value
      .toLowerCase()
      .replace(/\s+/g, '')
      .includes(query.toLowerCase().replace(/\s+/g, '')));

  return (
    <div className="">
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <div
            className={`relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm ${needIdx >= slot.min_need ? 'opacity-60' : ''}`}
          >
            <Combobox.Input
              className="w-full border-none py-2 pl-0.5 text-sm leading-5 text-gray-900 focus:ring-0"
              displayValue={el => el.value}
              onChange={event => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-0.5">
              <ChevronUpDownIcon
                className="h-3 w-3 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm min-w-48">
              {filteredData.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredData.map(el => (
                  <Combobox.Option
                    key={el.id}
                    className={({ active }) => `relative cursor-default select-none py-2 pl-8 pr-4 ${active ? 'bg-teal-600/50 text-gray-900' : 'text-gray-900'}`}
                    value={el}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex flex-row items-center">
                          <span
                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                          >
                            {el.value}
                          </span>
                          <EventSquare
                            e={el.preference}
                            points={el.points}
                            justPoints
                            small
                          />
                        </div>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-1 ${active ? 'text-teal-600' : 'text-teal-600'}`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
