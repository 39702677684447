/* eslint-disable max-len */
import { Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { request } from '@youshift/shared/api';
import { classNames } from '@youshift/shared/utils';
import { getPersonnelQuery } from '@youshift/shared/hooks/jsQueries';

import Alert from '../FormFeedback/Alert';

export default function AssignRole({
  open,
  setOpen,
  setSuccess,
  user,
  setUser,
}) {
  const { t } = useTranslation();
  const cancelButtonRef = useRef(null);
  const [error, setError] = useState(false);
  const { data } = useQuery(getPersonnelQuery());
  const [selected, setSelected] = useState(
    data.personnel.filter(role => role.id !== -1).length === 0
      ? { id: -1, name: t('manager.team.assignRole.createRolesFirst') }
      : data.personnel.filter(role => role.id !== -1)[0],
  );

  const queryClient = useQueryClient();
  const assignRoleMutation = useMutation({
    mutationFn: data => request({
      url: `/manager/personnel/user/${user.id}/assign`,
      method: 'post',
      data,
    }),
    onSuccess: () => {
      queryClient.invalidateQueries(['personnel']);
      setSuccess(t('manager.team.assignRole.success'));
      setUser('');
      setError(null);
      setOpen(false);
    },
    onError: () => {
      setError(t('manager.team.assignRole.error'));
      setSuccess(null);
    },
  });

  const handleAssignRole = async () => {
    const data = { id_user_role: selected.id };
    assignRoleMutation.mutate(data);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilSquareIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
                    {!user.id_user_role ? (
                      <Dialog.Title
                        as="h3"
                        className="text-lg mb-5 mt-2 text-blue-600 font-semibold leading-6"
                      >
                        {t('manager.team.assignRole.assignDialog', {
                          firstName: user.firstname,
                          lastName: user.lastname,
                        })}
                      </Dialog.Title>
                    ) : (
                      <Dialog.Title
                        as="h3"
                        className="text-lg mb-5 mt-2 text-blue-600 font-semibold leading-6"
                      >
                        {t('manager.team.assignRole.changeDialog', {
                          firstName: user.firstname,
                          lastName: user.lastname,
                        })}
                      </Dialog.Title>
                    )}
                    <div className="mt-2">
                      <Listbox value={selected} onChange={setSelected}>
                        {({ open }) => (
                          <>
                            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                              {t('manager.team.assignRole.assignTo')}
                            </Listbox.Label>
                            <div className="relative mt-2">
                              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                                <span className="block truncate">
                                  {selected.name}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {data.personnel
                                    && data.personnel.filter(
                                      role => role.id !== -1,
                                    ).length !== 0 ? (
                                    data.personnel
                                      .filter(role => role.id !== -1)
                                      .map(role => (
                                        <Listbox.Option
                                          key={role.id}
                                          className={({ active }) => classNames(
                                            active
                                              ? 'bg-blue-600 text-white'
                                              : 'text-gray-900',
                                            'relative cursor-default select-none py-2 pl-8 pr-4',
                                          )}
                                          value={role}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? 'font-semibold'
                                                    : 'font-normal',
                                                  'block truncate',
                                                )}
                                              >
                                                {role.name}
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? 'text-white'
                                                      : 'text-blue-600',
                                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))
                                  ) : (
                                    <Listbox.Option
                                      key={-1}
                                      className={classNames(
                                        'relative cursor-default select-none py-2 pl-8 pr-4',
                                      )}
                                      value={-1}
                                    >
                                      <span
                                        className={classNames(
                                          'font-normal',
                                          'block truncate',
                                        )}
                                      >
                                        {t(
                                          'manager.team.assignRole.createRolesFirst',
                                        )}
                                      </span>
                                    </Listbox.Option>
                                  )}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                  </div>
                </div>
                {error ? <Alert success={false} text={error} /> : null}
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                    onClick={handleAssignRole}
                  >
                    {t('manager.team.assignRole.assign')}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setOpen(false);
                    }}
                    ref={cancelButtonRef}
                  >
                    {t('generic.cancel')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
