/* eslint-disable react/require-default-props */
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { SlotLabel } from '@youshift/shared/types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { formatSlotDuration } from '@youshift/shared/utils';

import { YSButton } from '../Buttons';
import { ShiftLabelSelect } from './ShiftLabelSelect';
import Alert from '../FormFeedback/Alert';

// When daysOfWeek/setDaysOfWeek/days are present we are creating a slot in a template
export type SlotDetailsBaseProps = {
  selectedLabelId: number | null;
  setSelectedLabelId: (id: number | null) => void;
  daysOfWeek?: boolean[] | undefined;
  setDaysOfWeek?: ((days: boolean[]) => void) | undefined;
  startTime: string;
  setStartTime: (value: string) => void;
  endTime: string;
  setEndTime: (value: string) => void;
  minPeople: number;
  setMinPeople: (value: number) => void;
  maxPeople: number;
  setMaxPeople: (value: number) => void;
  restHours: number;
  setRestHours: (value: number) => void;
  shiftLabels: SlotLabel[];
  buttonDisabled: boolean;
  days?: string[] | undefined;
  duration: number;
  setExtra24h?: (extra24: number) => void;
  onSubmit: () => void;
  submitButtonLabel: string;
  error: string | boolean;
  success: string | boolean;
};

export default function SlotDetailsBase({
  selectedLabelId,
  setSelectedLabelId,
  daysOfWeek = undefined,
  setDaysOfWeek = undefined,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  minPeople,
  setMinPeople,
  maxPeople,
  setMaxPeople,
  restHours,
  setRestHours,
  shiftLabels,
  buttonDisabled,
  days = undefined,
  duration,
  onSubmit,
  setExtra24h = () => {},
  submitButtonLabel,
  error,
  success,
}: SlotDetailsBaseProps) {
  const { t } = useTranslation();

  const [isCustomLabel, setIsCustomLabel] = useState(false);
  const [newLabelName, setNewLabelName] = useState('');

  const creatingTemplate = days && daysOfWeek && setDaysOfWeek;

  const resetState = () => {
    setSelectedLabelId(null);
    setStartTime('');
    setEndTime('');
    setMinPeople(0);
    setMaxPeople(0);
    setRestHours(0);
    setIsCustomLabel(false);
    setNewLabelName('');
    if (daysOfWeek && setDaysOfWeek) {
      setDaysOfWeek([false, false, false, false, false, false, false]);
    }
  };

  const toggleDay = (index: number) => {
    if (daysOfWeek && setDaysOfWeek) {
      const updatedDays = [...daysOfWeek];
      updatedDays[index] = !updatedDays[index];
      setDaysOfWeek(updatedDays);
    }
  };

  return (
    <>
      <div className="grid grid-cols-2 p-4 mx-auto bg-white shadow-md rounded-lg gap-2">
        {/* Shift Label Selection */}
        <div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">
              {t('manager.servicesConfig.shiftSlotName')}
            </label>
            <p className="text-xxs text-gray-500">
              {t('manager.servicesConfig.shiftLabelDescription')}
            </p>
            <ShiftLabelSelect
              shiftLabels={shiftLabels}
              selectedLabelId={selectedLabelId}
              setSelectedLabelId={setSelectedLabelId}
              isCustomLabel={isCustomLabel}
              setIsCustomLabel={setIsCustomLabel}
              newLabelName={newLabelName}
              setNewLabelName={setNewLabelName}
            />
          </div>
          {/* Time Inputs */}
          <div className="mb-4">
            <label className="block text-gray-700">
              {t('manager.servicesConfig.startAndEndTime')}
            </label>
            <div className="flex space-x-2 items-center">
              {creatingTemplate ? (
                <>
                  <input
                    type="time"
                    value={startTime}
                    onChange={e => setStartTime(e.target.value)}
                    className="mt-1 p-2 border rounded"
                  />
                  <ArrowRightIcon className="w-5 h-5" />
                  <input
                    type="time"
                    value={endTime}
                    onChange={e => setEndTime(e.target.value)}
                    className="mt-1 p-2 border rounded"
                  />
                </>
              ) : (
                <>
                  {/* TODO: timeZone, review the escaping colon, add +24,-24, review duration, etc */}
                  <input
                    type="datetime-local"
                    value={startTime}
                    onChange={e => setStartTime(e.target.value)}
                    className="mt-1 p-2 border rounded"
                  />
                  <ArrowRightIcon className="w-5 h-5" />
                  <input
                    type="datetime-local"
                    value={endTime}
                    onChange={e => setEndTime(e.target.value)}
                    className="mt-1 p-2 border rounded"
                  />
                </>
              )}
              <p className="text-xs font-semibold">
                {formatSlotDuration(duration)}
              </p>
            </div>
          </div>
        </div>
        <div>
          {/* Min/Max People */}
          <div className="mb-4">
            <label className="block text-gray-700 mb-5">
              {t('manager.servicesConfig.minMaxShiftSlot')}
            </label>
            <div className="flex space-x-2">
              <input
                type="number"
                min={0}
                value={minPeople}
                onChange={e => setMinPeople(Number(e.target.value))}
                className="mt-1 p-2 border rounded w-16"
                placeholder="min"
              />
              <input
                type="number"
                min={0}
                value={maxPeople}
                onChange={e => setMaxPeople(Number(e.target.value))}
                className="mt-1 p-2 border rounded w-16"
                placeholder="max"
              />
            </div>
          </div>

          {/* Rest Hours */}
          <div className="mb-4">
            <label className="block text-gray-700">
              {t('manager.servicesConfig.rest')}
            </label>
            <p className="text-xxs text-gray-500">
              {t('manager.servicesConfig.restDescription')}
            </p>
            <input
              type="number"
              value={restHours}
              min={0}
              onChange={e => setRestHours(Number(e.target.value))}
              className="mt-1 p-2 border rounded w-16"
            />
            <span className="ml-2">h</span>
          </div>
        </div>

        {/* Day Picker */}
        {creatingTemplate ? (
          <div className="mb-4">
            <label className="block text-gray-700">
              {t('manager.servicesConfig.selectDays')}
            </label>
            <div className="flex space-x-2">
              {days.map((day, index) => (
                <button
                  key={index}
                  onClick={() => toggleDay(index)}
                  className={`w-10 h-10 flex items-center justify-center border rounded ${
                    daysOfWeek[index]
                      ? 'bg-blue-500 text-white'
                      : 'bg-white text-blue-500'
                  }`}
                >
                  {day}
                </button>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      {error ? <Alert text={error} success={false} /> : null}
      {success ? <Alert text={success} success /> : null}
      <YSButton
        variant="primary"
        onClick={() => {
          onSubmit();
          resetState();
        }}
        disabled={buttonDisabled}
        classNames="w-full my-2"
      >
        {submitButtonLabel}
      </YSButton>
    </>
  );
}
