import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowPathRoundedSquareIcon,
  Bars3Icon,
  BuildingOffice2Icon,
  CalendarIcon,
  ChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  UserCircleIcon,
  UserIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link, NavLink, Outlet, useNavigation,
} from 'react-router-dom';
import { request } from '@youshift/shared/api';
import { loadUserQuery } from '@youshift/shared/hooks/jsQueries';

import logo from '../assets/Logo/youshift_bubbles.png';
import { requireManager } from '../utils/checks';

export const managerLoader = queryClient => async () => {
  const user = await requireManager(queryClient);
  // await queryClient.fetchQuery(['completedTutorials'], () => request({ url: '/fetch_tutorial_status' }));
  return user;
};

export default function ManagerLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { data: user } = useQuery(loadUserQuery());
  const user_is_admin = user?.is_manager >= 80;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigation = [
    {
      id: 'dashboard',
      name: t('manager.navigation.home'),
      href: 'dashboard',
      icon: HomeIcon,
    },
    {
      id: 'team',
      name: t('manager.navigation.team'),
      href: 'team',
      icon: UsersIcon,
    },
    {
      id: 'chains',
      name: t('manager.navigation.iterations'),
      href: 'chains',
      icon: CalendarIcon,
    },
    {
      id: 'exchange',
      name: t('generic.shiftExchange.title'),
      href: 'exchange',
      icon: ArrowPathRoundedSquareIcon,
    },
    {
      id: 'stats',
      name: t('generic.stats'),
      href: 'stats',
      icon: ChartBarIcon,
    },
    {
      id: 'help',
      name: t('manager.navigation.help'),
      href: 'help',
      icon: QuestionMarkCircleIcon,
    },
    {
      id: 'user',
      name: t('manager.navigation.userProfile'),
      href: '/user',
      icon: UserIcon,
    },
  ];

  const navigationState = useNavigation();

  const exitImpersonateManagerMutation = useMutation({
    mutationFn: () => request({ url: '/admin/exit_impersonate_manager', method: 'post' }),
    onSuccess: () => {
      queryClient.invalidateQueries(['user']);
    },
  });

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-blue-700">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <Link to="/" className="flex flex-shrink-0 items-center px-4">
                    <img className="w-auto" src={logo} alt="YouShift" />
                  </Link>
                  <nav className="mt-5 space-y-1 px-2">
                    {navigation.map(item => (
                      <NavLink
                        to={item.href}
                        key={item.name}
                        className="[&.active]:bg-blue-800 text-white hover:bg-blue-600 hover:bg-opacity-75 group flex items-center rounded-md px-2 py-2 text-base font-medium"
                      >
                        <item.icon
                          className="mr-4 h-6 w-6 flex-shrink-0 text-blue-300"
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    ))}
                  </nav>
                </div>
                <BuildingOffice2Icon className="text-white h-9" />
                <p className="text-center mb-11 font-semibold text-white text-lg">
                  {user?.org.name}
                </p>
                <div className="flex flex-shrink-0 border-t border-blue-800 p-4">
                  <NavLink to="profile" className="group block flex-shrink-0">
                    <div className="flex items-center">
                      <div>
                        <UserCircleIcon className="inline-block h-9 w-9 rounded-full bg-white text-blue-600" />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-white">{`${user?.firstname} ${user?.lastname}`}</p>
                        <p className="text-sm font-medium text-blue-200 group-hover:text-white">
                          {t('manager.navigation.seeProfile')}
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-20 lg:flex-col group hover:w-64 transition-all duration-300 ease-in-out z-50">
        <div className="flex flex-shrink-0 items-center w-20 lg:w-20 px-4 my-3">
          <Link to="/" className="flex items-center justify-center w-full">
            <img
              className="h-auto w-full max-w-full"
              src={logo}
              alt="YouShift"
            />
          </Link>
        </div>
        <div className="flex min-h-0 flex-1 flex-col bg-gradient-to-b from-blue-700 to-blue-400">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <nav className="mt-5 flex-1 space-y-1 px-2">
              {navigation.map(item => {
                const isPending = navigationState.state === 'loading'
                  && navigationState.location.pathname === '/manager/stats';
                return (
                  <NavLink
                    to={item.href}
                    key={item.name}
                    className="[&.active]:bg-blue-800 [&.pending]:bg-blue-700 text-white hover:bg-blue-600 hover:bg-opacity-75 group flex items-center rounded-md px-2 py-2 text-base font-medium transition-all duration-300 ease-in-out"
                  >
                    <item.icon
                      className="mr-4 h-6 w-6 flex-shrink-0 text-blue-300"
                      aria-hidden="true"
                    />
                    <span className="hidden group-hover:inline text-nowrap">
                      {item.name}
                    </span>
                    {isPending && item.href === 'stats' ? (
                      <svg
                        className="animate-spin ml-4 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    ) : null}
                  </NavLink>
                );
              })}
              {user_is_admin ? (
                <NavLink
                  to="/admin"
                  onClick={() => {
                    exitImpersonateManagerMutation.mutate();
                  }}
                  className="[&.active]:bg-blue-800 text-white hover:bg-blue-600 hover:bg-opacity-75 group flex items-center rounded-md px-2 py-2 text-base font-medium transition-all duration-300 ease-in-out"
                >
                  <WrenchScrewdriverIcon
                    className="mr-4 h-6 w-6 flex-shrink-0 text-blue-300"
                    aria-hidden="true"
                  />
                  <span className="hidden group-hover:inline">
                    Portal Admin
                  </span>
                </NavLink>
              ) : null}
            </nav>
          </div>
          <div className="hidden group-hover:flex flex-col items-center">
            <BuildingOffice2Icon className="text-white h-9" />
            <p className="text-center mb-11 font-semibold text-white text-lg text-nowrap">
              {user?.org.name}
            </p>
          </div>
          <div className="flex flex-shrink-0 border-t border-white-800 p-4">
            <NavLink to="profile" className="group block w-full flex-shrink-0">
              <div className="flex items-center">
                <div>
                  <UserCircleIcon className="inline-block h-9 w-9 rounded-full bg-white text-blue-600" />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-white mb-1 hidden group-hover:block text-nowrap">{`${user?.firstname} ${user?.lastname}`}</p>
                  {/* <p className="text-sm font-medium text-white mb-1">Jota Chamorro</p> */}
                  <p className="text-xs font-medium text-blue-200 group-hover:text-white hidden group-hover:block">
                    {t('manager.navigation.seeProfile')}
                  </p>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col lg:pl-24">
        <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            onClick={() => setSidebarOpen(true)}
          >
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1">
          {/* removed this class from the div below to allow for more content max-w-7xl */}
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}
