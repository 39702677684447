import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { classNames } from '@youshift/shared/utils';

import EditPreferenceByManager from '../../../components/Calendars/EditPreferenceByManager';
import EventSquare from '../../../components/Calendars/EventSquare';

const { v4: uuidv4 } = require('uuid');

// import EventSquare from './EventSquare'

function PeopleRecordedData({ itrPrefs, slotPrefs, shifts }) {
  const [selectedPerson, setSelectedPerson] = useState(itrPrefs[0].id_user);
  const [preference, setPreference] = useState(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  function toJustify() {
    const filteredSlots = { events: [], shifts: [] };
    for (const slot of slotPrefs) {
      const userSlot = slot.slot_prefs[selectedPerson];
      if (userSlot?.preference === 'E') {
        filteredSlots.events.push({
          start: slot.start,
          justification: userSlot.justification,
          id_slot_pref: userSlot.id_slot_pref,
        });
      }
      if (userSlot?.preference === 'S') {
        filteredSlots.shifts.push({
          start: slot.start,
          other_shift_id: userSlot.other_shift_id,
          id_slot_pref: userSlot.id_slot_pref,
        });
      }
    }
    return filteredSlots;
  }
  const { events: justifiedEvents, shifts: selectedShifts } = toJustify();

  const adjustWeekDay = shifts_needs => {
    const weekdayAbbreviations = {
      Mon: 0,
      Tue: 1,
      Wed: 2,
      Thu: 3,
      Fri: 4,
      Sat: 5,
      Sun: 6,
    };

    const startWeekDay = shifts_needs[0].start.substring(0, 3);
    const endWeekDay = shifts_needs[shifts_needs.length - 1].start.substring(
      0,
      3,
    );
    const startCount = weekdayAbbreviations[startWeekDay];
    const endCount = 6 - weekdayAbbreviations[endWeekDay];
    const fillersStart = Array(startCount)
      .fill(null)
      .map(() => ({
        id_slot: uuidv4(),
        start: '',
      }));

    const fillersEnd = Array(endCount)
      .fill(null)
      .map(() => ({
        id_slot: uuidv4(),
        start: '',
      }));

    const adjustedShiftsNeeds = [
      ...fillersStart,
      ...shifts_needs,
      ...fillersEnd,
    ];
    return adjustedShiftsNeeds;
  };

  function sortRows(itrPrefs) {
    // Clone the itrPrefs array to avoid modifying the original array
    const sortedArray = [...itrPrefs];

    // Sort the array based on the custom sorting logic
    sortedArray.sort((a, b) => {
      if (a.id_user == selectedPerson) return -1;
      if (b.id_user == selectedPerson) return 1;

      const nameA = `${a.user.firstname} ${a.user.lastname}`.toLowerCase();
      const nameB = `${b.user.firstname} ${b.user.lastname}`.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    return sortedArray;
  }

  function changeSelectedPerson(direction) {
    // Find the index of the currently selected person
    const currentIndex = itrPrefs.findIndex(
      person => person.id_user === selectedPerson,
    );
    // Calculate the new index based on the direction
    let newIndex;
    if (direction === 'next') {
      newIndex = (currentIndex + 1) % itrPrefs.length;
    } else if (direction === 'previous') {
      newIndex = (currentIndex - 1 + itrPrefs.length) % itrPrefs.length;
    } else {
      throw new Error(`Invalid direction: ${direction}`);
    }
    // Set the selected person to the person at the new index
    setSelectedPerson(itrPrefs[newIndex].id_user);
  }

  return itrPrefs.length ? (
    <div className="px-4 sm:px-6 lg:px-8">
      {open ? (
        <EditPreferenceByManager
          open={open}
          setOpen={setOpen}
          shifts={shifts}
          preference={preference}
        />
      ) : null}
      <div className="flex flex-row items-center justify-center gap-2">
        <button onClick={() => changeSelectedPerson('previous')}>
          <ArrowLeftIcon className="h-5 w-5 text-blue-600" />
        </button>
        <select
          id="week"
          name="week"
          className="block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
          value={selectedPerson}
          onChange={e => setSelectedPerson(parseInt(e.target.value, 10))}
        >
          {itrPrefs
            .sort((a, b) => `${a.user.firstname} ${a.user.lastname}`.localeCompare(
              `${b.user.firstname} ${b.user.lastname}`,
            ))
            .map(itrPref => (
              <option key={itrPref.id_user} value={itrPref.id_user}>
                {`${itrPref.user.firstname} ${itrPref.user.lastname}`}
              </option>
            ))}
        </select>
        <button onClick={() => changeSelectedPerson('next')}>
          <ArrowRightIcon className="h-5 w-5 text-blue-600" />
        </button>
      </div>
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col mt-8">
        <div
          className="hidden lg:grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200
        text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none"
        >
          <div className="bg-white py-2">
            <Trans i18nKey="calendars.mon">
              L
              <span className="sr-only sm:not-sr-only">unes</span>
            </Trans>
          </div>
          <div className="bg-white py-2">
            <Trans i18nKey="calendars.tue">
              M
              <span className="sr-only sm:not-sr-only">artes</span>
            </Trans>
          </div>
          <div className="bg-white py-2">
            <Trans i18nKey="calendars.wed">
              M
              <span className="sr-only sm:not-sr-only">iércoles</span>
            </Trans>
          </div>
          <div className="bg-white py-2">
            <Trans i18nKey="calendars.thu">
              J
              <span className="sr-only sm:not-sr-only">ueves</span>
            </Trans>
          </div>
          <div className="bg-white py-2">
            <Trans i18nKey="calendars.fri">
              V
              <span className="sr-only sm:not-sr-only">iernes</span>
            </Trans>
          </div>
          <div className="bg-white py-2">
            <Trans i18nKey="calendars.sat">
              S
              <span className="sr-only sm:not-sr-only">ábado</span>
            </Trans>
          </div>
          <div className="bg-white py-2">
            <Trans i18nKey="calendars.sun">
              D
              <span className="sr-only sm:not-sr-only">omingo</span>
            </Trans>
          </div>
        </div>
        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
          <div
            className={`w-full lg:grid lg:grid-cols-7 lg:grid-rows-${Math.ceil(slotPrefs.length / 7)} lg:gap-px`}
          >
            {adjustWeekDay(slotPrefs).map(slot => {
              const personSlot = slot.start
                ? slot.slot_prefs[selectedPerson]
                : null;
              return (
                <div
                  key={slot.id_slot}
                  className={classNames(
                    slot.start ? 'bg-white' : 'bg-gray-50 text-gray-500',
                    'relative py-2 px-3 flex flex-col justify-around',
                  )}
                >
                  {slot.start ? (
                    <div className="flex flex-row justify-between">
                      <time dateTime={slot.start}>
                        {slot.start.slice(5, 7)}
                      </time>
                      <button
                        type="button"
                        onClick={() => {
                          setPreference({
                            event: personSlot.preference.toUpperCase(),
                            points: personSlot.points,
                            justification: personSlot.justification,
                            idSlot: slot.id_slot,
                            idShift: personSlot.other_shift_id,
                            idUser: selectedPerson,
                          });
                          setOpen(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 text-gray-300"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </button>
                    </div>
                  ) : null}

                  <div className="flex flex-row justify-center">
                    {slot.start ? (
                      <EventSquare
                        e={personSlot?.preference.toUpperCase()}
                        points={personSlot.points}
                        justPoints
                      />
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {itrPrefs.find(itrPref => itrPref.id_user === Number(selectedPerson))
        ?.comment ? (
        <div className="pointer-events-auto flex items-center justify-between gap-x-6 my-3 bg-blue-600 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
          <p className="text-sm leading-6 text-white">
            <strong className="font-semibold">{t('generic.comments')}</strong>
            <svg
              viewBox="0 0 2 2"
              className="mx-2 inline h-0.5 w-0.5 fill-current"
              aria-hidden="true"
            >
              <circle cx={1} cy={1} r={1} />
            </svg>
            {
              itrPrefs.find(
                itrPref => itrPref.id_user === Number(selectedPerson),
              )?.comment
            }
          </p>
        </div>
      ) : null}
      {justifiedEvents.map(event => {
        const date = new Date(event.start);
        return (
          <div className="mb-3">
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <EventSquare
                  e="E"
                  date={date.toLocaleString(undefined, {
                    day: '2-digit',
                    month: '2-digit',
                    timeZone: 'GMT',
                  })}
                />
              </div>
              <input
                type="event"
                name="event"
                id="event"
                className="block w-full rounded-md border-0 py-1.5 pl-28 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={event.justification}
                disabled
              />
            </div>
          </div>
        );
      })}

      {selectedShifts.map(event => {
        const date = new Date(event.start);
        return (
          <div className="mb-3">
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <EventSquare
                  e="S"
                  date={date.toLocaleString(undefined, {
                    day: '2-digit',
                    month: '2-digit',
                    timeZone: 'GMT',
                  })}
                />
              </div>
              <input
                type="shift"
                name="shift"
                id="shift"
                className="block w-full rounded-md border-0 py-1.5 pl-28 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                value={
                  shifts.find(s => s.id_shift === event.other_shift_id).name
                }
                disabled
              />
            </div>
          </div>
        );
      })}
      <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-blue-600 sm:pl-6"
              >
                {t('generic.firstName')}
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-blue-600 lg:table-cell"
              >
                {t('generic.role')}
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-blue-600 lg:table-cell"
              >
                {t('generic.dobletes')}
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-blue-600 lg:table-cell"
              >
                {t('manager.recordedData.avoidSameDay')}
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-blue-600"
              >
                {t('manager.recordedData.volunteer')}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortRows(itrPrefs).map((itrPref, planIdx) => (
              <tr
                key={itrPref.id_user}
                className={
                  itrPref.id_user === Number(selectedPerson)
                    ? 'bg-teal-300/25'
                    : ''
                }
              >
                <td
                  className={classNames(
                    planIdx === 0 ? '' : 'border-t border-transparent',
                    'relative py-4 pl-4 pr-3 text-sm sm:pl-6',
                  )}
                >
                  <div className="font-medium text-gray-900 ">
                    {`${itrPref.user.firstname} ${itrPref.user.lastname}`}
                  </div>
                  {planIdx !== 0 ? (
                    <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                  ) : null}
                </td>
                <td
                  className={classNames(
                    planIdx === 0 ? '' : 'border-t border-gray-200',
                    'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell',
                  )}
                >
                  {itrPref.user.role.name}
                </td>
                <td
                  className={classNames(
                    planIdx === 0 ? '' : 'border-t border-gray-200',
                    'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell',
                  )}
                >
                  {itrPref.P_101}
                </td>
                <td
                  className={classNames(
                    planIdx === 0 ? '' : 'border-t border-gray-200',
                    'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell',
                  )}
                >
                  {itrPref.P_77 ? t('generic.yes') : t('generic.no')}
                </td>
                <td
                  className={classNames(
                    planIdx === 0 ? '' : 'border-t border-gray-200',
                    'px-3 py-3.5 text-sm text-gray-500',
                  )}
                >
                  {itrPref.volunteer ? t('generic.yes') : t('generic.no')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <div className="text-center">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 48 48"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
      <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">
        {t('manager.recordedData.emptyStateTitle')}
      </h2>
      <p className="mt-1 text-sm text-gray-500">
        {t('manager.recordedData.emptyStateSubtitle')}
      </p>
    </div>
  );
}

export default PeopleRecordedData;
