import { Disclosure, Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import {
  ArrowPathIcon,
  CheckBadgeIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteLoaderData } from 'react-router-dom';
import { request } from '@youshift/shared/api';
import { getPersonnelQuery } from '@youshift/shared/hooks/jsQueries';

import Alert from '../../../components/FormFeedback/Alert';
import AssignRole from '../../../components/Team/AssignRole';
import ChangeFakeUserName from '../../../components/Team/ChangeFakeUserName';
import ConfirmDeleteModal from '../../../components/Team/ConfirmDeleteFakeUser';
import ConvertFakeUser from '../../../components/Team/ConvertFakeUser';
import CreateFakeUsers from '../../../components/Team/CreateFakeUsers';
import DeleteUser from '../../../components/Team/DeleteUser';
import InvitePersonnel from '../../../components/Team/InvitePersonnel';
import RevokeMembership from '../../../components/Team/RevokeMembership';
import ValidateUser from '../../../components/Team/ValidateUser';

export default function Personnel() {
  const { data } = useQuery(getPersonnelQuery());

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const thisManager = useRouteLoaderData('manager');

  const accessibleUsers = [];
  const notAccessibleUsers = [];
  data.personnel.forEach(role => {
    role.users.forEach(listed_user => {
      if (thisManager.id in data.groups[listed_user.group.id_group].managers) {
        accessibleUsers.push({ role, listed_user });
      } else {
        notAccessibleUsers.push({ role, listed_user });
      }
    });
  });

  const [user, setUser] = useState({});

  const [invitePersonnelOpen, setInvitePersonnelOpen] = useState(false);
  const [createFakeUsersOpen, setCreateFakeUsersOpen] = useState(false);
  const [editFakeUserNameOpen, setEditFakeUserNameOpen] = useState(null);
  const [convertFakeUserOpen, setConvertFakeUserOpen] = useState(null);
  const [deleteuser, setDeleteUserOpen] = useState(false);
  const [assignrole, setAssignRoleOpen] = useState(false);
  const [validateuser, setValidateUserOpen] = useState(false);
  const [revokeMembershipOpen, setRevokeMembershipOpen] = useState(false);
  const [userToBeRevoked, setUserToBeRevoked] = useState(null);

  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const { t } = useTranslation();

  const verifyUser = async id_user => request({
    url: `/manager/personnel/user/${id_user}/verify`,
    method: 'post',
  });
  const deleteFakeUser = async user => request({
    url: `/manager/personnel/user/mock/${user.id}/delete`,
    method: 'delete',
  });
  const revokeMembership = async () => request({
    url: `/manager/personnel/user/${userToBeRevoked}/revoke_membership`,
    method: 'post',
  });

  const queryClient = useQueryClient();
  const verifyUserMutation = useMutation({
    mutationFn: verifyUser,
    onSuccess: () => {
      queryClient.invalidateQueries(['personnel']);
      setSuccess(t('manager.team.personnel.acceptedUser'));
      setUser({});
    },
    onError: () => {
      setError(t('manager.team.personnel.verifyUserError'));
      setSuccess(null);
    },
  });

  const deleteFakeUserMutation = useMutation({
    mutationFn: deleteFakeUser,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['personnel']);
      setSuccess(
        t('manager.team.personnel.deleteFakeUserSuccess', {
          firstName: variables.firstname,
        }),
      );
      setConfirmDeleteOpen(false);
    },
    onError: (data, variables) => {
      setSuccess(null);
      setError(
        t('manager.team.personnel.deleteFakeUserError', {
          firstName: variables.firstname,
        }),
      );
    },
  });

  const revokeMembershipMutation = useMutation({
    mutationFn: revokeMembership,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['personnel']);
      setSuccess(
        t('manager.team.personnel.revokeMembershipSuccess', {
          firstName: variables.firstname,
        }),
      );
      setRevokeMembershipOpen(false);
    },
    onError: (data, variables) => {
      setSuccess(null);
      setError(
        t('manager.team.personnel.revokeMembershipError', {
          firstName: variables.firstname,
        }),
      );
    },
  });

  return (
    <div className="mt-6 px-4 sm:px-6 lg:px-8">
      <InvitePersonnel
        open={invitePersonnelOpen}
        setOpen={setInvitePersonnelOpen}
        setSuccess={setSuccess}
      />
      <CreateFakeUsers
        open={createFakeUsersOpen}
        setOpen={setCreateFakeUsersOpen}
        setSuccess={setSuccess}
      />
      {revokeMembershipOpen ? (
        <RevokeMembership
          open={revokeMembershipOpen}
          setOpen={setRevokeMembershipOpen}
          revokeMembershipMutation={revokeMembershipMutation}
        />
      ) : null}
      {editFakeUserNameOpen ? (
        <ChangeFakeUserName
          open={Boolean(editFakeUserNameOpen)}
          idUser={editFakeUserNameOpen}
          setOpen={setEditFakeUserNameOpen}
          setSuccess={setSuccess}
        />
      ) : null}
      {convertFakeUserOpen ? (
        <ConvertFakeUser
          open={Boolean(convertFakeUserOpen)}
          idUser={convertFakeUserOpen}
          setOpen={setConvertFakeUserOpen}
          setSuccess={setSuccess}
        />
      ) : null}
      {confirmDeleteOpen ? (
        <ConfirmDeleteModal
          open={confirmDeleteOpen}
          setOpen={setConfirmDeleteOpen}
          onConfirm={() => deleteFakeUserMutation.mutate(userToDelete)}
        />
      ) : null}
      <DeleteUser
        open={deleteuser}
        setOpen={setDeleteUserOpen}
        setSuccess={setSuccess}
        user={user}
        setUser={setUser}
      />
      <AssignRole
        open={assignrole}
        setOpen={setAssignRoleOpen}
        setSuccess={setSuccess}
        user={user}
        setUser={setUser}
      />
      <ValidateUser
        open={validateuser}
        setOpen={setValidateUserOpen}
        setSuccess={setSuccess}
        user={user}
        setUser={setUser}
      />

      <div className="sm:flex sm:items-center justify-end">
        <div className="my-4 sm:mt-0 sm:ml-16">
          <button
            type="button"
            className="block rounded-md bg-gray-500 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            onClick={() => {
              setCreateFakeUsersOpen(true);
            }}
          >
            {t('manager.team.createFakeUsers.createUsers')}
          </button>
        </div>
        <div className="my-4 sm:mt-0 sm:ml-2">
          <button
            type="button"
            className="block rounded-md bg-blue-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={() => {
              setInvitePersonnelOpen(true);
            }}
          >
            {t('manager.team.personnel.inviteUsers')}
          </button>
        </div>
      </div>
      {error ? <Alert success={false} text={error} /> : null}
      {success ? <Alert success text={success} /> : null}
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full pt-2 pb-24 align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                  >
                    {t('generic.firstName')}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t('generic.role')}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t('manager.team.personnel.verifiedUser')}
                  </th>
                  <th
                    scope="col"
                    className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                  >
                    <span className="sr-only">{t('generic.edit')}</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {accessibleUsers
                  .sort((a, b) => `${a.listed_user.firstname} ${a.listed_user.lastname}`.localeCompare(
                    `${b.listed_user.firstname} ${b.listed_user.lastname}`,
                  ))
                  .map(({ role, listed_user }) => (
                    <tr key={listed_user.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        {thisManager.id
                          in data.groups[listed_user.group.id_group].managers ? (
                          listed_user.is_mock ? (
                            <div className="whitespace-nowrap text-sm text-gray-500 flex flex-row gap-2 items-center">
                              <Link
                                to={`${listed_user.id}`}
                                state={{
                                  name: `${listed_user.firstname} ${listed_user.lastname}`,
                                  role: role.name,
                                  previous: 'personnel',
                                }}
                              >
                                {listed_user.firstname}
                                {' '}
                                {listed_user.lastname}
                              </Link>
                              <button
                                onClick={() => setEditFakeUserNameOpen(listed_user.id)}
                              >
                                <PencilIcon
                                  className="h-5 w-5 text-blue-600"
                                  aria-hidden="true"
                                />
                              </button>
                              <button
                                onClick={() => setConvertFakeUserOpen(listed_user.id)}
                              >
                                <ArrowPathIcon
                                  className="h-5 w-5 text-blue-600"
                                  aria-hidden="true"
                                />
                              </button>
                              <button
                                onClick={() => {
                                  setUserToDelete(listed_user);
                                  setConfirmDeleteOpen(true);
                                }}
                              >
                                <TrashIcon
                                  className="h-5 w-5 text-red-600"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          ) : (
                            <Link
                              to={`${listed_user.id}`}
                              state={{
                                name: `${listed_user.firstname} ${listed_user.lastname}`,
                                role: role.name,
                                previous: 'personnel',
                              }}
                            >
                              {listed_user.firstname}
                              {' '}
                              {listed_user.lastname}
                            </Link>
                          )
                        ) : null}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {role.name === 'no_role' ? t('manager.team.personnel.noRole') : role.name}
                      </td>
                      {listed_user.is_mock ? (
                        <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                            <p className="bg-gray-200 p-1.5 rounded-md">
                              {t('manager.team.createFakeUsers.demo')}
                            </p>
                          </div>
                        </td>
                      ) : listed_user.user_is_verified ? (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                            <CheckBadgeIcon
                              className="h-7 w-7 text-green-600"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                      ) : (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                            <XCircleIcon
                              className="h-7 w-7 text-red-600"
                              aria-hidden="true"
                            />
                          </div>
                        </td>
                      )}
                      {thisManager.id
                        in data.groups[listed_user.group.id_group].managers ? (
                        listed_user.user_is_verified ? (
                          role.id !== -1 ? (
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                              <Popover className="relative">
                                <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-5 text-gray-900">
                                  <span>{t('generic.manage')}</span>
                                  <ChevronDownIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </Popover.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 translate-y-1"
                                >
                                  <Popover.Panel className="absolute left-1/2 z-10 mt-2 flex w-screen max-w-min -translate-x-1/3 px-4">
                                    <div className="shrink rounded-xl bg-white p-3 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                                      <div className="relative rounded-xl p-2 hover:bg-gray-100">
                                        <button
                                          type="button"
                                          className="font-semibold text-gray-900"
                                          onClick={() => {
                                            setUser(listed_user);
                                            setAssignRoleOpen(true);
                                          }}
                                        >
                                          {t(
                                            'manager.team.personnel.changeRole',
                                          )}
                                          <span className="absolute inset-0" />
                                        </button>
                                      </div>
                                      <div className="relative rounded-xl p-2 hover:bg-gray-100">
                                        <Link
                                          className="font-semibold text-gray-900"
                                          to={`${listed_user.id}`}
                                          state={{
                                            name: `${listed_user.firstname} ${listed_user.lastname}`,
                                            role: role.name,
                                            previous: 'personnel',
                                          }}
                                        >
                                          {t('manager.team.personnel.seeStats')}
                                          <span className="absolute inset-0" />
                                        </Link>
                                      </div>
                                      <div className="relative rounded-xl p-2 hover:bg-gray-100">
                                        <button
                                          type="button"
                                          className="font-semibold text-gray-900"
                                          onClick={() => {
                                            setUserToBeRevoked(listed_user.id);
                                            setRevokeMembershipOpen(true);
                                          }}
                                        >
                                          {t(
                                            'manager.team.revokeMembership.revokeMembership',
                                          )}
                                          <span className="absolute inset-0" />
                                        </button>
                                      </div>
                                      {/* <div className="relative rounded-sm p-2 hover:bg-gray-100">
                                        <p className="font-semibold text-gray-900">
                                          Contactar con gestor
                                          <span className="absolute inset-0" />
                                        </p>
                                      </div> */}
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </Popover>
                            </td>
                          ) : (
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                              <button
                                type="button"
                                className="rounded bg-blue-50 px-2 py-1 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-100"
                                onClick={() => {
                                  setUser(listed_user);
                                  setAssignRoleOpen(true);
                                }}
                              >
                                {t('manager.team.personnel.assignRole')}
                              </button>
                            </td>
                          )
                        ) : (
                          <td className="relative whitespace-nowrap flex flex-row gap-3 justify-end py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                            <button
                              type="button"
                              className="rounded bg-green-100 px-2 py-1 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-200"
                              onClick={() => verifyUserMutation.mutate(listed_user.id)}
                            >
                              {t('manager.team.personnel.verifyUser')}
                            </button>
                            <button
                              type="button"
                              className="rounded bg-red-100 px-2 py-1 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
                              onClick={() => {
                                setUser(user);
                                setDeleteUserOpen(true);
                              }}
                            >
                              {t('manager.team.personnel.deleteUser')}
                            </button>
                          </td>
                        )
                      ) : (
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-gray-600 text-sm font-medium sm:pr-6 lg:pr-8">
                          {t('manager.team.personnel.noAccess')}
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
            {notAccessibleUsers.length === 0 ? null : (
              <Disclosure as="div" className="mt-2">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                      <span>{t('manager.team.personnel.otherUsers')}</span>
                      <ChevronUpIcon
                        className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-blue-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                      {/* Render not accessible users here */}
                      <table className="min-w-full divide-y divide-gray-300">
                        {/* Table Headers */}
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {notAccessibleUsers.map(({ role, listed_user }) => (
                            <tr key={listed_user.id}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                <Link
                                  to={`${listed_user.id}`}
                                  state={{
                                    name: `${listed_user.firstname} ${listed_user.lastname}`,
                                    role: role.name,
                                    previous: 'personnel',
                                  }}
                                >
                                  {listed_user.firstname}
                                  {' '}
                                  {listed_user.lastname}
                                </Link>
                                <span className="px-3 py-4 text-sm text-gray-500">
                                  {role.name === 'no_role'
                                    ? t('manager.team.personnel.noRole')
                                    : role.name}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
