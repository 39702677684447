import {
  CalendarDaysIcon,
  MapPinIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import Alert from '../../../components/FormFeedback/Alert';
import PostResponse from './PostResponse';

function UserShiftExchangeFeed() {
  const [my_requests, feed, shiftOutputs, allShifts, shiftOutputsText] = useOutletContext();
  const [open, setOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [exchangeType, setExchangeType] = useState(null);

  const { t } = useTranslation();

  return (
    <>
      {open ? (
        <PostResponse
          open={open}
          setOpen={setOpen}
          selectedRequest={selectedRequest}
          shifts={shiftOutputsText}
          setRequestSuccess={setRequestSuccess}
          exchangeType={exchangeType}
        />
      ) : null}
      {requestSuccess ? <Alert text={requestSuccess} success /> : null}
      {Object.values(feed).every(
        obj => obj.valid_requests && obj.valid_requests.length === 0,
      ) ? (
        <p className="mt-6 text-center text-gray-600">
          {t('user.shiftExchange.emptyFeed')}
        </p>
        ) : null}
      {Object.entries(feed).map(([id_itr, feed]) => feed.valid_requests.map(valid_request => (
        <ul className="space-y-4 mt-6 overflow-hidden bg-white m-4 shadow-md border border-slate-200 rounded-lg">
          <li
            key={valid_request.id_request}
            className="relative flex sm:flex-row flex-col justify-between gap-x-6 px-4 py-5  sm:px-6 sm:rounded-lg"
          >
            <div className="flex min-w-0 gap-x-4">
              <div>
                <p className="flex flex-row gap-1 items-center">
                  <CalendarDaysIcon className="h-4 text-blue-600" />
                  {new Date(
                    valid_request.request_dict.start_time,
                  ).toLocaleString(undefined, {
                    weekday: 'long',
                    day: '2-digit',
                    month: 'long',
                    timeZone: 'GMT',
                  })}
                </p>
                <p className="flex flex-row gap-1 items-center">
                  <MapPinIcon className="h-4 text-teal-600" />
                  {valid_request.request_dict.shift_name}
                </p>
                <p className="flex flex-row gap-1 items-center">
                  <UserIcon className="h-4 text-gray-600" />
                  {`${valid_request.request_dict?.user?.firstname} ${valid_request.request_dict?.user?.lastname}`}
                </p>
              </div>
            </div>
            <div className="flex shrink-0 items-center gap-x-4 mt-3 sm:mt-0">
              <div className="flex flex-row sm:items-end sm:gap-1 gap-4">
                <button
                  type="button"
                  className="border border-teal-400 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-teal-400/50 sm:ml-3 sm:w-auto disabled:bg-gray-100 disabled:border-gray-100"
                  disabled={valid_request.valid_trades.length == 0}
                  onClick={() => {
                    setSelectedRequest(valid_request);
                    setExchangeType('one_for_one');
                    setOpen(true);
                  }}
                >
                  {t('user.shiftExchange.trade')}
                </button>
                <button
                  type="button"
                  className="border border-blue-400 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-blue-400/50 sm:ml-3 sm:w-auto disabled:bg-gray-100 disabled:border-gray-100"
                  disabled={!valid_request.valid_accept}
                  onClick={() => {
                    setSelectedRequest(valid_request);
                    setExchangeType('one_for_zero');
                    setOpen(true);
                  }}
                >
                  {t('user.shiftExchange.accept')}
                </button>
              </div>
            </div>
          </li>
        </ul>
      )))}
    </>
  );
}
export default UserShiftExchangeFeed;
