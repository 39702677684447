import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { classNames, parseIterationDates } from '@youshift/shared/utils';
import { getChainsQuery } from '@youshift/shared/hooks/jsQueries';

import { requireApproved, requireManager } from '../../utils/checks';

export const chainsLoader = queryClient => async () => {
  const user = await requireManager(queryClient);
  await requireApproved(user);
  const query = getChainsQuery();
  return (
    queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
  );
};

export default function ChainsHistory() {
  const { data: itrs } = useQuery(getChainsQuery());

  const { t } = useTranslation();
  // TODO: abstract this
  const status_classes = {
    initialization: [
      'text-blue-800 bg-blue-50 ring-blue-600/20',
      t('generic.itrStatuses.initialization'),
    ],
    configuration: [
      'text-blue-800 bg-blue-50 ring-blue-600/20',
      t('generic.itrStatuses.configuration'),
    ],
    'failed-algo': [
      'text-red-800 bg-red-50 ring-red-600/20',
      t('generic.itrStatuses.failedAlgo'),
    ],
    'running-algo': [
      'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
      t('generic.itrStatuses.runningAlgo'),
    ],
    verification: [
      'text-violet-800 bg-violet-50 ring-violet-600/20',
      t('generic.itrStatuses.verification'),
    ],
    published: [
      'text-teal-800 bg-teal-50 ring-teal-600/20',
      t('generic.itrStatuses.published'),
    ],
    closed: [
      'text-stone-800 bg-stone-50 ring-stone-600/20',
      t('generic.itrStatuses.closed'),
    ],
    archived: [
      'text-stone-800 bg-stone-50 ring-stone-600/20',
      t('generic.itrStatuses.archived'),
    ],
  };

  return (
    <div className="space-y-6 md:mt-16 px-2 sm:px-4">
      <div className="flex flex-row justify-between">
        <h3 className="text-2xl font-semibold leading-6 text-gray-900 my-4">
          {t('manager.chains.history')}
        </h3>
      </div>
      <ul className="mt-2">
        {itrs.map(chain => (
          <div className="border-b border-b-gray-400/50">
            <p className="text-blue-600 font-bold mt-4">{chain.chain_name}</p>
            {chain.itrs.map(itr => (
              <li
                key={chain.id_chain}
                className="flex items-center justify-between gap-x-6 py-3"
              >
                <div className="min-w-0">
                  <div className="flex items-start gap-x-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {parseIterationDates(
                        itr.start_day,
                        itr.end_day,
                        itr.itr_type,
                      )}
                    </p>
                    <p
                      className={classNames(
                        status_classes[itr.status][0],
                        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                      )}
                    >
                      {status_classes[itr.status][1]}
                    </p>
                  </div>
                </div>
                <div className="flex flex-none items-center gap-x-4">
                  <Link
                    to={`../iteration/${itr.id_itr}`}
                    className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                  >
                    {t('manager.chains.seeItr')}
                    <span className="sr-only">
                      ,
                      {itr.name}
                    </span>
                  </Link>
                </div>
              </li>
            ))}
          </div>
        ))}
      </ul>
    </div>
  );
}
