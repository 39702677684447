import { NumericFormat } from 'react-number-format';

function EventSquare({
  e,
  small,
  date,
  points,
  id,
  dispatch,
  justPoints,
  maxPoints,
  noMargin,
  shift_acr,
}) {
  const maxPointsCopy = maxPoints || 50;

  const bgc = (e, b) => {
    if (e === 'B') {
      return b ? 'rgba(255, 217, 84, 0.5)' : 'rgba(255, 217, 84, 1)';
    }
    if (e === 'S') {
      return b ? 'rgba(57, 208, 255, 0.5)' : 'rgba(57, 208, 255, 1)';
    }
    if (e === 'E') {
      return b ? 'rgba(255, 84, 217, 0.5)' : 'rgba(255, 84, 217, 1)';
    }
    if (e === 'V') {
      return b ? 'rgba(151, 71, 255, 0.5)' : 'rgba(151, 71, 255, 1)';
    }
    if (e === 'X') {
      return b ? 'rgba(191, 191, 191, 0.5)' : 'rgba(191, 191, 191, 1)';
      // } if (e === 'C') {
      //   return b ? 'rgba(0, 50, 236, 0.05)' : 'rgba(0, 50, 236, 1)'
    }
    if (Number(points) >= 0) {
      return b
        ? `rgba(0, 157, 113, ${Math.ceil((points / maxPointsCopy) * 10) / 10})`
        : 'rgba(0, 157, 113, 1)';
    }
    if (Number(points) < 0) {
      return b
        ? `rgba(255, 48, 48, ${Math.ceil(-(points / maxPointsCopy) * 10) / 10})`
        : 'rgba(255, 48, 48, 1)';
    }

    return '#FFFF';
  };

  const square = {
    width: small ? '35px' : date ? '80px' : '55px',
    height: small ? '35px' : date ? '20px' : '55px',
    backgroundColor: bgc(e, true),
    borderRadius: small ? '0.5rem' : '0.5rem',
    border: `1px solid ${bgc(e, false)}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '0.85rem',
    margin: noMargin ? '' : '0px auto',
    padding: '0px',
  };

  const changePoints = increment => {
    let ctPoints = Number(points);
    if (increment) {
      ctPoints++;
    } else {
      ctPoints--;
    }
    dispatch({ type: 'UPDATE_POINTS', id, points: ctPoints });
  };
  return e === 'P' && !justPoints ? (
    <div className="flex flex-col items-center">
      <button onClick={() => changePoints(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v12m6-6H6"
          />
        </svg>
      </button>
      <NumericFormat
        value={points}
        valueIsNumericString
        // isAllowed={(values) => {
        //     const { value } = values;
        //     return (value < MAX_POINTS && value > -MAX_POINTS) || value === '-';
        // }}
        onValueChange={values => dispatch({ type: 'UPDATE_POINTS', id, points: values.value })}
        style={square}
      />
      <button onClick={() => changePoints(false)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-4 h-4"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
        </svg>
      </button>
    </div>
  ) : e === 'P' && justPoints ? (
    <div style={square}>{points}</div>
  ) : (
    <div style={square}>
      {shift_acr || (date ? `${e.toUpperCase()}: ${date}` : e.toUpperCase())}
    </div>
  );
}

export default EventSquare;
