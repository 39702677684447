import {
  CheckBadgeIcon,
  FolderPlusIcon,
  PencilSquareIcon,
  PlusIcon,
  RectangleGroupIcon,
} from '@heroicons/react/24/outline';
import { QueryClient } from '@tanstack/react-query';
import { userReqRulesQuery } from '@youshift/shared/hooks/queries';
import { preLoadQuery } from '@youshift/shared/hooks';
import { Rule } from '@youshift/shared/types';
import { useTranslation } from 'react-i18next';
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';

import { EmptyState } from '../../../../components/EmptyState';
import { requireApproved, requireManager } from '../../../../utils/checks';
import Wrapper from '../../../../components/Wrapper';

type UserReqRulesLoader = { rules: Rule[] };
export const userReqRulesLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
  }: LoaderFunctionArgs): Promise<UserReqRulesLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const rules = await preLoadQuery(
      queryClient,
      userReqRulesQuery(params.idItr),
    );
    return { rules };
  };

export default function UserReqRulesConfig() {
  const { rules } = useLoaderData() as UserReqRulesLoader;
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!rules.length) {
    return (
      <Wrapper>
        <EmptyState
          title={t('manager.rulesConfig.createPrompt')}
          subtitle={t('manager.rulesConfig.definition')}
          buttonText={t('manager.rulesConfig.createRule')}
          Icon={RectangleGroupIcon}
          onClick={() => navigate('./new')}
        />
      </Wrapper>
    );
  }

  return (
    <div className="py-6 h-full flex">
      <div className="space-y-2 max-w-lg flex-grow">
        {rules.map(rule => (
          <div
            key={rule.id_rule}
            className="grid grid-cols-2 bg-white rounded-lg border border-gray-200 p-3"
          >
            <div
              className="flex items-center space-x-3 border-r-2"
              style={{ borderRightColor: 'blue' }}
            >
              <span className="text-lg font-medium">{rule.name}</span>
              <Link
                to={`${rule.id_rule}`}
                className="p-1 rounded-full border border-gray-300"
              >
                <PencilSquareIcon className="w-5 h-5 text-gray-400" />
              </Link>
            </div>
            <div className="flex items-center justify-self-end space-x-3">
              <div className="p-1 rounded-full bg-green-500">
                <CheckBadgeIcon className="w-5 h-5 text-white" />
              </div>
            </div>
          </div>
        ))}
        <div className="flex items-center justify-between bg-gray-100 rounded-lg p-3 text-gray-400">
          <span>{t('manager.rulesConfig.createRule')}</span>
          <button
            onClick={() => navigate('./new')}
            className="p-1 rounded-full border border-gray-300"
            aria-label={t('manager.rulesConfig.createRule')}
          >
            <PlusIcon className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}
