import { Dialog, Transition } from '@headlessui/react';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { request } from '@youshift/shared/api';

import Alert from '../FormFeedback/Alert';

export default function CreateFakeUsers({ open, setOpen, setSuccess }) {
  const cancelButtonRef = useRef(null);
  const [numUsers, setNumUsers] = useState(1);
  const [error, setError] = useState(false);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => request({
      url: 'manager/personnel/user/mock/create',
      method: 'post',
      data: { num_users: numUsers },
    }),
    onSuccess: () => {
      queryClient.invalidateQueries(['personnel']);
      setSuccess(t('manager.team.createFakeUsers.success'));
      setError(null);
      setOpen(false);
    },
    onError: () => {
      setSuccess(null);
      setError(t('manager.team.createFakeUsers.error'));
    },
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <UserGroupIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
                    <Dialog.Title
                      as="h3"
                      className="text-base mb-1 font-semibold leading-6 text-gray-900"
                    >
                      {t('manager.team.createFakeUsers.createUsers')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="isolate -space-y-px rounded-md shadow-sm mt-2">
                        <div className="relative rounded-md rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label
                            htmlFor="numUsers"
                            className="block text-xs font-medium text-gray-900"
                          >
                            {t('manager.team.createFakeUsers.numUsers')}
                          </label>
                          <input
                            type="number"
                            name="numUsers"
                            id="numUsers"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            value={numUsers}
                            onChange={e => setNumUsers(Number(e.target.value))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Dialog.Title as="p" className="text-xs mb-4 text-gray-500">
                  {t('manager.team.createFakeUsers.description')}
                </Dialog.Title>
                {error ? <Alert success={false} text={error} /> : null}
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                    onClick={() => mutate()}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    ) : null}
                    {t('generic.create')}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    {t('generic.cancel')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
