import { useTranslation } from 'react-i18next';

import EventSquare from './EventSquare';

export default function Justification({ eventsToJustify, dispatch }) {
  const { t } = useTranslation();
  return eventsToJustify.map(event => {
    const date = new Date(event.start);
    return (
      <div className="mb-3">
        <div className="relative mt-2 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <EventSquare
              e="E"
              date={date.toLocaleString(undefined, {
                day: '2-digit',
                month: '2-digit',
                timeZone: 'GMT',
              })}
            />
          </div>
          <input
            type="name"
            name="name"
            id="name"
            className="block w-full rounded-md border-0 py-1.5 pl-28 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder={t('manager.eventJustification.placeholder')}
            value={event.justification}
            onChange={e => dispatch({
              type: 'UPDATE_JUSTIFICATION',
              id: event.id_slot_pref,
              justification: e.target.value,
            })}
          />
        </div>
      </div>
    );
  });
}
