import { Dialog, Transition } from '@headlessui/react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { request } from '@youshift/shared/api';

import Alert from '../FormFeedback/Alert';

export default function InvitePersonnel({ open, setOpen, setSuccess }) {
  const cancelButtonRef = useRef(null);
  const [email_list, setEmailList] = useState([]);
  const [email_is_valid, setEmailIsValid] = useState([]);
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const handleEmailChange = event => {
    const clean_str = event.target.value.replace(/\s/g, '');
    const newList = clean_str.split(',');

    const valid_email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailIsValid(newList.map(email => valid_email_pattern.test(email)));
    setEmailList(newList);
  };

  const deleteEmail = index => {
    const newList = [...email_list];
    newList.splice(index, 1);
    setEmailList(newList);
  };

  const saveChanges = async () => {
    if (email_list.length === 0) {
      setError(t('manager.team.invitePersonnel.emptyError'));
      setSuccess(null);
      return;
    }
    try {
      const data = { email_list };
      const url = '/manager/personnel/send_invitations';
      await request({ url, method: 'post', data });
      setSuccess(t('manager.team.invitePersonnel.success'));
      setError(null);
      setOpen(false);
    } catch (error) {
      setError(t('generic.error'));
      setSuccess(null);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilSquareIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
                    <Dialog.Title
                      as="h3"
                      className="text-lg mb-5 mt-2 text-blue-600 font-semibold leading-6"
                    >
                      {t('manager.team.invitePersonnel.inviteUsers')}
                    </Dialog.Title>
                    <div className="mt-2">
                      {email_list.map((email, index) => (email_is_valid[index] ? (
                        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          {email}
                          <button
                            type="button"
                            className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-green-600/20"
                            onClick={() => deleteEmail(index)}
                          >
                            <span className="sr-only">Remove</span>
                            <svg
                              viewBox="0 0 14 14"
                              className="h-3.5 w-3.5 stroke-green-700/50 group-hover:stroke-green-700/75"
                            >
                              <path d="M4 4l6 6m0-6l-6 6" />
                            </svg>
                            <span className="absolute -inset-1" />
                          </button>
                        </span>
                      ) : (
                        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                          {email}
                          <button
                            type="button"
                            className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-red-600/20"
                            onClick={() => deleteEmail(index)}
                          >
                            <svg
                              viewBox="0 0 14 14"
                              className="h-3.5 w-3.5 stroke-red-600/50 group-hover:stroke-red-600/75"
                            >
                              <path d="M4 4l6 6m0-6l-6 6" />
                            </svg>
                            <span className="absolute -inset-1" />
                          </button>
                        </span>
                      )))}

                      <div className="isolate -space-y-px rounded-md shadow-sm mt-2">
                        <div className="relative rounded-md rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label
                            htmlFor="email-list"
                            className="block text-xs font-medium text-gray-900"
                          >
                            {t('manager.team.invitePersonnel.emailList')}
                          </label>
                          <input
                            type="text"
                            name="email-list"
                            id="email-list"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="Emails..."
                            value={email_list.join(', ')}
                            onChange={handleEmailChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {error ? <Alert success={false} text={error} /> : null}
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                    onClick={() => {
                      saveChanges();
                    }}
                  >
                    {t('generic.sendButton')}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setOpen(false);
                      setEmailList([]);
                    }}
                    ref={cancelButtonRef}
                  >
                    {t('generic.cancel')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
