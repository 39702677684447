/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react';
import { Link, LinkProps, RelativeRoutingType, To } from 'react-router-dom';

interface ButtonProps {
  children: ReactNode;
  onClick?: () => void | undefined;
  to?: To | undefined;
  variant?:
    | 'primary'
    | 'secondary'
    | 'outline-primary'
    | 'outline-secondary'
    | 'ghost-primary'
    | 'ghost-secondary'
    | 'delete';
  classNames?: string;
  disabled?: boolean;
  relative?: RelativeRoutingType | undefined;
}

const getClassNames = (variant: string) => {
  switch (variant) {
    case 'primary':
      return 'bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md disabled:opacity-50 disabled:cursor-not-allowed';
    case 'secondary':
      return 'bg-teal-600 hover:bg-teal-700 text-white font-semibold py-2 px-4 rounded-md disabled:opacity-50 disabled:cursor-not-allowed';
    case 'outline-primary':
      return 'bg-transparent hover:bg-blue-600 text-blue-600 font-semibold hover:text-white py-2 px-4 border border-blue-600 hover:border-transparent rounded-md';
    case 'outline-secondary':
      return 'bg-transparent hover:bg-teal-600 text-teal-600 font-semibold hover:text-white py-2 px-4 border border-teal-600 hover:border-transparent rounded-md';
    case 'ghost-primary':
      return 'bg-transparent text-blue-600 font-semibold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50';
    case 'ghost-secondary':
      return 'bg-transparent text-teal-600 font-semibold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-opacity-50';
    case 'delete':
      return 'bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-md disabled:opacity-50 disabled:cursor-not-allowed';
    default:
      return '';
  }
};

export function YSButton({
  children,
  to = undefined,
  onClick = undefined,
  variant = 'primary',
  classNames,
  disabled = false,
  relative = 'path',
}: ButtonProps) {
  const className =
    getClassNames(variant) + (classNames ? ` ${classNames}` : '');

  return to ? (
    <Link to={to} onClick={onClick} className={className} relative={relative}>
      {children}
    </Link>
  ) : (
    <button
      type="button"
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
