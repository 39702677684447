/* eslint-disable react/require-default-props */
import type {
  Section,
  SectionSlotsDict,
  SectionWithSlots,
} from '@youshift/shared/types';
import {
  arrangeSectionSlots,
  ColorIndex,
  dateToString,
  divideSlotsIntoWeeks,
  getCustomDateRange,
  getDifferenceInHours,
  getDifferenceInMinutes,
  returnColor,
} from '@youshift/shared/utils';
import { ReactNode } from 'react';
import { PencilIcon } from '@heroicons/react/24/outline';

import { SectionSlot, SectionSlotSelect } from '../SectionSlot';
import { ArrangedSectionSlot } from '../../pages/Manager/IterationConfig/Sections/CreateNewSection';

type VirgueriaProps = {
  start: string;
  end: string;
  sectionsWithSlots: SectionWithSlots[];
  shadeMap: Record<number, ColorIndex>;
  children?: ReactNode;
  onClick: (slot: ArrangedSectionSlot | number) => void;
  version: 'rules' | 'section';
  selectedSlots?: Set<number>;
};

export default function Virgueria({
  start,
  end,
  sectionsWithSlots,
  shadeMap,
  children,
  onClick,
  version,
  selectedSlots,
}: VirgueriaProps) {
  const weeks = getCustomDateRange(start, end);
  const RESOLUTION = 1;
  const singleSectionView: boolean = sectionsWithSlots.length === 1;
  const isRules = version === 'rules';

  return (
    <div>
      {weeks.map((week, weekIdx) => (
        <div
          className="grid auto-cols-auto auto-rows-min mt-4 mb-2 pb-2"
          style={{
            gridTemplateColumns: singleSectionView
              ? 'repeat(7, 1fr)'
              : 'minmax(75px, auto) repeat(7, 1fr)',
          }}
        >
          {singleSectionView ? null : <p />}
          {week.map(day => (
            <div className="border text-white p-1 rounded-md bg-gray-600">
              <p className="text-center font-bold">
                {dateToString(day, 'only-day')}
              </p>
            </div>
          ))}
          {sectionsWithSlots.map((section, idx) => {
            const slotsPerWeek = divideSlotsIntoWeeks(
              weeks,
              section.section_slots,
            );
            return (
              <>
                {singleSectionView ? null : (
                  <div
                    className={`flex justify-center items-center border-b-2 ${idx === Object.values(sectionsWithSlots).length - 1 ? 'rounded-b-md' : idx === 0 ? 'rounded-t-md' : ''}`}
                    style={{ backgroundColor: returnColor(section.color) }}
                  >
                    <p className="font-semibold">{section.acronym}</p>
                  </div>
                )}
                <div className="col-span-7 grid text-center auto-rows-min">
                  {arrangeSectionSlots(slotsPerWeek[weekIdx]).map(
                    (row, rowIndex) => (
                      <div
                        key={rowIndex}
                        className="grid"
                        style={{
                          gridTemplateColumns: `repeat(${168 / RESOLUTION}, minmax(5px, 1fr))`,
                        }}
                      >
                        {row.map(slot => {
                          const diffFromStart = getDifferenceInHours(
                            week[0],
                            slot.start,
                          );
                          const duration = getDifferenceInHours(
                            slot.start,
                            slot.end,
                          );
                          const shade = shadeMap[slot.id_slot_label];
                          const arrangedSectionSlot = {
                            start_position: diffFromStart + 1,
                            end_position: diffFromStart + 1 + duration,
                            start_time: dateToString(slot.start, 'hh:mm'),
                            end_time: dateToString(slot.end, 'hh:mm'),
                            duration_minutes: getDifferenceInMinutes(
                              slot.start,
                              slot.end,
                            ),
                            min_need: slot.min_need,
                            max_need: slot.max_need,
                            rest_period: slot.rest_period,
                            id_slot_label: slot.id_slot_label,
                            id: slot.id_section_slot,
                          };
                          return isRules ? (
                            <SectionSlotSelect
                              slot={arrangedSectionSlot}
                              sectionColor={section.color}
                              shade={shade}
                              onClick={onClick}
                              selectedSlots={selectedSlots || new Set()}
                            />
                          ) : (
                            <SectionSlot
                              slot={arrangedSectionSlot}
                              sectionColor={section.color}
                              shade={shade}
                              onClick={onClick}
                              Icon={PencilIcon}
                            />
                          );
                        })}
                      </div>
                    ),
                  )}
                </div>
              </>
            );
          })}
        </div>
      ))}
    </div>
  );
}
