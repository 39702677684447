import { classNames } from '@youshift/shared/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Navigate, Outlet } from 'react-router-dom';

import { useItrContext } from './IterationLayout';

export default function IterationConfigLayout() {
  const { status, locked } = useItrContext();

  const { t } = useTranslation();

  const tabs = useMemo(
    () => [
      { name: t('generic.roles'), href: 'roles', key: 'roles' },
      {
        name: t('generic.sections'),
        href: 'sections',
        key: 'sections',
      },
      {
        name: t('generic.additionalReqs'),
        href: 'rules',
        key: 'rules',
      },
      {
        name: t('generic.incompatibilities'),
        href: 'incompatibilities',
        key: 'incompatibilities',
      },
      {
        name: t('generic.preferences'),
        href: 'preferences',
        key: 'preferences',
      },
    ],
    [t],
  );

  return (
    <>
      {/* {status === 'running-algo' ? <Navigate to="../running" replace /> : null}
      {status === 'failed-algo' ? <Navigate to="../failed" replace /> : null} */}
      {status === 'initialization' ? (
        <Navigate to="../initialization/roles" replace />
      ) : null}
      {status === 'published' || status === 'archived' ? (
        <Navigate to="../verification" replace />
      ) : null}
      <div className="block">
        <nav
          className="isolate flex divide-x rounded-lg shadow"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <NavLink
              key={tab.name}
              to={tab.href}
              id={tab.href}
              className={classNames(
                '[&.active]:text-gray-900 [&.active]:border-b-2 [&.active]:border-solid [&.active]:border-b-blue-500 text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                tab.href === 'recorded-data'
                  ? 'bg-teal-400/50 hover:bg-teal-400/40 [&.active]:border-b-teal-500'
                  : '',
                'group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10',
              )}
            >
              <span>{tab.name}</span>
            </NavLink>
          ))}
        </nav>
      </div>
      <Outlet context={[locked]} />
    </>
  );
}
