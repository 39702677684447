"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatSlotDuration = exports.getShadeMap = exports.getCustomDateRange = exports.getDifferenceInMinutes = exports.getDifferenceInHours = exports.subtractDays = exports.addDays = exports.getDayOfWeek = exports.divideSlotsIntoWeeks = exports.arrangeSectionSlots = exports.getWeekdayInitials = exports.dateToString = exports.getFirstDayOfWeek = exports.classNames = exports.parseIterationDates = void 0;
/* eslint-disable no-else-return */
var parseIterationDates = function (start_day, end_day, itr_type) {
    var start_date = new Date(start_day);
    var end_date = new Date(end_day);
    if (itr_type === "Month") {
        var year = start_date.getUTCFullYear();
        var month = start_date.toLocaleString("default", {
            month: "long",
            timeZone: "GMT",
        });
        return "".concat(month, " ").concat(year);
    }
    else {
        var start_day_1 = start_date.toLocaleString("default", {
            day: "numeric",
            month: "long",
            timeZone: "GMT",
        });
        var adjustedEndDate = new Date(end_date);
        adjustedEndDate.setDate(adjustedEndDate.getDate() - 1);
        var end_day_1 = adjustedEndDate.toLocaleString("default", {
            day: "numeric",
            month: "long",
            year: "numeric",
            timeZone: "GMT",
        });
        return "".concat(start_day_1, " - ").concat(end_day_1);
    }
};
exports.parseIterationDates = parseIterationDates;
function classNames() {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes.filter(Boolean).join(" ");
}
exports.classNames = classNames;
var getFirstDayOfWeek = function (locale) {
    switch (locale) {
        case "en-US":
            return 7;
        default:
            return 1;
    }
};
exports.getFirstDayOfWeek = getFirstDayOfWeek;
var dateToString = function (dateInput, format) {
    var date = new Date(dateInput);
    switch (format) {
        case "only-day":
            return date.toLocaleString(undefined, {
                day: "2-digit",
                timeZone: "GMT",
            });
        case "weekday":
            return date.toLocaleString(undefined, {
                day: "2-digit",
                weekday: "short",
                timeZone: "GMT",
            });
        case "hh:mm":
            return date.toLocaleString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
                timeZone: "GMT",
            });
    }
};
exports.dateToString = dateToString;
var getWeekdayInitials = function (t, locale) {
    var days = [
        t("calendars.mo"),
        t("calendars.tu"),
        t("calendars.we"),
        t("calendars.th"),
        t("calendars.fr"),
        t("calendars.sa"),
        t("calendars.su"),
    ];
    var firstDay = (0, exports.getFirstDayOfWeek)(locale);
    if (firstDay === 1) {
        return days;
    }
    else {
        return days.slice(-1).concat(days).slice(0, -1);
    }
};
exports.getWeekdayInitials = getWeekdayInitials;
var arrangeSectionSlots = function (slots) {
    var slotArray = Object.values(slots).map(function (slot) { return (__assign(__assign({}, slot), { start: new Date(slot.start), end: new Date(slot.end) })); });
    // Sort the slots by their start time
    slotArray.sort(function (a, b) {
        if (a.start.getTime() - b.start.getTime() === 0) {
            return b.end.getTime() - a.end.getTime(); // Longer duration first
        }
        return a.start.getTime() - b.start.getTime();
    });
    // Array to hold the rows, each row is an array of non-overlapping slots
    var rows = [];
    // Iterate over each slot
    for (var _i = 0, slotArray_1 = slotArray; _i < slotArray_1.length; _i++) {
        var slot = slotArray_1[_i];
        var placed = false;
        // Try to place the slot in an existing row
        for (var _a = 0, rows_1 = rows; _a < rows_1.length; _a++) {
            var row = rows_1[_a];
            if (row[row.length - 1].end <= slot.start) {
                row.push(slot);
                placed = true;
                break;
            }
        }
        // If the slot couldn't be placed in any existing row, create a new row
        if (!placed) {
            rows.push([slot]);
        }
    }
    return rows;
};
exports.arrangeSectionSlots = arrangeSectionSlots;
var divideSlotsIntoWeeks = function (weeks, slots) {
    var slotsByWeek = weeks.map(function () { return []; });
    Object.keys(slots).forEach(function (slotKey) {
        var slot = slots[slotKey];
        var startTime = new Date(slot.start);
        var endTime = new Date(slot.end);
        for (var weekIndex = 0; weekIndex < weeks.length; weekIndex++) {
            var week = weeks[weekIndex];
            var weekStart = new Date(week[0]);
            var weekEnd = weekIndex < weeks.length - 1
                ? new Date(weeks[weekIndex + 1][0])
                : addDays(new Date(week[6]), 1);
            if (weekStart <= startTime && startTime < weekEnd) {
                if (endTime <= weekEnd) {
                    // Non-spanning slot: starts and ends within the week
                    slotsByWeek[weekIndex].push(__assign({}, slot));
                }
                else {
                    // Spanning slot: split the slot
                    var splitSlot1 = __assign(__assign({}, slot), { end: String(weekEnd) });
                    var splitSlot2 = __assign(__assign({}, slot), { start: String(weekEnd) });
                    slotsByWeek[weekIndex].push(splitSlot1);
                    if (weekIndex < weeks.length - 1) {
                        slotsByWeek[weekIndex + 1].push(splitSlot2);
                    }
                }
                break; // Exit the loop since the slot has been handled
            }
        }
    });
    return slotsByWeek;
};
exports.divideSlotsIntoWeeks = divideSlotsIntoWeeks;
// Helper function to get the day of the week (0 for Sunday, 6 for Saturday)
function getDayOfWeek(date) {
    return date.getUTCDay();
}
exports.getDayOfWeek = getDayOfWeek;
// Helper function to add days to a date
function addDays(date, days) {
    var result = new Date(date);
    result.setUTCDate(result.getUTCDate() + days);
    return result;
}
exports.addDays = addDays;
// Helper function to subtract days from a date
function subtractDays(date, days) {
    return addDays(date, -days);
}
exports.subtractDays = subtractDays;
function getDifferenceInHours(startDate, endDate) {
    var start = new Date(startDate);
    var end = new Date(endDate);
    // Calculate the difference in milliseconds
    var differenceInMilliseconds = end.getTime() - start.getTime();
    // Convert milliseconds to hours
    var differenceInHours = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60));
    return differenceInHours;
}
exports.getDifferenceInHours = getDifferenceInHours;
function getDifferenceInMinutes(startDate, endDate) {
    // Calculate the difference in milliseconds
    var start = new Date(startDate);
    var end = new Date(endDate);
    var differenceInMilliseconds = end.getTime() - start.getTime();
    // Convert milliseconds to minutes
    var differenceInMinutes = differenceInMilliseconds / (1000 * 60);
    return differenceInMinutes;
}
exports.getDifferenceInMinutes = getDifferenceInMinutes;
function getCustomDateRange(startDate, endDate) {
    var start = new Date(startDate);
    var end = new Date(endDate);
    // Calculate the difference to adjust the start to the previous Monday and end to the next Sunday
    var startDayOfWeek = getDayOfWeek(start);
    var endDayOfWeek = getDayOfWeek(end);
    var startAdjustment = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1; // Adjust Sunday (0) to be the previous Monday
    var endAdjustment = endDayOfWeek === 0 ? 0 : 7 - endDayOfWeek; // Adjust Sunday (0) to stay the same
    var adjustedStart = subtractDays(start, startAdjustment);
    var adjustedEnd = addDays(end, endAdjustment);
    var daysMatrix = [];
    var currentWeek = [];
    var currentDay = adjustedStart;
    while (currentDay <= adjustedEnd) {
        currentWeek.push(new Date(currentDay));
        if (currentWeek.length === 7) {
            daysMatrix.push(currentWeek);
            currentWeek = [];
        }
        currentDay = addDays(currentDay, 1);
    }
    // Add the last week if it wasn't completed
    if (currentWeek.length > 0) {
        daysMatrix.push(currentWeek);
    }
    return daysMatrix;
}
exports.getCustomDateRange = getCustomDateRange;
function getShadeMap(shiftLabels) {
    var map = {};
    shiftLabels.forEach(function (label) {
        map[label.id_slot_label] = label.shade;
    });
    return map;
}
exports.getShadeMap = getShadeMap;
function formatSlotDuration(minutes) {
    var days = Math.floor(minutes / (24 * 60));
    minutes %= 24 * 60;
    var hours = Math.floor(minutes / 60);
    minutes %= 60;
    var result = [];
    if (days > 0) {
        result.push("".concat(days, " d"));
    }
    if (hours > 0) {
        result.push("".concat(hours, " h"));
    }
    if (minutes > 0 || (days === 0 && hours === 0)) {
        result.push("".concat(minutes, " min"));
    }
    return result.join(" ");
}
exports.formatSlotDuration = formatSlotDuration;
