import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

export default function SlotSubsetReqs({ slotSubsetReqs, dispatch, disabled }) {
  const {
    max_req, min_req, id_slot_subset, userRoleId,
  } = slotSubsetReqs;

  const squareStyle = {
    width: '45px',
    height: '45px',
    backgroundColor: 'rgba(0, 50, 236, 0.05)',
    borderRadius: '0.25rem',
    border: '1px solid rgba(0, 50, 236, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '1rem',
  };

  const changePoints = (increment, isMin) => {
    const currentPoints = isMin ? Number(min_req) : Number(max_req);
    const updatedPoints = increment ? currentPoints + 1 : currentPoints - 1;

    dispatch({
      type: isMin ? 'EDIT_SPECIFIC_MIN_REQ' : 'EDIT_SPECIFIC_MAX_REQ',
      payload: {
        id_slot_subset,
        userRoleId,
        [isMin ? 'minReq' : 'maxReq']: updatedPoints,
      },
    });
  };

  return (
    <div className="flex gap-2">
      {/* Min selector */}
      <div className="flex flex-col items-center">
        <button onClick={() => changePoints(true, true)} disabled={disabled}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v12m6-6H6"
            />
          </svg>
        </button>
        <NumericFormat
          value={min_req}
          valueIsNumericString
          isAllowed={values => {
            const { value } = values;
            return value >= 0;
          }}
          disabled={disabled}
          onValueChange={values => dispatch({
            type: 'EDIT_SPECIFIC_MIN_REQ',
            payload: {
              id_slot_subset,
              userRoleId,
              minReq: parseInt(values.value, 10),
            },
          })}
          style={squareStyle}
        />
        <button onClick={() => changePoints(false, true)} disabled={disabled}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
          </svg>
        </button>
      </div>
      {/* Max selector */}
      <div className="flex flex-col items-center">
        <button onClick={() => changePoints(true, false)} disabled={disabled}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v12m6-6H6"
            />
          </svg>
        </button>
        <NumericFormat
          value={max_req}
          isAllowed={values => {
            const { value } = values;
            return value >= 0;
          }}
          disabled={disabled}
          onValueChange={values => dispatch({
            type: 'EDIT_SPECIFIC_MAX_REQ',
            payload: {
              id_slot_subset,
              userRoleId,
              maxReq: parseInt(values.value, 10),
            },
          })}
          style={squareStyle}
        />
        <button onClick={() => changePoints(false, false)} disabled={disabled}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export function UpdateMinMaxSlotSubsetReqs({ dispatch, disabled }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row">
      {/* Min buttons for bulk update */}
      <div className="flex flex-col ml-2 items-center">
        <p>{t('generic.min')}</p>
        <button
          className="text-gray font-bold py-0 px-0"
          onClick={() => dispatch({
            type: 'EDIT_ALL_MIN_REQS',
            payload: { incrementMin: 1 },
          })}
          disabled={disabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 bg-blue-300 rounded"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 15.75l7.5-7.5 7.5 7.5"
            />
          </svg>
        </button>
        <button
          className="text-gray font-bold py-0 px-0 rounded mt-1"
          onClick={() => dispatch({
            type: 'EDIT_ALL_MIN_REQS',
            payload: { incrementMin: -1 },
          })}
          disabled={disabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 bg-blue-300 rounded"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </button>
      </div>
      {/* Max buttons for bulk update */}
      <div className="flex flex-col ml-2 items-center">
        <p>{t('generic.max')}</p>
        <button
          className="text-gray font-bold py-0 px-0"
          onClick={() => dispatch({
            type: 'EDIT_ALL_MAX_REQS',
            payload: { incrementMax: 1 },
          })}
          disabled={disabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 bg-blue-300 rounded"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 15.75l7.5-7.5 7.5 7.5"
            />
          </svg>
        </button>
        <button
          className="text-gray font-bold py-0 px-0 rounded mt-1"
          onClick={() => dispatch({
            type: 'EDIT_ALL_MAX_REQS',
            payload: { incrementMax: -1 },
          })}
          disabled={disabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 bg-blue-300 rounded"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
