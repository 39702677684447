import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import {
  useExcludeItrUsersMutation,
  useIncludeItrUsersMutation,
} from '@youshift/shared/hooks/mutations';
import { itrUsersQuery, personnelQuery } from '@youshift/shared/hooks/queries';
import { preLoadQuery } from '@youshift/shared/hooks';
import { User, UserRole } from '@youshift/shared/types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, useLoaderData, useParams } from 'react-router-dom';

import Alert from '../../../../components/FormFeedback/Alert';
import { requireApproved, requireManager } from '../../../../utils/checks';

type RolesLoader = {
  itrUsers: number[];
  users: Record<number, User>;
  roles: Record<number, UserRole>;
};

export const itrUsersLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<RolesLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const { itr_users_list: itrUsers } = await preLoadQuery(
      queryClient,
      itrUsersQuery(params.idItr),
    );
    const personnel = await preLoadQuery(queryClient, personnelQuery());
    const { users, roles } = personnel;
    return { itrUsers, users, roles };
  };

export default function RolesConfig() {
  // TODO: review this
  // const context = useOutletContext();
  // const locked = context ? context[0] : false;
  const locked = true;

  const { roles, users, itrUsers } = useLoaderData() as RolesLoader;
  const [localItrUsers, setLocalItrUsers] = useState<number[]>(itrUsers);
  useEffect(() => {
    setLocalItrUsers(itrUsers);
  }, [itrUsers]);

  const { t } = useTranslation();

  const params = useParams();
  const id = params.id ?? '';

  const userIdsByRole = useMemo(
    () =>
      Object.values(users).reduce<Record<number, number[]>>((acc, user) => {
        if (user.id_user_role) {
          if (acc[user.id_user_role]) {
            acc[user.id_user_role].push(user.id);
          } else {
            acc[user.id_user_role] = [user.id];
          }
        }
        return acc;
      }, {}),
    [users],
  );

  const includedRoles = useMemo(
    () =>
      Object.keys(roles)
        .map(Number)
        .filter(id =>
          userIdsByRole[id].some(userId => localItrUsers.includes(userId)),
        ),
    [localItrUsers, roles, userIdsByRole],
  );

  const queryClient = useQueryClient();

  const [error, setError] = useState<string | boolean>(false);

  const includeUsersMutation = useIncludeItrUsersMutation(queryClient, {
    onMutate: ({ id_users }) => {
      setLocalItrUsers(prev => [...prev, ...id_users]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['itrUsers', id] });
      // ... other invalidations ...
    },
    onError: (error: Error) => {
      setError(t('manager.rolesConfig.errorInclude'));
      queryClient.invalidateQueries({ queryKey: ['itrUsers', id] });
    },
  });

  const excludeUsersMutation = useExcludeItrUsersMutation(queryClient, {
    onMutate: ({ id_users }) => {
      setLocalItrUsers(prev =>
        prev.filter(userId => !id_users.includes(userId)),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['itrUsers', id] });
      // ... other invalidations ...
    },
    onError: (error: Error) => {
      setError(t('manager.rolesConfig.errorExclude'));
      queryClient.invalidateQueries({ queryKey: ['itrUsers', id] });
    },
  });

  return (
    <div className="px-4 sm:px-6 lg:px-8" id="rolesScreen">
      <div className="sm:flex sm:items-center" />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
            <div className="relative">
              {Object.keys(roles).length > 0 ? (
                <>
                  {error ? <Alert success={false} text={error} /> : null}
                  {!locked ? (
                    <p className="text-center mx-auto mb-3 text-red-600">
                      {t('manager.rolesConfig.locked')}
                    </p>
                  ) : null}
                  {Object.values(roles).map(role => (
                    <div
                      className="flex flex-row justify-center"
                      id="rolesTable"
                    >
                      <div className="relative px-4 sm:px-6">
                        {includedRoles.includes(role.id) && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                        )}
                        <label className="relative inline-flex items-center cursor-pointer align-middle">
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={includedRoles.includes(role.id)}
                            onChange={e => {
                              if (e.target.checked) {
                                includeUsersMutation.mutate({
                                  id_users: userIdsByRole[role.id],
                                  id_itr: Number(id),
                                });
                              } else {
                                excludeUsersMutation.mutate({
                                  id_users: userIdsByRole[role.id],
                                  id_itr: Number(id),
                                });
                              }
                            }}
                            disabled={!locked}
                          />
                          <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                        </label>
                      </div>
                      <Disclosure
                        as="div"
                        key={role.id}
                        className="mb-2 w-full"
                        defaultOpen={includedRoles.includes(role.id)}
                      >
                        {({ open, close }) => (
                          <>
                            <Disclosure.Button
                              disabled={!includedRoles.includes(role.id)}
                              className={`flex w-full z-0 justify-between rounded-lg px-4 bg-blue-100 hover:bg-blue-200 disabled:hover:bg-blue-200 py-2 text-left text-sm font-medium text-blue-900 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75`}
                            >
                              <div className="flex flex-row items-center gap-4">
                                <div>
                                  <p className="font-medium">{role.name}</p>
                                  {/* <p className="text-xs">
                                    {role.num_users
                                      ? `${role.num_users} usuarios`
                                      : null}
                                  </p> */}
                                </div>
                              </div>
                              <ChevronUpIcon
                                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                              {includedRoles.includes(role.id) ? (
                                <>
                                  <p className="mt-3 text-sm text-gray-800 font-medium">
                                    {t(
                                      'manager.rolesConfig.participantsAddRemove',
                                    )}
                                  </p>
                                  <div className="grid grid-cols-2 mt-3">
                                    {userIdsByRole[role.id]?.map(
                                      participantId => (
                                        <div className="flex flex-row my-2">
                                          <div className="relative px-4 sm:px-6">
                                            {localItrUsers.includes(
                                              participantId,
                                            ) && (
                                              <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                                            )}
                                            <label className="relative inline-flex items-center cursor-pointer align-middle">
                                              <input
                                                type="checkbox"
                                                className="sr-only peer"
                                                checked={localItrUsers.includes(
                                                  participantId,
                                                )}
                                                onChange={e => {
                                                  if (e.target.checked) {
                                                    includeUsersMutation.mutate(
                                                      {
                                                        id_users: [
                                                          participantId,
                                                        ],
                                                        id_itr: Number(id),
                                                      },
                                                    );
                                                  } else {
                                                    excludeUsersMutation.mutate(
                                                      {
                                                        id_users: [
                                                          participantId,
                                                        ],
                                                        id_itr: Number(id),
                                                      },
                                                    );
                                                  }
                                                }}
                                                disabled={!locked}
                                              />
                                              <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                                            </label>
                                          </div>
                                          <p>{`${users[participantId].firstname} ${users[participantId].lastname}`}</p>
                                        </div>
                                      ),
                                    )}
                                  </div>
                                </>
                              ) : null}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  ))}
                </>
              ) : (
                <p>{t('manager.rolesConfig.noRoles')}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
