import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { request } from '@youshift/shared/api';

import AdminLayout, { adminLoader } from './layouts/AdminLayout';
import AdvancedItrConfigLayout from './layouts/AdvancedItrConfigLayout';
import AuthLayout from './layouts/AuthLayout';
import IterationConfigLayout from './layouts/IterationConfigLayout';
import IterationLayout, { iterationLoader } from './layouts/IterationLayout';
import IterationStatus, { statusLoader } from './layouts/IterationStatus';
import IterationVerificationLayout, {
  verificationLoader,
} from './layouts/IterationVerificationLayout';
import ManagerLayout, { managerLoader } from './layouts/ManagerLayout';
import PreAuthLayout from './layouts/PreAuthLayout';
import RootLayout from './layouts/RootLayout';
import TeamLayout, { personnelLoader } from './layouts/TeamLayout';
import UserLayout, { userLoader } from './layouts/UserLayout';
import AdminDashboard, {
  adminDashboardLoader,
} from './pages/Admin/AdminDashboard';
import AdminGroup, { adminGroupLoader } from './pages/Admin/AdminGroup';
import AdminStats, { adminStatsLoader } from './pages/Admin/AdminStats';
import AllOrgs, { orgsLoader } from './pages/Admin/AllOrgs';
import AllUsers, { usersLoader } from './pages/Admin/AllUsers';
import Tools from './pages/Admin/Tools';
import Login from './pages/Auth/Login';
import ResetPassword from './pages/Auth/ResetPassword';
import SignUpDivider from './pages/Auth/SignUpDivider';
import SignUpManager from './pages/Auth/SignUpManager';
import SignUpMockUser from './pages/Auth/SignUpMockUser';
import SignUpNonManager from './pages/Auth/SignUpNonManager';
import SignUpUser from './pages/Auth/SignUpUser';
import VerifyEmail, { verifyEmailLoader } from './pages/Auth/VerifyEmail';
import Help from './pages/Help';
import Contact from './pages/Landing/Contact';
import Landing from './pages/Landing/Landing';
import Cookies from './pages/Landing/Legal/Cookies';
import Legal from './pages/Landing/Legal/Legal';
import Privacy from './pages/Landing/Legal/Privacy';
import Loading from './pages/Loading';
import Maintenance from './pages/Maintenance';
import Chains, { activeChainsLoader } from './pages/Manager/Chains';
import ChainsHistory, { chainsLoader } from './pages/Manager/ChainsHistory';
import FailedAlgo from './pages/Manager/FailedAlgo';
import GroupStats, { groupStatsLoader } from './pages/Manager/GroupStats';
import CrossShiftReqs, {
  crossShiftReqsLoader,
} from './pages/Manager/IterationConfig/CrossShiftReqs/CrossShiftReqs';
import Incompatibilities from './pages/Manager/IterationConfig/Incompatibilities/Incompatibilities';
import RecordedDataConfig, {
  recordedDataLoader,
} from './pages/Manager/IterationConfig/RecordedDataConfig';
import OldRolesConfig, {
  oldItrRolesLoader,
} from './pages/Manager/IterationConfig/RolesConfig/OldRolesConfig';
import ServicesConfig, {
  iterationShiftsLoader,
} from './pages/Manager/IterationConfig/ServicesConfig/ServicesConfig';
import SlotSubsetsConfig, {
  slotSubsetLoader,
} from './pages/Manager/IterationConfig/SlotSubsetsConfig/SlotSubsetsConfig';
import Day from './pages/Manager/IterationVerification/Day';
import Global from './pages/Manager/IterationVerification/Global';
import Person from './pages/Manager/IterationVerification/Person';
import SummaryStatistics from './pages/Manager/IterationVerification/SummaryStatistics';
import ManualAssignment, {
  manualAssignmentLoader,
} from './pages/Manager/ManualAssignment';
import ManagerDashboard, {
  managerDashboardLoader,
} from './pages/Manager/ManagerDashboard';
import NewChain from './pages/Manager/NewChain';
import RunningAlg from './pages/Manager/RunningAlg';
import ManagerShiftExchange, {
  managerShiftExchangeLoader,
} from './pages/Manager/ShiftExchange';
import Personnel from './pages/Manager/Team/Personnel';
import Roles from './pages/Manager/Team/Roles';
import UserStatsManager, {
  statsManagerLoader,
} from './pages/Manager/Team/UserStatsManager';
import NotFound from './pages/NotFound';
import Profile, { profileLoader } from './pages/Profile';
import GlobalResults, { globalResultsLoader } from './pages/User/GlobalResults';
import Iterations, { userIterationsLoader } from './pages/User/Iterations';
import Preferences, { userPrefsLoader } from './pages/User/Preferences';
import Results, { resultsLoader } from './pages/User/Results';
import UserShiftExchangeFeed from './pages/User/ShiftExchange/Feed';
import UserShiftExchangeMyRequests from './pages/User/ShiftExchange/MyRequests';
import UserShiftExchange, {
  userShiftExchangeLoader,
} from './pages/User/ShiftExchange/ShiftExchangeLayout';
import UserChainsHistory, {
  userIterationsHistoryLoader,
} from './pages/User/UserChainsHistory';
import UserDashboard, { userDashboardLoader } from './pages/User/UserDashboard';
import UserStats, { userStatsLoader } from './pages/User/UserStats';
import UserVideos from './pages/UserVideos';
import Waitlist from './pages/Waitlist';
import { AppOptionsProvider, useAppOptions } from './utils/AppOptionsContext';
// import { TutorialProvider } from './utils/TutorialContext';
import { canSkipAuth } from './utils/checks';
import { AuthProvider } from './utils/globalContext';
import SectionsConfig, {
  sectionsLoader,
} from './pages/Manager/IterationConfig/Sections/SectionsConfig';
import IterationInitializationLayout from './layouts/IterationInitializationLayout';
import RolesConfig, { itrUsersLoader } from './pages/Manager/IterationConfig/RolesConfig/RolesConfig';
import UserReqRulesConfig, { userReqRulesLoader } from './pages/Manager/IterationConfig/UserReqRules/UserReqRulesConfig';
import { IncompatibilitiesConfig, incompatibilitiesLoader } from './pages/Manager/IterationConfig/Incompatibilities/IncompatibilitiesConfig';
import { PreferencesConfig, preferencesLoader } from './pages/Manager/IterationConfig/PreferencesConfig';
import { CreateNewSection, newSectionLoader } from './pages/Manager/IterationConfig/Sections/CreateNewSection';
import { iterationSectionsLoader } from './pages/Manager/IterationConfig/ServicesConfig/SectionsConfig';
import SectionConfig, { sectionLoader } from './pages/Manager/IterationConfig/Sections/SectionConfig';
import UserReqRuleConfig, { userReqRuleLoader } from './pages/Manager/IterationConfig/UserReqRules/UserReqRuleConfig';
import NewReqRule, { newReqRuleLoader } from './pages/Manager/IterationConfig/UserReqRules/NewReqRule';

const queryClient = new QueryClient();

function SharedRoutes() {
  return (
    <>
      <Route index element={<Navigate to="roles" />} />
      <Route
        path="roles"
        element={<RolesConfig />}
        loader={itrUsersLoader(queryClient)}
      />
      <Route
        path="sections"
        element={<SectionsConfig />}
        loader={sectionsLoader(queryClient)}
      />
      <Route
        path="sections/new"
        element={<CreateNewSection />}
        loader={newSectionLoader(queryClient)}
      />
      <Route
        path="rules"
        element={<UserReqRulesConfig />}
        loader={userReqRulesLoader(queryClient)}
      />
      <Route
        path="rules/new"
        element={<NewReqRule />}
        loader={newReqRuleLoader(queryClient)}
      />
      <Route
        path="incompatibilities"
        element={<IncompatibilitiesConfig />}
        loader={incompatibilitiesLoader(queryClient)}
      />
      <Route
        path="preferences"
        element={<PreferencesConfig />}
        loader={preferencesLoader(queryClient)}
      />
    </>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route element={<PreAuthLayout />}>
        <Route index element={<Landing />} />
        <Route path="signup" element={<SignUpDivider />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="legal" element={<Legal />} />
        <Route path="cookies" element={<Cookies />} />
        <Route path="contact" element={<Contact />} />
        {/* <Route path="history" element={<History />} /> */}
      </Route>
      <Route element={<AuthLayout />} errorElement={<NotFound />}>
        <Route path="login" element={<Login />} loader={canSkipAuth} />
        <Route path="signup/manager" element={<SignUpManager />} />
        <Route path="signup/user" element={<SignUpNonManager />} />
        <Route
          path="signup/user/:token"
          element={<SignUpUser />}
          loader={async ({ params }) => request({
            url: 'user/fetch_user_inv_token',
            method: 'post',
            data: { invitation_token: params.token },
          })}
          errorElement={(
            <NotFound
              text="El enlace no es válido"
              description="Lo sentimos, el enlace con el que intentas acceder no es válido. Es posible que haya caducado (si han pasado más de 5 días desde que lo recibiste), o que haya habido un error. Pídele a tu gestor que te vuelva a invitar o contacta con nosotros en tech@you-shift.com"
            />
          )}
        />
        <Route
          path="signup/user/mock/:token"
          element={<SignUpMockUser />}
          loader={async ({ params }) => request({
            url: 'user/fetch_user_inv_token',
            method: 'post',
            data: { invitation_token: params.token },
          })}
          errorElement={(
            <NotFound
              text="El enlace no es válido"
              description="Lo sentimos, el enlace con el que intentas acceder no es válido. Es posible que haya caducado (si han pasado más de 5 días desde que lo recibiste), o que haya habido un error. Pídele a tu gestor que te vuelva a invitar o contacta con nosotros en tech@you-shift.com"
            />
          )}
        />
        <Route path="/reset_password/:token" element={<ResetPassword />} />
        <Route
          path="/verify_email/:token"
          element={<VerifyEmail />}
          loader={verifyEmailLoader}
          errorElement={(
            <NotFound
              text="El enlace no es válido"
              description="Lo sentimos, el enlace con el que intentas acceder no es válido. Es posible que haya caducado (si han pasado más de 5 días desde que lo recibiste), o que haya habido un error. Si el error persiste, contacta con nosotros en tech@you-shift.com"
            />
          )}
        />
      </Route>
      <Route
        path="manager"
        element={<ManagerLayout />}
        loader={managerLoader(queryClient)}
        errorElement={<NotFound />}
        id="manager"
      >
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="help" element={<Help manager />} />
        <Route path="waitlist" element={<Waitlist />} />
        <Route
          path="dashboard"
          element={<ManagerDashboard />}
          loader={managerDashboardLoader(queryClient)}
          errorElement={<NotFound />}
        />
        <Route
          path="team"
          element={<TeamLayout />}
          loader={personnelLoader(queryClient)}
        >
          <Route index element={<Navigate to="personnel" />} />
          <Route path="roles" element={<Roles />} />
          <Route path="personnel" element={<Personnel />} />
        </Route>
        <Route
          path="stats"
          element={<GroupStats />}
          loader={groupStatsLoader(queryClient)}
        />
        <Route
          path="team/personnel/:id"
          element={<UserStatsManager />}
          loader={statsManagerLoader(queryClient)}
        />
        <Route
          path="chains"
          element={<Chains />}
          loader={activeChainsLoader(queryClient)}
        />
        <Route
          path="chains/history"
          element={<ChainsHistory />}
          loader={chainsLoader(queryClient)}
        />
        <Route path="chains/new" element={<NewChain />} />

        <Route
          path="exchange"
          element={<ManagerShiftExchange />}
          loader={managerShiftExchangeLoader(queryClient)}
        />
        <Route path="iteration/:idItr/initialization" element={<IterationInitializationLayout />}>
          {SharedRoutes()}
          <Route
            path="sections/:idSection"
            element={<SectionConfig />}
            loader={sectionLoader(queryClient)}
          />
          <Route
            path="rules/:idRule"
            element={<UserReqRuleConfig />}
            loader={userReqRuleLoader(queryClient)}
          />
        </Route>
        <Route
          path="iteration/:idItr"
          element={<IterationLayout />}
          loader={iterationLoader(queryClient)}
          errorElement={<NotFound />}
        >
          <Route
            index
            element={<IterationStatus />}
            id="itrLayout"
          />
          <Route path="configuration" element={<IterationConfigLayout />}>
            {SharedRoutes()}
            <Route
              path="sections/:idSection"
              element={<SectionConfig />}
              loader={sectionLoader(queryClient)}
            />
            <Route
              path="rules/:idRule"
              element={<UserReqRuleConfig />}
              loader={userReqRuleLoader(queryClient)}
            />
            {/* <Route path="participants" element={<ParticipantsConfig />} loader={participantsLoader(queryClient)} /> */}
            {/* <Route
              path="slot-subset"
              element={<SlotSubsetsConfig />}
              loader={slotSubsetLoader(queryClient)}
            /> */}
            {/* <Route
              path="services"
              element={<ServicesConfig />}
              loader={iterationShiftsLoader(queryClient)}
            /> */}
            {/* <Route
              path="sections"
              element={<SectionsConfig />}
              loader={iterationSectionsLoader(queryClient)}
            /> */}
            {/* <Route path="advanced" element={<AdvancedItrConfigLayout />}>
              <Route index element={<Navigate to="incompatibilities" />} />
              <Route
                path="incompatibilities"
                element={<Incompatibilities />}
                loader={incompatibilitiesLoader(queryClient)}
              />
              <Route
                path="clusters"
                element={<CrossShiftReqs />}
                loader={crossShiftReqsLoader(queryClient)}
              />
            </Route> */}
            {/* <Route
              path="recorded-data"
              element={<RecordedDataConfig />}
              loader={recordedDataLoader(queryClient)}
            /> */}
          </Route>
          <Route path="running" element={<RunningAlg />} />
          <Route path="failed" element={<FailedAlgo />} />
          <Route
            path="verification"
            element={<IterationVerificationLayout />}
            loader={verificationLoader(queryClient)}
          >
            <Route index element={<Navigate to="day" />} />
            <Route path="all" element={<Global />} />
            <Route path="day" element={<Day />} />
            <Route path="person" element={<Person />} />
            <Route path="summary" element={<SummaryStatistics />} />
          </Route>
        </Route>
        <Route
          path="iteration/:idItr/manual"
          element={<ManualAssignment />}
          loader={manualAssignmentLoader(queryClient)}
        />
        <Route
          path="profile"
          element={<Profile />}
          loader={profileLoader(queryClient)}
        />
      </Route>
      <Route
        path="user"
        element={<UserLayout />}
        loader={userLoader(queryClient)}
        errorElement={<NotFound />}
      >
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="help" element={<Help />} />
        <Route index element={<Navigate to="iterations" />} />
        <Route path="waitlist" element={<Waitlist />} />
        <Route
          path="dashboard"
          element={<UserDashboard />}
          loader={userDashboardLoader(queryClient)}
          errorElement={<NotFound />}
        />
        <Route
          path="iterations"
          element={<Iterations />}
          loader={userIterationsLoader(queryClient)}
        />
        <Route
          path="iterations/history"
          element={<UserChainsHistory />}
          loader={userIterationsHistoryLoader(queryClient)}
        />
        <Route
          path=":idItr/preferences"
          element={<Preferences />}
          loader={userPrefsLoader(queryClient)}
        />
        <Route
          path=":idItr/results"
          element={<Results />}
          loader={resultsLoader(queryClient)}
        />
        <Route
          path=":idItr/results/all"
          element={<GlobalResults />}
          loader={globalResultsLoader(queryClient)}
        />
        <Route
          path="stats"
          element={<UserStats />}
          loader={userStatsLoader(queryClient)}
        />
        <Route
          path="exchange"
          element={<UserShiftExchange />}
          loader={userShiftExchangeLoader(queryClient)}
        >
          <Route index element={<Navigate to="feed" />} />
          <Route path="feed" element={<UserShiftExchangeFeed />} />
          <Route path="requests" element={<UserShiftExchangeMyRequests />} />
        </Route>
        <Route
          path="profile"
          element={<Profile />}
          loader={profileLoader(queryClient)}
        />
        <Route path="videos" element={<UserVideos />} />
      </Route>
      <Route
        path="admin"
        element={<AdminLayout />}
        loader={adminLoader(queryClient)}
        errorElement={<NotFound />}
        id="admin"
      >
        <Route index element={<Navigate to="dashboard" />} />
        <Route
          path="dashboard"
          element={<AdminDashboard />}
          loader={adminDashboardLoader(queryClient)}
          errorElement={<NotFound />}
        />
        <Route
          path="profile"
          element={<Profile />}
          loader={profileLoader(queryClient)}
        />
        <Route
          path="group/:id"
          element={<AdminGroup />}
          loader={adminGroupLoader(queryClient)}
          errorElement={<NotFound />}
        />
        <Route
          path="orgs"
          element={<AllOrgs />}
          loader={orgsLoader(queryClient)}
        />
        <Route
          path="users"
          element={<AllUsers />}
          loader={usersLoader(queryClient)}
        />
        <Route path="tools" element={<Tools />} />
        <Route
          path="stats"
          element={<AdminStats />}
          loader={adminStatsLoader(queryClient)}
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);

const maintenanceRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route element={<PreAuthLayout />}>
        <Route index element={<Landing />} />
        <Route path="signup" element={<SignUpDivider />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="legal" element={<Legal />} />
        <Route path="cookies" element={<Cookies />} />
        <Route path="contact" element={<Contact />} />
      </Route>
      <Route element={<AuthLayout />} errorElement={<NotFound />}>
        <Route path="login" element={<Login />} loader={canSkipAuth} />
        <Route path="signup/manager" element={<SignUpManager />} />
        <Route path="signup/user" element={<SignUpNonManager />} />
        <Route
          path="signup/user/:token"
          element={<SignUpUser />}
          loader={async ({ params }) => request({
            url: 'user/fetch_user_inv_token',
            method: 'post',
            data: { invitation_token: params.token },
          })}
          errorElement={(
            <NotFound
              text="El enlace no es válido"
              description="Lo sentimos, el enlace con el que intentas acceder no es válido. Es posible que haya caducado (si han pasado más de 5 días desde que lo recibiste), o que haya habido un error. Pídele a tu gestor que te vuelva a invitar o contacta con nosotros en tech@you-shift.com"
            />
          )}
        />
        <Route path="/reset_password/:token" element={<ResetPassword />} />
        <Route
          path="/verify_email/:token"
          element={<VerifyEmail />}
          loader={verifyEmailLoader}
          errorElement={(
            <NotFound
              text="El enlace no es válido"
              description="Lo sentimos, el enlace con el que intentas acceder no es válido. Es posible que haya caducado (si han pasado más de 5 días desde que lo recibiste), o que haya habido un error. Si el error persiste, contacta con nosotros en tech@you-shift.com"
            />
          )}
        />
      </Route>
      <Route
        path="manager"
        element={<ManagerLayout />}
        loader={managerLoader}
        errorElement={<Maintenance />}
        id="manager"
      >
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="*" element={<Maintenance />} />
        <Route
          path="profile"
          element={<Profile />}
          loader={profileLoader(queryClient)}
        />
      </Route>
      <Route
        path="user"
        element={<UserLayout />}
        loader={userLoader}
        errorElement={<Maintenance />}
      >
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="*" element={<Maintenance />} />
        <Route
          path="profile"
          element={<Profile />}
          loader={profileLoader(queryClient)}
        />
      </Route>
      <Route path="*" element={<Maintenance />} />
    </Route>,
  ),
);

function UnWrappedApp() {
  const { appOptions, loading, error } = useAppOptions();

  if (loading) return <Loading />;
  if (error) {
    return (
      <div>
        Error:
        {error}
      </div>
    );
  }

  return (
    <AuthProvider>
      <RouterProvider
        router={
          appOptions.app_config.maintenance_mode
            ? maintenanceRouter
            : router
        }
      />
    </AuthProvider>
  );
}

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AppOptionsProvider>
        <UnWrappedApp />
      </AppOptionsProvider>
    </QueryClientProvider>
  );
}

export default App;
