import { Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import {
  MinusCircleIcon,
  PlusCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useParams } from 'react-router-dom';
import { request } from '@youshift/shared/api';

import Alert from '../../../../components/FormFeedback/Alert';

export default function AssignShift({
  open,
  setOpen,
  setSuccess,
  slots,
  itrPrefs,
  shifts,
  participant,
  section,
  slot,
}) {
  const [selectedParticipant, setSelectedParticipant] = useState(participant);
  const [selectedSection, setSelectedSection] = useState(section);
  const [selectedSlot, setSelectedSlot] = useState(slot);
  const [rewards, setRewards] = useState(0);

  const { idItr: id } = useParams();

  const [error, setError] = useState(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const parseData = () => {
    const shiftOutputsIds = {};

    const participants = [];
    const slotsList = [];

    Object.values(itrPrefs).forEach(pref => {
      participants.push({
        id: pref.user.id,
        name: `${pref.user.firstname} ${pref.user.lastname}`,
      });
    });

    Object.values(slots).forEach(slot => {
      slotsList.push({
        id: slot.id_slot,
        start: new Date(slot.start).toLocaleString(undefined, {
          day: '2-digit',
          month: 'long',
          timeZone: 'GMT',
        }),
      });
      Object.values(slot.shift_outputs).forEach(
        ({
          id_user, id_slot, id_shift, id_output, active,
        }) => {
          if (active) {
            const key = `${id_user}-${id_slot}-${id_shift}`;
            shiftOutputsIds[key] = id_output;
          }
        },
      );
    });

    return { shiftOutputsIds, participants, slotsList };
  };

  const sections = Object.values(shifts).map(shift => ({
    id: shift.id_shift,
    name: shift.name,
  }));

  const { shiftOutputsIds, participants, slotsList } = parseData();

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const assign = useMutation({
    mutationFn: () => request({
      url: `manager/itrs/${id}/shift_reassignment/assign`,
      method: 'post',
      data: {
        id_user: selectedParticipant,
        id_slot: selectedSlot,
        id_shift: selectedSection,
        reward_points: rewards,
      },
    }),
    onSuccess: () => {
      queryClient.invalidateQueries(['outputs', id]);
      queryClient.invalidateQueries({ queryKey: ['postExecStatus', id] });
      setSuccess(t('manager.shiftAssignment.successAssign'));
      setError(null);
      setOpen(false);
    },
    onError: () => {
      setError(t('manager.shiftAssignment.errorAssign'));
      setSuccess(null);
    },
  });

  const outputId = shiftOutputsIds[
    `${selectedParticipant}-${selectedSlot}-${selectedSection}`
  ];

  const unassign = useMutation({
    mutationFn: () => request({
      url: `manager/itrs/${id}/shift_reassignment/unassign`,
      method: 'post',
      data: {
        id_output: outputId,
      },
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['outputs', id] });
      queryClient.invalidateQueries({ queryKey: ['postExecStatus', id] });
      setSuccess(t('manager.shiftAssignment.successEliminate'));
      setError(null);
      setOpen(false);
    },
    onError: () => {
      setError(t('manager.shiftAssignment.errorEliminate'));
      setSuccess(null);
    },
  });

  const square = {
    width: '40px',
    height: '40px',
    backgroundColor:
      rewards >= 0 ? 'rgba(0, 157, 113, 0.2)' : 'rgba(255, 48, 48, 0.2)',
    borderRadius: '0.5rem',
    border: `1px solid ${rewards >= 0 ? 'rgba(0, 157, 113, 1)' : 'rgba(255, 48, 48, 1)'}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '0.85rem',
    margin: '0px auto',
    padding: '0px',
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-center">
                  <div
                    className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${outputId ? 'bg-red-100' : 'bg-blue-100'} sm:mx-0 sm:h-10 sm:w-10`}
                  >
                    {outputId ? (
                      <MinusCircleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    ) : (
                      <PlusCircleIcon
                        className="h-6 w-6 text-blue-600"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {outputId
                        ? t('manager.shiftAssignment.eliminateShift')
                        : t('manager.shiftAssignment.assignShift')}
                    </Dialog.Title>
                  </div>
                </div>
                {/* Participant */}
                <div className="my-5 flex flex-row items-start gap-6">
                  <h2 className="font-semibold">
                    {t('manager.shiftAssignment.selectParticipant')}
                  </h2>
                  <div className="items-center">
                    <Listbox
                      value={selectedParticipant}
                      onChange={setSelectedParticipant}
                      disabled={participant}
                    >
                      <div className="relative">
                        <Listbox.Button
                          className={classNames(
                            participant ? 'bg-gray-200' : 'bg-white',
                            'relative cursor-default rounded-lg py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm',
                          )}
                        >
                          <span className="block truncate">
                            {participants.find(
                              participant => participant.id === selectedParticipant,
                            )?.name || '-'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {participants
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map(participant => (
                                <Listbox.Option
                                  key={participant.id}
                                  className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'}`}
                                  value={participant.id}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span
                                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                      >
                                        {participant.name}
                                      </span>
                                      {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                </div>
                {/* Slot */}
                <div className="my-5 flex flex-row items-start gap-6">
                  <h2 className="font-semibold">
                    {t('manager.shiftAssignment.selectSlot')}
                  </h2>
                  <div className="items-center">
                    <Listbox
                      value={selectedSlot}
                      onChange={setSelectedSlot}
                      disabled={slot}
                    >
                      <div className="relative">
                        <Listbox.Button
                          className={classNames(
                            slot ? 'bg-gray-200' : 'bg-white',
                            'relative cursor-default rounded-lg py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm',
                          )}
                        >
                          <span className="block truncate">
                            {slotsList.find(slot => slot.id === selectedSlot)
                              ?.start || '-'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {slotsList.map(slot => (
                              <Listbox.Option
                                key={slot.id}
                                className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'}`}
                                value={slot.id}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                    >
                                      {slot.start}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                </div>
                {/* Section */}
                <div className="my-5 flex flex-row start gap-6">
                  <h2 className="font-semibold">
                    {t('manager.shiftAssignment.selectSection')}
                  </h2>
                  <div className="items-center">
                    <Listbox
                      value={selectedSection}
                      onChange={setSelectedSection}
                      disabled={section}
                    >
                      <div className="relative">
                        <Listbox.Button
                          className={classNames(
                            section ? 'bg-gray-200' : 'bg-white',
                            'relative cursor-default rounded-lg py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm',
                          )}
                        >
                          <span className="block truncate">
                            {sections.find(
                              section => section.id === selectedSection,
                            )?.name || '-'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {sections.map(section => (
                              <Listbox.Option
                                key={section.id}
                                className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'}`}
                                value={section.id}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                    >
                                      {section.name}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                </div>
                {/* Rewards */}
                {!outputId ? (
                  <div className="my-5 flex flex-row start gap-6">
                    <h2 className="font-semibold">
                      {t('manager.shiftAssignment.selectRewards')}
                    </h2>
                    <div className="items-center">
                      <div className="relative">
                        <NumericFormat
                          value={rewards}
                          valueIsNumericString
                          onValueChange={values => setRewards(Number(values.value))}
                          style={square}
                          allowNegative={false}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                {error ? <Alert text={error} /> : null}
                <div className="mt-5 sm:mt-4 flex gap-4">
                  {outputId ? (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto disabled:bg-red-200"
                      onClick={() => {
                        unassign.mutate();
                      }}
                    >
                      {t('generic.eliminate')}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto disabled:bg-blue-200"
                      onClick={() => {
                        assign.mutate();
                      }}
                      disabled={
                        !selectedParticipant
                        || !selectedSection
                        || !selectedSlot
                      }
                    >
                      {t('manager.shiftAssignment.assign')}
                    </button>
                  )}
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    {t('generic.cancel')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
