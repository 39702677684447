import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  PencilSquareIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useQuery } from '@tanstack/react-query';
import {
  iterationQuery,
  sectionQuery,
  slotLabelsQuery,
} from '@youshift/shared/hooks/queries';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  Iteration,
  Section,
  SectionSlotsDict,
  SlotLabel,
} from '@youshift/shared/types';
import { getShadeMap, returnColor } from '@youshift/shared/utils';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';

import { YSButton } from '../../../../components/Buttons';
import DragCloseDrawer from '../../../../components/DragCloseDrawer';
import CreateSlot from '../../../../components/ItrConfig/CreateSlot';
import EditSectionModal from '../../../../components/ItrConfig/EditSectionModal';
import EditShiftLabels from '../../../../components/ItrConfig/EditShiftLabels';
import EditSlot from '../../../../components/ItrConfig/EditSlot';
import Virgueria from '../../../../components/ManualAssignment/Virgueria';
import Wrapper from '../../../../components/Wrapper';
import { requireApproved, requireManager } from '../../../../utils/checks';
import { ArrangedSectionSlot } from './CreateNewSection';

type SectionLoader = {
  section: Section;
  section_slots: SectionSlotsDict;
  shiftLabels: SlotLabel[];
  iteration: Iteration;
};
export const sectionLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<SectionLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined || params.idSection === undefined) {
      return null;
    }
    const { idItr, idSection } = params;
    // TODO: do i need to query the iteration all the time...? prob not
    const iteration = await preLoadQuery(queryClient, iterationQuery(idItr));
    const shiftLabels = await preLoadQuery(queryClient, slotLabelsQuery(idItr));
    const { section, section_slots } = await preLoadQuery(
      queryClient,
      sectionQuery(idItr, idSection),
    );
    return { iteration, section, section_slots, shiftLabels };
  };

export default function SectionConfig() {
  const { iteration, section, section_slots, shiftLabels } =
    useLoaderData() as SectionLoader;
  // using useQuery here instead of loader to make invalidations work
  const { data: sectionSlotsData, isLoading } = useQuery(
    sectionQuery(iteration.id_itr, String(section.id_section)),
  );

  const [isEditSectionModalOpen, setIsEditSectionModalOpen] = useState(false);
  const [isEditSlotModalOpen, setIsEditSlotModalOpen] =
    useState<boolean>(false);
  const [isNewSlotModalOpen, setIsNewSlotModalOpen] = useState<boolean>(false);
  const [slotToEdit, setSlotToEdit] = useState<number | undefined>(undefined);
  const { t } = useTranslation();
  const shadeMap = useMemo(() => getShadeMap(shiftLabels || []), [shiftLabels]);

  const editSlot = (slot: ArrangedSectionSlot | number) => {
    const id = typeof slot === 'number' ? slot : slot.id;
    setIsEditSlotModalOpen(true);
    setSlotToEdit(id);
  };

  if (!sectionSlotsData) {
    return null;
  }

  const { color, name } = sectionSlotsData.section;

  return (
    <Wrapper>
      <div className="flex flex-row justify-between items-center">
        <div className="flex items-center gap-2">
          <div
            className="rounded-full w-6 h-6"
            style={{
              backgroundColor: returnColor(color),
            }}
          />
          <h1 className="text-2xl font-bold">{name}</h1>
          <button onClick={() => setIsEditSectionModalOpen(true)}>
            <PencilSquareIcon className="w-6 h-6 text-gray-600" />
          </button>
        </div>
        <div className="flex items-center gap-2">
          <YSButton variant="ghost-secondary" to=".." relative="path">
            {t('generic.back')}
          </YSButton>
          <YSButton variant="primary">
            <ArrowLongLeftIcon className="h-6 w-6 text-white" />
          </YSButton>
          <YSButton variant="primary">
            <ArrowLongRightIcon className="h-6 w-6 text-white" />
          </YSButton>
        </div>
      </div>
      <div className="flex flex-row justify-between mt-2 items-center">
        <EditShiftLabels shiftLabels={shiftLabels} sectionColor={color} />
        <YSButton
          classNames="flex flex-row gap-0.5 items-center"
          variant="primary"
          onClick={() => setIsNewSlotModalOpen(true)}
        >
          <PlusIcon className="text-white h-5 w-5" />
          {t('manager.servicesConfig.createSlot')}
        </YSButton>
      </div>
      <Virgueria
        start={iteration.start_day}
        end={iteration.end_day}
        sectionsWithSlots={[
          { ...section, section_slots: sectionSlotsData?.section_slots || {} },
        ]}
        shadeMap={shadeMap}
        onClick={editSlot}
        version="section"
      />
      <EditSectionModal
        isOpen={isEditSectionModalOpen}
        onClose={() => setIsEditSectionModalOpen(false)}
        details={section}
      />
      {slotToEdit ? (
        <DragCloseDrawer
          open={isEditSlotModalOpen}
          setOpen={setIsEditSlotModalOpen}
          modalHeight={42}
        >
          <EditSlot
            setOpen={setIsEditSlotModalOpen}
            shiftLabels={shiftLabels}
            slot={section_slots[slotToEdit]}
            idItr={iteration.id_itr}
            idSection={section.id_section}
          />
        </DragCloseDrawer>
      ) : null}
      {isNewSlotModalOpen ? (
        <DragCloseDrawer
          open={isNewSlotModalOpen}
          setOpen={setIsNewSlotModalOpen}
          modalHeight={42}
        >
          <CreateSlot
            setOpen={setIsEditSlotModalOpen}
            shiftLabels={shiftLabels}
            idItr={iteration.id_itr}
            idSection={section.id_section}
          />
        </DragCloseDrawer>
      ) : null}
    </Wrapper>
  );
}
