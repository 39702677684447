/* eslint-disable max-len */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link, useLoaderData, useLocation, useParams,
} from 'react-router-dom';
import { parseIterationDates } from '@youshift/shared/utils';
import { allResultsQuery } from '@youshift/shared/hooks/jsQueries';

import { requireApproved, requireLoggedIn } from '../../utils/checks';
import { bgColor, textColor } from '../../utils/constants';

export const globalResultsLoader = queryClient => async ({ params }) => {
  const user = await requireLoggedIn(queryClient);
  await requireApproved(user);
  const query = allResultsQuery(params.id);
  return {
    data:
      queryClient.getQueryData(query.queryKey)
      ?? (await queryClient.fetchQuery(query)),
    user,
  };
};

export default function GlobalResults() {
  const { data, user } = useLoaderData();

  const { state } = useLocation();
  const { idItr: id } = useParams();
  const textColors = {};
  const bgColors = {};

  const { t } = useTranslation();

  Object.entries(data.shifts).forEach(([key], index) => {
    textColors[key] = textColor[index % textColor.length];
    bgColors[key] = bgColor[index % bgColor.length];
  });

  const parseWeekDay = start => {
    const weekdays = [
      t('calendars.sunday'),
      t('calendars.monday'),
      t('calendars.tuesday'),
      t('calendars.wednesday'),
      t('calendars.thursday'),
      t('calendars.friday'),
      t('calendars.saturday'),
    ];

    const date = new Date(start);
    const weekday = weekdays[date.getUTCDay()];
    const day = date.getUTCDate();

    return `${weekday} ${day}`;
  };

  const slotIds = useMemo(
    () => data.slots
      .filter(slot => Object.values(slot.shift_outputs).some(
        output => output.active && output.id_user === user.id,
      ))
      .map(slot => slot.id_slot),
    [data.slots, user.id],
  );

  const outputsByShift = data.slots
    .filter(slot => slotIds.includes(slot.id_slot))
    .map(slot => {
      const slotOutputs = {};

      Object.values(slot.shift_outputs).forEach(output => {
        const shiftId = output.id_shift;

        if (!output.active) {
          return;
        }

        if (!slotOutputs[shiftId]) {
          slotOutputs[shiftId] = [];
        }

        const { user } = data.itr_prefs.find(
          e => e.id_user === output.id_user,
        );

        slotOutputs[shiftId].push({
          id_user: output.id_user,
          id_output: output.id_output,
          id_slot: output.id_slot,
          shift_name: data.shifts[shiftId].name,
          name: `${user.firstname} ${user.lastname}`,
          role: user.role,
          sink: data.shifts[shiftId].sink,
        });
      });

      return {
        start: slot.start,
        id_slot: slot.id_slot,
        slot_outputs: slotOutputs,
      };
    });

  return (
    <div className="space-y-12 sm:space-y-16 md:mt-16">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex flex-row justify-between">
          <h1 className="text-2xl font-semibold leading-6 text-gray-900 mb-4">
            <time>{`${t('user.results.title')}: ${state && parseIterationDates(state.start_day, state.end_day, state.itr_type)}`}</time>
          </h1>
          <div className="flex items-center">
            <Link
              to={`/user/${id}/results`}
              className="ml-6 rounded-md  bg-gray-400 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-gray-400/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300"
              state={state}
            >
              {t('user.results.goBack')}
            </Link>
          </div>
        </div>
        <div className="mt-4 overflow-x-auto">
          <table className="min-w-full">
            <tbody>
              {outputsByShift.map(slot => (
                <tr key={slot.id_slot} className="">
                  <th className="sticky cursor-pointer bg-white border-b border-r max-w-xs whitespace-nowrap left-0 z-10 py-4 pl-4 pr-3 text-right text-sm font-semibold">
                    {parseWeekDay(slot.start)}
                  </th>
                  {Object.values(slot.slot_outputs).length === 0 ? (
                    <td className="cursor-pointer" />
                  ) : (
                    <td className="border-b">
                      {Object.entries(slot.slot_outputs).map(
                        ([shiftId, shift]) => (
                          <div
                            key={shiftId}
                            className="flex flex-row gap-8 my-2 items-center"
                          >
                            <div className="flex flex-col">
                              <button
                                className={`${textColors[shiftId]} p-3 rounded-md`}
                              >
                                {shift[0].shift_name}
                              </button>
                            </div>
                            {shift.map(person => (
                              <button
                                className={`${bgColors[shiftId]} ${textColors[shiftId]} p-3 rounded-md max-w-xs text-center content-center self-center`}
                                key={person.id_user}
                              >
                                {person.name}
                                {/* <p>{person.role.name}</p> */}
                              </button>
                            ))}
                          </div>
                        ),
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
