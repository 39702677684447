import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import EventSquare from './EventSquare';

export default function SelectOtherShift({ shiftsToSelect, dispatch, shifts }) {
  const { t } = useTranslation();
  return shiftsToSelect.map(event => {
    const date = new Date(event.start);
    return (
      <div className="flex flex-row items-start gap-3 mb-3">
        <div className="pointer-events-none inset-y-0 mt-2 left-0 flex items-center pl-3">
          <EventSquare
            e="S"
            date={date.toLocaleString(undefined, {
              day: '2-digit',
              month: '2-digit',
              timeZone: 'GMT',
            })}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Listbox
            value={event.other_shift_id}
            onChange={e => dispatch({
              type: 'UPDATE_OS_ID',
              id: event.id_slot_pref,
              other_shift_id: e,
            })}
          >
            <Listbox.Button className="relative cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
              <span className="block truncate">
                {shifts.find(s => s.shift.id_shift === event.other_shift_id)
                  ?.shift.name || t('generic.noSelect')}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="relative max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {shifts.map(shift => (
                  <Listbox.Option
                    key={shift.shift.id_shift}
                    className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'}`}
                    value={shift.shift.id_shift}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                        >
                          {shift.shift.name}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </Listbox>
        </div>
      </div>
    );
  });
}
