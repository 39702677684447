import { Switch } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  NavLink,
  useLocation,
  useNavigation,
  useParams,
} from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { classNames, parseIterationDates } from '@youshift/shared/utils';
import { resultsQuery } from '@youshift/shared/hooks/jsQueries';

import EventSquare from '../../components/Calendars/EventSquare';
import ShiftOutputLegend from '../../components/Calendars/ShiftOutputLegend';
import { requireApproved, requireLoggedIn } from '../../utils/checks';
import { bgColor, iconTypes, textColor } from '../../utils/constants';

const { v4: uuidv4 } = require('uuid');

export const resultsLoader = queryClient => async ({ params }) => {
  const user = await requireLoggedIn(queryClient);
  await requireApproved(user);
  const query = resultsQuery(params.id);
  return (
    queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
  );
};

function Results() {
  const { idItr: id } = useParams();
  const { data } = useQuery(resultsQuery(id));

  const { t } = useTranslation();

  const { state } = useLocation();

  const navigation = useNavigation();

  const textColors = {};
  const bgColors = {};

  Object.entries(data.shifts).forEach(([key], index) => {
    textColors[key] = textColor[index % textColor.length];
    bgColors[key] = bgColor[index % bgColor.length];
  });

  const [showPreferences, setShowPreferences] = useState(false);

  const {
    itr_pref, shift_outputs, shifts, slot_prefs,
  } = data;

  function extractSlotsOff() {
    const slotsOffObject = {};

    for (const key in shift_outputs) {
      if (Object.prototype.hasOwnProperty.call(shift_outputs, key)) {
        const slotsOffArray = shift_outputs[key].slots_off;

        // Check if slots_off is an array
        if (Array.isArray(slotsOffArray)) {
          // Iterate through slots_off and index them by id_slot
          slotsOffArray.forEach(slot => {
            const idSlot = slot.id_slot;
            slotsOffObject[idSlot] = slot;
          });
        }
      }
    }

    return slotsOffObject;
  }

  const slotsOff = extractSlotsOff();

  const stats = [
    {
      name: t('user.results.yourRole'),
      stat: itr_pref.user.role.name || 'N/A',
      previousStat: '',
      change: '4.05%',
      changeType: 'decrease',
    },
    {
      name: t('user.results.numberOfShifts'),
      stat: Object.keys(shift_outputs).length || 0,
      previousStat: '',
      change: '2.02%',
      changeType: 'increase',
    },
    {
      name: t('user.results.pointsRespected'),
      stat: `${(((itr_pref.pos_respected + (itr_pref.total_neg - itr_pref.neg_not_respected)) * 100) / (itr_pref.total_pos + itr_pref.total_neg || 1)).toFixed(2)} %`,
      previousStat: `(+) ${itr_pref.pos_respected || 0} de ${itr_pref.total_pos || 0}, (-) ${itr_pref.total_neg - itr_pref.neg_not_respected || 0} de ${itr_pref.total_neg || 0}`,
      change: '12%',
      changeType: 'increase',
    },
    {
      name: t('user.results.pointsAccumulated'),
      stat: `${itr_pref.points_not_used + itr_pref.neg_not_respected}*` || 0,
      previousStat: `${itr_pref.points_not_used || 0} no usados y ${itr_pref.neg_not_respected || 0} no respetados`,
      change: '12%',
      changeType: 'increase',
    },
  ];

  const adjustWeekDay = slot_prefs => {
    const weekdayAbbreviations = {
      Mon: 0,
      Tue: 1,
      Wed: 2,
      Thu: 3,
      Fri: 4,
      Sat: 5,
      Sun: 6,
    };

    const startWeekDay = slot_prefs[0].slot.start.substring(0, 3);
    const endWeekDay = slot_prefs[slot_prefs.length - 1].slot.start.substring(
      0,
      3,
    );
    const startCount = weekdayAbbreviations[startWeekDay];
    const endCount = 6 - weekdayAbbreviations[endWeekDay];
    const fillersStart = Array(startCount)
      .fill(null)
      .map(() => ({
        id_slot_pref: uuidv4(),
        slot: { start: '' },
      }));

    const fillersEnd = Array(endCount)
      .fill(null)
      .map(() => ({
        id_slot_pref: uuidv4(),
        slot: { start: '' },
      }));

    const adjustedShiftsNeeds = [...fillersStart, ...slot_prefs, ...fillersEnd];
    return adjustedShiftsNeeds;
  };

  const globalShiftsViewAllowed = true;

  const totalPointsAwarded = Object.values(shift_outputs).reduce(
    (acc, current) => acc + (current.point_award || 0),
    0,
  );

  return (
    <div className="space-y-12 sm:space-y-16 md:mt-16">
      <div className="px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-semibold leading-6 text-gray-900">
          <time>{`${t('user.results.title')}: ${state && parseIterationDates(state.start_day, state.end_day, state.itr_type)}`}</time>
        </h1>
        <div className="flex flex-row justify-between mt-8 mb-6 align-middle">
          <Switch.Group as="div" className="flex items-center gap-1">
            <Switch
              checked={showPreferences}
              onChange={() => setShowPreferences(!showPreferences)}
              className={classNames(
                showPreferences ? 'bg-blue-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2',
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  showPreferences ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
            <Switch.Label as="span" className="text-sm flex ml-3 flex-row">
              <span className="font-medium text-gray-900">
                {t('user.results.showMyPreferences')}
              </span>
            </Switch.Label>
          </Switch.Group>
          {globalShiftsViewAllowed && (
            <div className="flex items-center">
              <NavLink
                to="all"
                className="ml-6 rounded-md  bg-blue-600 flex flex-row py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                state={state}
              >
                {navigation.state === 'loading' ? (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                ) : null}
                {t('user.results.globalShifts')}
              </NavLink>
            </div>
          )}
        </div>
        <div>
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {t('user.results.inThisItr')}
          </h3>
          <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0">
            {stats.map(item => (
              <div key={item.name} className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">
                  {item.name}
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                    {item.stat}
                    <span className="ml-2 text-sm font-medium text-gray-500">
                      {item.previousStat}
                    </span>
                  </div>
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <ShiftOutputLegend />
        <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col mt-4">
          <div
            className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200
        text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none"
          >
            <div className="bg-white py-2">
              <Trans i18nKey="calendars.mon">
                L
                <span className="sr-only sm:not-sr-only">unes</span>
              </Trans>
            </div>
            <div className="bg-white py-2">
              <Trans i18nKey="calendars.tue">
                M
                <span className="sr-only sm:not-sr-only">artes</span>
              </Trans>
            </div>
            <div className="bg-white py-2">
              <Trans i18nKey="calendars.wed">
                M
                <span className="sr-only sm:not-sr-only">iércoles</span>
              </Trans>
            </div>
            <div className="bg-white py-2">
              <Trans i18nKey="calendars.thu">
                J
                <span className="sr-only sm:not-sr-only">ueves</span>
              </Trans>
            </div>
            <div className="bg-white py-2">
              <Trans i18nKey="calendars.fri">
                V
                <span className="sr-only sm:not-sr-only">iernes</span>
              </Trans>
            </div>
            <div className="bg-white py-2">
              <Trans i18nKey="calendars.sat">
                S
                <span className="sr-only sm:not-sr-only">ábado</span>
              </Trans>
            </div>
            <div className="bg-white py-2">
              <Trans i18nKey="calendars.sun">
                D
                <span className="sr-only sm:not-sr-only">omingo</span>
              </Trans>
            </div>
          </div>
          <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 flex-auto">
            <div
              className={`w-full grid grid-cols-7 grid-rows-${Math.ceil(slot_prefs.length / 7)} gap-px`}
            >
              {adjustWeekDay(slot_prefs).map(slot => {
                const { start, id_slot } = slot.slot;
                const output = shift_outputs[id_slot];
                const slotOff = slotsOff[id_slot];
                return (
                  <div
                    key={slot.id_slot_pref}
                    className={classNames(
                      start ? 'bg-white' : 'bg-gray-50 text-gray-500',
                      'relative py-2 px-3 flex flex-col justify-around h-32',
                    )}
                  >
                    {start ? (
                      <time dateTime={start} className="absolute top-2">
                        {start.slice(5, 7)}
                      </time>
                    ) : null}
                    <div className="flex flex-row justify-center">
                      {start ? (
                        <div className="flex flex-col gap-3">
                          {output ? (
                            <>
                              <IconDisplay type={output?.type} />
                              <span
                                className={`z-0 relative items-center text-xs sm:text-base text-center rounded-md ${bgColors[output.id_shift]} ${textColors[output.id_shift]} sm:py-2 sm:px-1.5 text-gray-600`}
                              >
                                {shifts[output.id_shift].name}
                                {output.point_award ? (
                                  <span className="w-5 h-5 absolute -top-3 -right-2 bg-green-600 rounded-xl flex items-center justify-center">
                                    <p className=" text-white text-xxs text-center margin-auto align-middle ">
                                      +
                                      {output.point_award}
                                    </p>
                                  </span>
                                ) : null}
                              </span>
                            </>
                          ) : null}
                          <Tooltip
                            id="my-tooltip"
                            style={{
                              backgroundColor: 'gray',
                              opacity: 0.5,
                              padding: 5,
                              borderRadius: 4,
                            }}
                          />
                          {slotOff ? <EventSquare e="X" small /> : null}
                          {showPreferences ? (
                            <EventSquare
                              e={slot.preference.toUpperCase()}
                              points={slot.points}
                              justPoints
                              small
                            />
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {totalPointsAwarded ? (
          <p className="font-normal text-black text-xs my-2">
            {t('user.results.pointsRewarded', { points: totalPointsAwarded })}
          </p>
        ) : null}
        <p className="font-normal text-black text-xs my-2">
          {t('user.results.pointsToAccumulate', {
            points: data.chain.max_saved_points_allowed,
          })}
        </p>
      </div>
    </div>
  );
}

export default Results;

function IconDisplay({ type }) {
  const { t } = useTranslation();

  const iconConfig = iconTypes[type];

  if (!iconConfig) return null;

  const { bgColor, Icon } = iconConfig;

  return (
    <p
      className={`relative w-5 h-5 top-5 z-10 p-0.5 right-2 text-center ${bgColor} rounded-xl text-sm text-white`}
      data-tooltip-id="my-tooltip"
      data-tooltip-content={t(`generic.${type}`)}
    >
      <Icon />
    </p>
  );
}
