/* eslint-disable max-len */
import {
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useReducer, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { request } from '@youshift/shared/api';
import { classNames, parseIterationDates } from '@youshift/shared/utils';
import { userPrefsQuery } from '@youshift/shared/hooks/jsQueries';

import EventSquare from '../../components/Calendars/EventSquare';
import Justification from '../../components/Calendars/Justification';
import PreferencesSelector from '../../components/Calendars/PreferencesSelector';
import SelectOtherShift from '../../components/Calendars/SelectOtherShift';
import Alert from '../../components/FormFeedback/Alert';
import Information from '../../components/Information';

const { v4: uuidv4 } = require('uuid');

export const userPrefsLoader = queryClient => async ({ params }) => {
  const query = userPrefsQuery(params.id);
  return (
    // GetQueryData - getting data from storage
    // OR (if data aren't loaded yet)
    // FetchQuery - fetching data from endpoint
    queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
  );
};

export default function Preferences() {
  // useParams return dynamic params got from URL
  const { idItr: id } = useParams();
  // useQuery return data get/fetched from storage/endpoint.
  // parameters defined earlier userPrefQuery
  const { data } = useQuery(userPrefsQuery(id));

  const [infoOpen, setInfoOpen] = useState(false);

  const { state } = useLocation();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [savedChanges, setSavedChanges] = useState(true);

  const { t } = useTranslation();

  const preferencesInformation = {
    title: t('user.preferences.title'),
    text: (
      <p className="text-sm text-gray-500">
        {t('user.preferences.description')}
        <ul className="my-2">
          <li className="mb-2">
            <span className="text-green-400 font-semibold">
              {t('user.preferences.points')}
            </span>
            <Trans i18nKey="user.preferences.usePoints">
              utiliza puntos positivos para los días que
              {' '}
              <span className="font-semibold">sí</span>
              {' '}
              quieras hacer guardia y
              puntos negativos para días que
              {' '}
              <span className="font-semibold">no</span>
              {' '}
              quieras.
            </Trans>
          </li>

          <li className="mb-2">
            <span className="text-yellow-400 font-semibold">
              {t('user.preferences.wildcard')}
            </span>
            {t('user.preferences.useWildcard')}
          </li>
          <li className="mb-2">
            <span className="text-fuchsia-400 font-semibold">
              {t('user.preferences.otherEvents')}
            </span>
            {t('user.preferences.useOtherEvents')}
          </li>
          <li className="mb-2">
            <span className="text-blue-400 font-semibold">
              {t('user.preferences.otherServices')}
            </span>
            {t('user.preferences.useOtherServices')}
          </li>
          <li className="mb-2">
            <span className="text-purple-400 font-semibold">
              {t('user.preferences.vacations')}
            </span>
            {t('user.preferences.useVacations')}
          </li>
        </ul>
        <p className="mt-6">
          <span className="font-semibold">
            {t('user.preferences.important')}
          </span>
          {t('user.preferences.note')}
        </p>
      </p>
    ),
  };

  // Managing SLOT events, each one.
  function slotPrefsReducer(state, action) {
    setSavedChanges(false);
    switch (action.type) {
      case 'UPDATE_POINTS':
        return state.map(slotPref => (slotPref.id_slot_pref === action.id
          ? { ...slotPref, points: action.points }
          : slotPref));

      case 'CHANGE_PREFERENCE':
        return state.map(slotPref => (slotPref.id_slot_pref === action.id
          ? { ...slotPref, preference: action.preference, points: 0 }
          : slotPref));

      case 'UPDATE_JUSTIFICATION':
        return state.map(slotPref => (slotPref.id_slot_pref === action.id
          ? { ...slotPref, justification: action.justification }
          : slotPref));
      case 'UPDATE_OS_ID':
        return state.map(slotPref => (slotPref.id_slot_pref === action.id
          ? { ...slotPref, other_shift_id: action.other_shift_id }
          : slotPref));

      default:
        return state;
    }
  }

  // Managing Preference events, as a hole.
  function itrPrefReducer(state, action) {
    setSavedChanges(false);
    switch (action.type) {
      case 'EDIT_PREFERENCES':
        return {
          ...state,
          [action.payload.fieldName]: action.payload.value,
        };
      default:
        return state;
    }
  }

  const [itrPref, itrPrefDispatch] = useReducer(itrPrefReducer, data.itr_pref);
  const [slotPrefs, slotPrefsDispatch] = useReducer(
    slotPrefsReducer,
    data.slot_prefs,
  );

  const shortItrPrefDispatch = (fieldName, value) => {
    itrPrefDispatch({
      type: 'EDIT_PREFERENCES',
      payload: {
        fieldName,
        value,
      },
    });
  };

  const adjustWeekDay = slot_prefs => {
    const weekdayAbbreviations = {
      Mon: 0,
      Tue: 1,
      Wed: 2,
      Thu: 3,
      Fri: 4,
      Sat: 5,
      Sun: 6,
    };

    const startWeekDay = slotPrefs[0]?.slot?.start.substring(0, 3);
    const endWeekDay = slotPrefs[slotPrefs.length - 1]?.slot?.start.substring(
      0,
      3,
    );
    const startCount = weekdayAbbreviations[startWeekDay];
    const endCount = 6 - weekdayAbbreviations[endWeekDay];
    const fillersStart = Array(startCount)
      .fill(null)
      .map(() => ({
        id_slot_pref: uuidv4(),
      }));

    const fillersEnd = Array(endCount)
      .fill(null)
      .map(() => ({
        id_slot_pref: uuidv4(),
      }));

    return [...fillersStart, ...slot_prefs, ...fillersEnd];
  };

  function toJustify() {
    const filteredSlots = { events: [], shifts: [], slotsOff: new Set() };
    // for (const slot of slotPrefs) {
    for (let i = 0; i < slotPrefs.length; i++) {
      const slot = slotPrefs[i];
      if (slot.preference === 'E') {
        filteredSlots.events.push({
          start: slot.slot.start,
          justification: slot.justification,
          id_slot_pref: slot.id_slot_pref,
        });
      }
      if (slot.preference === 'S') {
        filteredSlots.shifts.push({
          start: slot.slot.start,
          other_shift_id: slot.other_shift_id,
          id_slot_pref: slot.id_slot_pref,
        });
        const typesOfSlotOff = data.shifts
          .find(shift => shift.id_shift == slot.other_shift_id)
          ?.shift_slots_off.filter(
            slotOff => slotOff.id_slot_type == slot.slot.slot_type.id_slot_type,
          )
          ?.map(e => e.slot_off_slot_type);
        if (typesOfSlotOff) {
          for (let t = 0; t < typesOfSlotOff.length; t++) {
            for (let j = i + 1; j < slotPrefs.length; j++) {
              const potentialSlotOff = slotPrefs[j];
              if (
                potentialSlotOff.slot.slot_type.id_slot_type
                === typesOfSlotOff[t]
              ) {
                filteredSlots.slotsOff.add(potentialSlotOff.slot.id_slot);
                break;
              }
            }
          }
        }
      }
    }
    return filteredSlots;
  }
  const {
    events: eventsToJustify,
    shifts: shiftsToSelect,
    slotsOff,
  } = toJustify();

  function getCumulative() {
    let sumPoints = 0;
    let jokerCount = 0;
    let oGCount = 0;
    let ECount = 0;
    let vCount = 0;

    for (const slotPref of slotPrefs) {
      if (slotsOff.has(slotPref.slot.slot_id)) {
        // do nothing
      } else if (slotPref.preference === 'P') {
        sumPoints += Math.abs(slotPref.points);
      } else if (slotPref.preference === 'B') {
        jokerCount++;
      } else if (slotPref.preference === 'S') {
        oGCount++;
      } else if (slotPref.preference === 'E') {
        ECount++;
      } else if (slotPref.preference === 'V') {
        // sumPoints += data.itr.holiday_point_penalty
        vCount++;
      }
    }

    const used = [
      {
        key: t('generic.points'),
        value: sumPoints,
        color: 'fill-green-500',
        max: Math.max(
          data.itr_role.base_points
          + data.itr_pref.not_respected_from_prev
          + data.itr_pref.points_saved_from_prev
          + (data.itr_pref?.reassignment_rewards_counter || 0)
          - data.itr.chain.holiday_point_penalty * vCount,
          0,
        ),
        masc: true,
      },
      {
        key: t('generic.wildcards'),
        value: jokerCount,
        color: 'fill-yellow-500',
        max: data.itr_role.jokers,
        masc: true,
      },
      {
        key: t('generic.otherServices'),
        value: oGCount,
        color: 'fill-blue-300',
        masc: true,
      },
      {
        key: t('generic.otherEvents'),
        value: ECount,
        color: 'fill-pink-500',
        masc: true,
      },
      {
        key: t('generic.vacations'),
        value: vCount,
        color: 'fill-purple-500',
        masc: false,
      },
    ];

    return used;
  }
  const used = getCumulative();

  const minConsHolidaysCheck = () => {
    let consecutiveCount = 0;
    let hasGroupLessThanX = false;

    for (const slot of slotPrefs) {
      if (slot.preference === 'V') {
        consecutiveCount++;
      } else {
        if (
          consecutiveCount > 0
          && consecutiveCount < data?.itr?.chain?.min_cons_holidays
        ) {
          hasGroupLessThanX = true;
          break;
        }
        consecutiveCount = 0;
      }
    }

    return !hasGroupLessThanX;
  };

  const eventsAreJustified = eventsToJustify.every(
    element => element.justification && element.justification.trim() !== '',
  );
  const limitsAreRespected = used[0].value <= used[0].max && used[1].value <= used[1].max;
  const otherShiftsAreSelected = shiftsToSelect.every(
    element => element.other_shift_id,
  );
  const noOverlap = slotPrefs.every(slotPref => {
    const id = slotPref.slot.id_slot;
    const { user_can_modify } = slotPref;
    return user_can_modify || !slotsOff.has(id);
  });
  const minConsHolidaysRespected = minConsHolidaysCheck();

  function cleanArgs(obj) {
    const { itr_pref, slot_prefs } = obj;
    const cleanedObj = {
      itr_pref: {
        P_101: itr_pref.P_101,
        P_77: itr_pref.P_77,
        comment: itr_pref.comment,
        spread: itr_pref.spread,
        volunteer: itr_pref.volunteer,
      },
      slot_prefs: slot_prefs
        .filter(
          (slotPref, index) => data.slot_prefs[index] !== slotPref
            || slotsOff.has(slotPref.slot.id_slot),
        )
        .map(slotPref => ({
          id_slot: slotPref.slot.id_slot,
          other_shift_id: slotsOff.has(slotPref.slot.id_slot)
            ? null
            : slotPref.other_shift_id,
          justification: slotsOff.has(slotPref.slot.id_slot)
            ? ''
            : slotPref.justification,
          points: slotsOff.has(slotPref.slot.id_slot) ? 0 : slotPref.points,
          preference: slotsOff.has(slotPref.slot.id_slot)
            ? 'P'
            : slotPref.preference,
        })),
    };
    return cleanedObj;
  }

  const savePreferences = useMutation({
    mutationFn: async () => {
      await request({
        url: `/user/${id}/edit_user_prefs`,
        method: 'patch',
        data: cleanArgs({ itr_pref: itrPref, slot_prefs: slotPrefs }),
      });
    },
    onSuccess: () => {
      setSuccess(t('generic.changesSaved'));
      setSavedChanges(true);
      setError(false);
    },
    onError: () => {
      setError(t('generic.changesError'));
      setSuccess(false);
    },
  });

  return (
    <>
      {eventsAreJustified ? null : (
        <Alert text={t('user.preferences.eventsNotJustified')} />
      )}
      {otherShiftsAreSelected ? null : (
        <Alert text={t('user.preferences.shiftsNotSelected')} />
      )}
      {limitsAreRespected ? null : (
        <Alert text={t('user.preferences.limitsNotRespected')} />
      )}
      {minConsHolidaysRespected ? null : (
        <Alert
          text={t('user.preferences.minConsHolidaysRespected', {
            count: data.itr.chain.min_cons_holidays,
          })}
        />
      )}
      {noOverlap ? null : (
        <Alert text="La libranza de uno de los 'otros servicios' que has marcado entra en conflicto con un evento o servicio asignado por tu gestor. Reinicia la página e inténtalo de nuevo." />
      )}
      <Information
        open={infoOpen}
        setOpen={setInfoOpen}
        title={preferencesInformation.title}
        text={preferencesInformation.text}
      />
      <div className="space-y-12 sm:space-y-16 md:mt-16">
        <div className="lg:flex lg:h-full lg:flex-col px-4 sm:px-6 lg:px-8">
          <header className="flex items-center justify-between lg:flex-none">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">
              <time dateTime="2022-01">
                {state
                  && parseIterationDates(
                    state.start_day,
                    state.end_day,
                    state.itr_type,
                  )}
              </time>
            </h1>
            <div className="flex items-center">
              {savedChanges ? (
                <p className=" p-3 rounded-xl">{t('generic.savedChanges')}</p>
              ) : (
                <button
                  type="button"
                  className="ml-6 rounded-md cursor-pointer disabled:cursor-not-allowed disabled:opacity-50  bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  disabled={
                    !eventsAreJustified
                    || !otherShiftsAreSelected
                    || !limitsAreRespected
                    || !minConsHolidaysRespected
                    || !noOverlap
                  }
                  onClick={() => savePreferences.mutate()}
                >
                  {t('user.preferences.savePreferences')}
                </button>
              )}
            </div>
          </header>
          <div className="flex md:flex-row flex-col my-6 justify-between">
            {used.map(badge => (
              <span className="inline-flex items-center gap-x-1.5 rounded-md px-4 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                <svg
                  className={`h-1.5 w-1.5 ${badge.color}`}
                  viewBox="0 0 6 6"
                  aria-hidden="true"
                >
                  <circle cx={3} cy={3} r={3} />
                </svg>
                {`${badge.key} ${t('user.preferences.used', { context: badge.masc ? 'male' : 'female' })}: ${badge.value} ${badge?.max || badge?.max === 0 ? `/ ${badge.max}` : ''}`}
              </span>
            ))}
            <button type="button" onClick={() => setInfoOpen(true)}>
              <InformationCircleIcon className="text-blue-600 h-8" />
            </button>
          </div>
          {error ? <Alert text={error} /> : null}
          {success ? <Alert success text={success} /> : null}

          {data.itr.message && (
            <div>
              <div className="flex flex-row items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 text-blue-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                  />
                </svg>
                <label
                  htmlFor="comment"
                  className="block text-md font-medium leading-6 text-gray-900"
                >
                  {t('user.iterations.managerMessage')}
                </label>
              </div>
              <p className="mt-2 text-sm font-normal text-gray-700 p-3 border border-gray-300 rounded-md">
                {data.itr.message}
              </p>
            </div>
          )}
          <section className="mb-6 mt-2">
            <label className="relative">
              <input
                type="checkbox"
                className="peer/showLabel absolute scale-0"
              />
              <span className="flex peer-checked/showLabel:hidden">
                <ChevronDownIcon className="absolute right-2 w-10 p-2 text-white cursor-pointer" />
              </span>
              <span className="hidden peer-checked/showLabel:flex">
                <ChevronUpIcon className="absolute right-2 w-10 p-2 text-white cursor-pointer" />
              </span>
              <span className="block max-h-10 max-w-100 overflow-hidden rounded-lg py-0 text-white font-bold  shadow-md transition-all duration-300 peer-checked/showLabel:max-h-fit">
                <h3 className="flex h-10 cursor-pointer items-center px-4 bg-blue-600">
                  {t('user.preferences.myConfig')}
                </h3>
                <div className="shadow ring-1 px-4 ring-black ring-opacity-5 flex flex-auto flex-col my-3 h-100">
                  <div className="grid grid-cols-3 gap-px border-b border-gray-300 bg-gray-200 text-xs leading-6 font-semibold text-gray-700 lg:flex-none">
                    <div className="bg-white p-2">
                      {t('user.preferences.shift')}
                    </div>
                    <div className="bg-white p-2">
                      {t('user.preferences.minShift')}
                    </div>
                    <div className="bg-white p-2">
                      {t('user.preferences.maxShift')}
                    </div>
                  </div>

                  {data.user_role_reqs.map(
                    req => (req.min_req != 0 || req.max_req != 0) && (
                      <div className="grid grid-cols-3 bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                        <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                          {req.shift.name}
                        </div>
                        <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                          {req.min_req}
                        </div>
                        <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                          {req.max_req}
                        </div>
                      </div>
                    ),
                  )}
                  {data.slot_subset_reqs
                    ? data.slot_subset_reqs.map(slot_subset_req => (
                      <div className="grid grid-cols-3 bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                        <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                          {
                            data.slot_subsets[slot_subset_req.id_slot_subset]
                              .name
                          }
                        </div>
                        <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                          {slot_subset_req.min_req}
                        </div>
                        <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                          {slot_subset_req.max_req}
                        </div>
                      </div>
                    ))
                    : null}
                </div>
                <p className="text-gray-600 font-normal text-sm my-2 ml-4">
                  <span className="font-bold">
                    {t('user.preferences.pointsBreakdownBold')}
                  </span>
                  {t('user.preferences.pointsBreakdownExplanation', {
                    points: data.itr_role.base_points,
                    notRespected: data.itr_pref.not_respected_from_prev,
                    accumulated: data.itr_pref.points_saved_from_prev,
                    rewards: data.itr_pref?.reassignment_rewards_counter || 0,
                    holidays:
                      data.itr.chain.holiday_point_penalty * used[4].value,
                    total: used[0].max,
                  })}
                </p>
                <p className="font-normal text-gray-600 text-xs ml-4 my-2">
                  {t('user.preferences.pointsToAccumulate', {
                    points: data.itr.chain.max_saved_points_allowed,
                  })}
                </p>
              </span>
            </label>
          </section>

          <div className="shadow ring-1 ring-black ring-opacity-5 flex flex-auto flex-col">
            <div className="hidden lg:grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
              <div className="bg-white py-2">
                <Trans i18nKey="calendars.mon">
                  L
                  <span className="sr-only sm:not-sr-only">unes</span>
                </Trans>
              </div>
              <div className="bg-white py-2">
                <Trans i18nKey="calendars.tue">
                  M
                  <span className="sr-only sm:not-sr-only">artes</span>
                </Trans>
              </div>
              <div className="bg-white py-2">
                <Trans i18nKey="calendars.wed">
                  M
                  <span className="sr-only sm:not-sr-only">iércoles</span>
                </Trans>
              </div>
              <div className="bg-white py-2">
                <Trans i18nKey="calendars.thu">
                  J
                  <span className="sr-only sm:not-sr-only">ueves</span>
                </Trans>
              </div>
              <div className="bg-white py-2">
                <Trans i18nKey="calendars.fri">
                  V
                  <span className="sr-only sm:not-sr-only">iernes</span>
                </Trans>
              </div>
              <div className="bg-white py-2">
                <Trans i18nKey="calendars.sat">
                  S
                  <span className="sr-only sm:not-sr-only">ábado</span>
                </Trans>
              </div>
              <div className="bg-white py-2">
                <Trans i18nKey="calendars.sun">
                  D
                  <span className="sr-only sm:not-sr-only">omingo</span>
                </Trans>
              </div>
            </div>
            <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
              <div className="w-full sm:grid sm:grid-cols-7 sm:grid-rows-5 sm:gap-px">
                {adjustWeekDay(slotPrefs).map(slotPref => (
                  <div
                    key={slotPref.id_slot_pref}
                    className={classNames(
                      slotPref.slot?.start
                        ? 'bg-white'
                        : 'bg-gray-50 text-gray-500',
                      'relative py-2 px-3 flex flex-col justify-around',
                    )}
                  >
                    {/* display el dia */}
                    <time dateTime={slotPref.slot?.start}>
                      {slotPref.slot?.start
                        ? slotPref.slot.start.slice(5, 7)
                        : ''}
                    </time>
                    <div className="flex flex-row justify-center">
                      {slotPref.slot?.start ? (
                        slotsOff.has(slotPref.slot?.id_slot) ? (
                          <EventSquare e="X" small />
                        ) : slotPref.user_can_modify ? (
                          <PreferencesSelector
                            maxPoints={data.itr_role.base_points}
                            id={slotPref.id_slot_pref}
                            dispatch={slotPrefsDispatch}
                            preference={slotPref.preference}
                            points={slotPref.points}
                          />
                        ) : (
                          <EventSquare
                            e={slotPref.preference}
                            points={slotPref.points}
                            justPoints
                          />
                        )
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {data.itr.chain.holiday_point_penalty && (
            <p className="font-normal text-gray-600 text-xs ml-4 my-2">
              *
              {t('user.preferences.vacationPoints', {
                points: data.itr.chain.holiday_point_penalty,
              })}
            </p>
          )}

          <p className="mb-2 mt-4 font-semibold text-blue-600">
            {t('user.preferences.otherConfig')}
          </p>
          <div className="mb-4 rounded-md flex flex-col gap-3">
            <div className="flex flex-row gap-1 bg-gray-50 shadow-md p-3 rounded-md w-fit">
              <p>{t('user.preferences.maxDobletes')}</p>
              <input
                id="dobletes"
                name="dobletes"
                type="number"
                value={itrPref.P_101}
                onChange={e => shortItrPrefDispatch('P_101', e.target.value)}
                autoComplete="off"
                min="0"
                className="border border-solid border-blue-600 ml-3 w-11 h-7 pl-2 rounded-md block p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              />
            </div>
            <div className="flex flex-row gap-1 items-center bg-gray-50 shadow-md p-3 rounded-md w-fit">
              <p>{t('user.preferences.sameDay')}</p>
              <input
                id="same-day"
                name="same-day"
                type="checkbox"
                checked={itrPref.P_77}
                onChange={e => shortItrPrefDispatch('P_77', e.target.checked)}
                className="m-2 h-5 w-5 rounded-md border border-solid border-blue-600"
              />
            </div>
            <div className="flex flex-row gap-1 items-center bg-gray-50 shadow-md p-3 rounded-md w-fit">
              <p>{t('user.preferences.separation')}</p>
              <input
                id="spread"
                name="spread"
                type="checkbox"
                checked={itrPref.spread}
                onChange={e => shortItrPrefDispatch('spread', e.target.checked)}
                className="m-2 h-5 w-5 rounded-md border border-solid border-blue-600"
              />
            </div>
            <div className="flex flex-row gap-1 items-center bg-gray-50 shadow-md p-3 rounded-md w-fit">
              <p>{t('user.preferences.volunteer')}</p>
              <input
                id="volunteer"
                name="volunteer"
                type="checkbox"
                checked={itrPref.volunteer}
                onChange={e => shortItrPrefDispatch('volunteer', e.target.checked ? 1 : 0)}
                className="m-2 h-5 w-5 rounded-md border border-solid border-blue-600"
              />
            </div>
          </div>

          <p className="mb-2 font-semibold text-blue-600">
            {t('user.preferences.comments')}
          </p>
          <Justification
            eventsToJustify={eventsToJustify}
            dispatch={slotPrefsDispatch}
          />
          <SelectOtherShift
            shiftsToSelect={shiftsToSelect}
            dispatch={slotPrefsDispatch}
            shifts={data.user_role_reqs}
          />
          <div>
            <div className="mt-2">
              <textarea
                rows={4}
                name="comment"
                id="comment"
                className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:py-1.5 sm:text-sm sm:leading-6"
                placeholder={t('generic.otherComments')}
                value={itrPref.comment}
                onChange={e => shortItrPrefDispatch('comment', e.target.value)}
              />
            </div>
          </div>
          <div className="mt-8 mb-4 mx-auto">
            {savedChanges ? (
              <p className=" p-3 rounded-xl">{t('generic.savedChanges')}</p>
            ) : (
              <button
                type="button"
                className="ml-6 rounded-md cursor-pointer disabled:cursor-not-allowed disabled:opacity-50  bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                disabled={
                  !eventsAreJustified
                  || !otherShiftsAreSelected
                  || !limitsAreRespected
                  || !minConsHolidaysRespected
                  || !noOverlap
                }
                onClick={() => savePreferences.mutate()}
              >
                {t('user.preferences.savePreferences')}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
