import {
  NumberFormatValues,
  NumericFormat,
  SourceInfo,
} from 'react-number-format';

// Counter component
interface MinMaxCounterProps {
  min: number | undefined;
  setMin: (value: number) => void;
  max: number | undefined;
  setMax: (value: number) => void;
  disabled?: boolean;
}

export function MinMaxCounter({
  min,
  setMin,
  max,
  setMax,
  disabled = false,
}: MinMaxCounterProps) {
  const handleMinChange = (
    values: NumberFormatValues,
    sourceInfo: SourceInfo,
  ) => {
    if (sourceInfo.source === 'event' && sourceInfo.event) {
      setMin(parseInt(values.value, 10));
    }
  };

  const handleMaxChange = (
    values: NumberFormatValues,
    sourceInfo: SourceInfo,
  ) => {
    if (sourceInfo.source === 'event' && sourceInfo.event) {
      setMax(parseInt(values.value, 10));
    }
  };

  return (
    <div className="flex border border-gray-600 space-x-0 rounded-md">
      <NumericFormat
        value={disabled ? '' : min}
        valueIsNumericString
        onValueChange={handleMinChange}
        allowNegative={false}
        disabled={disabled}
        className={`rounded-md border-none w-16 text-center focus:ring-transparent ${disabled && 'bg-gray-100'}`}
      />
      <div className="border-l border-gray-600" />
      <NumericFormat
        value={disabled ? '' : max}
        valueIsNumericString
        onValueChange={handleMaxChange}
        thousandSeparator={false}
        allowNegative={false}
        disabled={disabled}
        className={`rounded-md border-none w-16 text-center focus:ring-transparent ${disabled && 'bg-gray-100'}`}
      />
    </div>
  );
}
