/* eslint-disable react/require-default-props */
import { ColorIndex, ColorName, returnColor } from '@youshift/shared/utils';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon, CheckIcon } from '@heroicons/react/24/outline';

import type { ArrangedSectionSlot } from '../pages/Manager/IterationConfig/Sections/CreateNewSection';

interface SectionSlotProps {
  slot: ArrangedSectionSlot;
  sectionColor: ColorName;
  shade: ColorIndex;
  onClick: (slot: ArrangedSectionSlot) => void;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  children?: ReactNode;
}

interface SectionSlotSelectProps {
  slot: ArrangedSectionSlot;
  sectionColor: ColorName;
  shade: ColorIndex;
  onClick: (slotId: number) => void;
  children?: ReactNode;
  selectedSlots: Set<number>;
}

export function SectionSlot({
  slot,
  sectionColor,
  shade,
  onClick,
  Icon,
  children,
}: SectionSlotProps) {
  const { t } = useTranslation();

  return (
    <div
      key={`${slot.start_time}-${slot.day_idx}-${slot.id_slot_label}`}
      className="rounded-md flex flex-col justify-between relative gap-0.5"
      style={{
        backgroundColor: returnColor(sectionColor, shade),
        gridColumnStart: slot.start_position,
        gridColumnEnd: slot.end_position,
      }}
    >
      <button
        className="absolute top-0 left-0 ml-0.5 mt-0.5"
        onClick={() => onClick(slot)}
        aria-label={t('generic.delete')}
      >
        <Icon className="h-3 w-3" />
      </button>

      {/* Main content container to avoid overlap */}
      <div className="flex flex-col items-center">
        <p
          className={`text-center ${
            slot.duration_minutes < 480
              ? 'text-xs px-0.5 pt-4 pb-1'
              : 'text-xs px-2 pt-4 pb-1'
          }`}
        >
          {`${slot.start_time} - ${slot.end_time}`}
        </p>
        {/* {children} */}
      </div>
      <div className="flex flex-row justify-center gap-1 mx-1">
        <p className="px-2 text-gray-600 text-xs bg-white rounded-sm">
          {slot.min_need}
        </p>
        <p className="px-2 text-gray-600 text-xs bg-white rounded-sm">
          {slot.max_need}
        </p>
      </div>
      {/* Rest period container */}
      <div className="flex justify-end w-full">
        <p className="text-xxs rounded-lg bg-white text-black w-min p-1 mb-0.5 mr-0.5">
          {`${slot.rest_period}`}
        </p>
      </div>
    </div>
  );
}

export function SectionSlotSelect({
  slot,
  sectionColor,
  shade,
  onClick,
  children,
  selectedSlots,
}: SectionSlotSelectProps) {
  const { t } = useTranslation();
  if (slot.id === undefined) {
    return null;
  }
  const selected = selectedSlots.has(slot.id);
  return (
    <button
      key={`${slot.start_time}-${slot.day_idx}-${slot.id_slot_label}`}
      className="rounded-md flex flex-col items-center justify-around relative gap-0.5 mt-1"
      style={{
        backgroundColor: selected ? returnColor(sectionColor, shade) : 'white',
        border: '1px solid green',
        gridColumnStart: slot.start_position,
        gridColumnEnd: slot.end_position,
      }}
      onClick={() => onClick(slot.id!)}
    >
      {/* Main content container to avoid overlap */}
      <div className="flex flex-col items-center">
        <p
          className={`text-center ${
            slot.duration_minutes < 480
              ? 'text-xs px-0.5 pt-4 pb-1'
              : 'text-xs px-2 pt-4 pb-1'
          }`}
        >
          {`${slot.start_time} - ${slot.end_time}`}
        </p>
      </div>
      <CheckCircleIcon
        className={`${selected ? 'block' : 'invisible'} text-white w-8 h-8 mx-auto`}
      />
    </button>
  );
}
