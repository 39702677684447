import { ReactNode } from 'react';

interface WrapperProps {
  children: ReactNode;
  mt?: 'mt-4' | 'mt-8' | 'mt-16';
}

function Wrapper({ children, mt = 'mt-16' }: WrapperProps) {
  return <div className={mt}>{children}</div>;
}

export default Wrapper;
