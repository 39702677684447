import { Dialog, Disclosure } from '@headlessui/react';
import { ChevronUpIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

const tabs = [{ name: 'Users', href: '#', current: true }];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ReqsInterval({
  min = Math.floor(Math.random() * (3 - 1 + 1)) + 1,
  value = Math.floor(Math.random() * (10 - 1 + 1)) + 1,
  max = Math.floor(Math.random() * (10 - 7 + 1)) + 7,
}) {
  const withinBounds = min <= value && max >= value;

  return (
    <p className="ml-3">
      <span className="text-sm">{min}</span>
      {' '}
      &lt;
      {' '}
      <span
        className={`${withinBounds ? 'text-green-600' : 'text-red-600'} text-base`}
      >
        {value}
      </span>
      {' '}
      &lt;
      {' '}
      <span className="text-sm">{max}</span>
    </p>
  );
}

export default function ToolDrawer({ open, setOpen, people }) {
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Dialog.Panel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div className="p-6">
                  <div className="flex items-start justify-between">
                    <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                      Team
                    </Dialog.Title>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-500"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-200">
                  <div className="px-6">
                    <nav className="-mb-px flex space-x-6">
                      {tabs.map(tab => (
                        <a
                          key={tab.name}
                          href={tab.href}
                          className={classNames(
                            tab.current
                              ? 'border-blue-500 text-blue-600'
                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
                          )}
                        >
                          {tab.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
                <ul
                  role="list"
                  className="flex-1 divide-y divide-gray-200 overflow-y-auto"
                >
                  {people.map(person => (
                    <li>
                      <div className="w-full px-4">
                        <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2">
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="flex w-full justify-start rounded-lg px-4 py-2 text-left text-sm font-medium items-center focus:outline-none focus-visible:ring">
                                  <ChevronUpIcon
                                    className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-600`}
                                  />
                                  <span>{person.name}</span>
                                  <ReqsInterval
                                    min={undefined}
                                    max={undefined}
                                    value={undefined}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="px-4 py-1 text-sm text-gray-500">
                                  <div className="flex flex-row gap-2 items-center">
                                    <p>PLANTA</p>
                                    <ReqsInterval
                                      min={undefined}
                                      max={undefined}
                                      value={undefined}
                                    />
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        </div>
                      </div>
                      {/* <Menu as="div" className="relative ml-2 inline-block flex-shrink-0 text-left">
                          <Menu.Button className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open options menu</span>
                            <span className="flex h-full w-full items-center justify-center rounded-full">
                              <EllipsisVerticalIcon
                                aria-hidden="true"
                                className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              />
                            </span>
                          </Menu.Button>
                          <Menu.Items
                            transition
                            className="absolute right-9 top-0 z-10 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                          >
                            <div className="py-1">
                              <Menu.Item>
                                <a
                                  href="#"
                                  className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                >
                                  View profile
                                </a>
                              </Menu.Item>
                              <Menu.Item>
                                <a
                                  href="#"
                                  className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                >
                                  Send message
                                </a>
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Menu> */}
                    </li>
                  ))}
                </ul>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
