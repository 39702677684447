import { NumericFormat } from 'react-number-format';

import { square } from '../../utils/constants';

export default function ClustersCounter({
  maxReq,
  minReq,
  setMinReq,
  setMaxReq,
  disabled,
}) {
  const changePoints = (increment, min) => {
    let ctPoints = min ? Number(minReq) : Number(maxReq);

    if (increment) {
      ctPoints++;
    } else if (ctPoints > 0) {
      ctPoints--;
    }

    if (min) {
      setMinReq(ctPoints);
      if (ctPoints > maxReq) {
        setMaxReq(ctPoints);
      }
    } else {
      setMaxReq(ctPoints);
      if (ctPoints < minReq) {
        setMinReq(ctPoints);
      }
    }

    if (minReq < 0) setMinReq(0);
    if (maxReq < 0) setMaxReq(0);
  };

  return (
    <div className="flex gap-2 justify-center">
      {/* min selector */}
      <div className="flex flex-col items-center">
        <button onClick={() => changePoints(true, true)} disabled={disabled}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v12m6-6H6"
            />
          </svg>
        </button>
        <NumericFormat
          value={minReq}
          valueIsNumericString
          isAllowed={values => {
            const { value } = values;
            return value >= 0;
          }}
          disabled={disabled}
          onValueChange={values => setMinReq(parseInt(values.value, 10))}
          style={square}
        />
        <button onClick={() => changePoints(false, true)} disabled={disabled}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
          </svg>
        </button>
      </div>
      {/* max selector */}
      <div className="flex flex-col items-center">
        <button onClick={() => changePoints(true, false)} disabled={disabled}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v12m6-6H6"
            />
          </svg>
        </button>
        <NumericFormat
          value={maxReq}
          isAllowed={values => {
            const { value } = values;
            return value >= 0;
          }}
          disabled={disabled}
          onValueChange={values => setMaxReq(parseInt(values.value, 10))}
          style={square}
        />
        <button onClick={() => changePoints(false, false)} disabled={disabled}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
          </svg>
        </button>
      </div>
    </div>
  );
}
