/* eslint-disable no-else-return */

import { Params } from 'react-router-dom';

export const parseIterationDates = (
  start_day: string,
  end_day: string,
  itr_type: string,
) => {
  const start_date = new Date(start_day);
  const end_date = new Date(end_day);

  if (itr_type === 'Month') {
    const year = start_date.getUTCFullYear();
    const month = start_date.toLocaleString('default', {
      month: 'long',
      timeZone: 'GMT',
    });
    return `${month} ${year}`;
  } else {
    const start_day = start_date.toLocaleString('default', {
      day: 'numeric',
      month: 'long',
      timeZone: 'GMT',
    });
    const adjustedEndDate = new Date(end_date);
    adjustedEndDate.setDate(adjustedEndDate.getDate() - 1);

    const end_day = adjustedEndDate.toLocaleString('default', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'GMT',
    });
    return `${start_day} - ${end_day}`;
  }
};

export function getIdFromParams(params: Params): string {
  return params.id ?? '';
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

// Helper function to get the day of the week (0 for Sunday, 6 for Saturday)
export function getDayOfWeek(date: Date) {
  return date.getUTCDay();
}

// Helper function to add days to a date
export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setUTCDate(result.getUTCDate() + days);
  return result;
}

// Helper function to subtract days from a date
export function subtractDays(date: Date, days: number) {
  return addDays(date, -days);
}

export function getDifferenceInHours(startDate: string, endDate: string) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds =
    end.getMilliseconds() - start.getMilliseconds();

  // Convert milliseconds to hours
  const differenceInHours = Math.ceil(
    differenceInMilliseconds / (1000 * 60 * 60),
  );

  return differenceInHours;
}

export function getCustomDateRange(startDate: string, endDate: string) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference to adjust the start to the previous Monday and end to the next Sunday
  const startDayOfWeek = getDayOfWeek(start);
  const endDayOfWeek = getDayOfWeek(end);

  const startAdjustment = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1; // Adjust Sunday (0) to be the previous Monday
  const endAdjustment = endDayOfWeek === 0 ? 0 : 7 - endDayOfWeek; // Adjust Sunday (0) to stay the same

  const adjustedStart = subtractDays(start, startAdjustment);
  const adjustedEnd = addDays(end, endAdjustment);

  const daysMatrix = [];
  let currentWeek = [];
  let currentDay = adjustedStart;

  while (currentDay <= adjustedEnd) {
    currentWeek.push(new Date(currentDay));
    if (currentWeek.length === 7) {
      daysMatrix.push(currentWeek);
      currentWeek = [];
    }
    currentDay = addDays(currentDay, 1);
  }

  // Add the last week if it wasn't completed
  if (currentWeek.length > 0) {
    daysMatrix.push(currentWeek);
  }
  return daysMatrix;
}
