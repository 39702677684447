import { Navigate, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { statusQuery } from '@youshift/shared/hooks/jsQueries';

import { useItrContext } from './IterationLayout';

function IterationStatus() {
  const { status } = useItrContext();

  // prevent people from going to any route
  if (status === 'configuration') {
    return <Navigate to="configuration" />;
  }
  if (status === 'initialization') {
    return <Navigate to="initialization" />;
  }
  if (status === 'running-algo') {
    return <Navigate to="running" />;
  }
  if (status === 'failed-algo') {
    return <Navigate to="failed" />;
  }
  if (
    status === 'verification'
    || status === 'published'
    || status === 'archived'
  ) {
    return <Navigate to="verification" />;
  }
}

export default IterationStatus;
