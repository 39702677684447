import {
  CheckBadgeIcon,
  FolderPlusIcon,
  PencilSquareIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { QueryClient } from '@tanstack/react-query';
import { sectionsQuery } from '@youshift/shared/hooks/queries';
import { preLoadQuery } from '@youshift/shared/hooks';
import { Section } from '@youshift/shared/types';
import { useTranslation } from 'react-i18next';
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';

import { EmptyState } from '../../../../components/EmptyState';
import { requireApproved, requireManager } from '../../../../utils/checks';
import Wrapper from '../../../../components/Wrapper';

type SectionsLoader = Section[];
export const sectionsLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<SectionsLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const sections = await preLoadQuery(
      queryClient,
      sectionsQuery(params.idItr),
    );
    return sections;
  };

export default function SectionsConfig() {
  const sections = useLoaderData() as SectionsLoader;
  const { t } = useTranslation();
  const navigate = useNavigate();
  console.log(sections);

  if (!sections.length) {
    return (
      <Wrapper>
        <EmptyState
          title={t('manager.servicesConfig.createPrompt')}
          subtitle={t('manager.servicesConfig.definition')}
          buttonText={t('manager.servicesConfig.newShift')}
          Icon={FolderPlusIcon}
          onClick={() => navigate('./new')}
        />
      </Wrapper>
    );
  }

  return (
    <div className="py-6 h-full flex">
      <div className="space-y-2 max-w-lg flex-grow">
        {sections.map(section => (
          <div
            key={section.id_section}
            className="grid grid-cols-2 bg-white rounded-lg border border-gray-200 p-3"
          >
            <div
              className="flex items-center space-x-3 border-r-2"
              style={{ borderRightColor: section.color }}
            >
              <span className="text-lg font-medium">{section.name}</span>
              <Link
                to={`${section.id_section}`}
                className="p-1 rounded-full border border-gray-300"
              >
                <PencilSquareIcon className="w-5 h-5 text-gray-400" />
              </Link>
            </div>
            <div className="flex items-center justify-self-end space-x-3">
              <div className="p-1 rounded-full bg-green-500">
                <CheckBadgeIcon className="w-5 h-5 text-white" />
              </div>
            </div>
          </div>
        ))}
        <div className="flex items-center justify-between bg-gray-100 rounded-lg p-3 text-gray-400">
          <span>{t('manager.servicesConfig.addSection')}</span>
          <button
            onClick={() => navigate('./new')}
            className="p-1 rounded-full border border-gray-300"
            aria-label={t('manager.servicesConfig.addSection')}
          >
            <PlusIcon className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}
