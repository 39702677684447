/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Navigate, useOutletContext, useParams } from 'react-router-dom';
import { request } from '@youshift/shared/api';

import { ReactComponent as Logo } from '../../assets/Logo/youshift_logotype.svg';
import { requireApproved, requireManager } from '../../utils/checks';

export const runningLoader = queryClient => async ({ params }) => {
  const user = await requireManager(queryClient);
  await requireApproved(user);
  return true;
};

export default function RunningAlg() {
  const { idItr: id } = useParams();
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const [status, locked] = useOutletContext();

  const interruptAlgMutation = useMutation({
    mutationFn: () => request({
      url: `/manager/itrs/${id}/flow/interrupt_alg`,
      method: 'post',
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] });
    },
  });

  return (
    <div className="flex flex-col content-center justify-center items-center my-16">
      {status === 'failed-algo' ? <Navigate to="../failed" replace /> : null}
      {status === 'configuration' ? (
        <Navigate to="../configuration" replace />
      ) : null}
      {status === 'verification' ? (
        <Navigate to="../verification" replace />
      ) : null}
      <svg
        className="animate-spin h-16 w-16 text-blue-600"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-50"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-100"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      <p className="text-4xl text-center my-12">
        {t('manager.runningAlg.text')}
      </p>
      <button
        type="button"
        className="block w-64 rounded-md bg-blue-500 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        onClick={interruptAlgMutation.mutate}
        disabled={interruptAlgMutation.isLoading}
      >
        {interruptAlgMutation.isLoading
          ? t('manager.runningAlg.interrupting')
          : t('manager.runningAlg.interrupt')}
      </button>
      <Logo className="h-64 block" />
    </div>
  );
}
