import { CheckIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { classNames } from '@youshift/shared/utils';

const stepIdMapping = {
  configuration: '01',
  'failed-algo': '02',
  'running-algo': '02',
  verification: '03',
  published: '04',
  archived: '05',
};

export default function Steps({ currentStep, failed, setOpen }) {
  const failedAlgo = currentStep === 'failed-algo' || failed;
  const currentStepId = stepIdMapping[currentStep];

  const { t } = useTranslation();

  const steps = [
    { id: '01', name: t('generic.config') },
    { id: '02', name: t('generic.calc') },
    { id: '03', name: t('generic.verif') },
    { id: '04', name: t('generic.publ') },
  ];

  return (
    <nav aria-label="Progress">
      <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => {
          const isCurrent = step.id === currentStepId;
          const isCompleted = stepIdx < steps.findIndex(s => s.id === currentStepId);

          return (
            <li key={step.id} className="relative md:flex md:flex-1">
              {isCompleted || currentStepId === '04' || currentStepId === '05' ? (
                <div className="group flex w-full items-center">
                  <span className="flex items-center px-3 py-2 text-sm font-medium">
                    <span className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-blue-600 group-hover:bg-blue-800">
                      <CheckIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">
                      {step.name}
                    </span>
                    {step.id === '01' ? (
                      <button
                        className="md:ml-4 sm:ml-2"
                        onClick={() => setOpen(true)}
                      >
                        <span className="sr-only">Preview configuration</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 text-gray-500 hover:text-gray-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                      </button>
                    ) : null}
                  </span>
                </div>
              ) : isCurrent ? (
                <div
                  className="flex items-center px-3 py-2 text-sm font-medium"
                  aria-current="step"
                >
                  <span
                    className={classNames(
                      failedAlgo ? 'border-red-600' : 'border-blue-600',
                      'flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2',
                    )}
                  >
                    <span
                      className={failedAlgo ? 'text-red-600' : 'text-blue-600'}
                    >
                      {step.id}
                    </span>
                  </span>
                  <span
                    className={classNames(
                      failedAlgo ? 'text-red-600' : 'text-blue-600',
                      'ml-4 text-sm font-medium',
                    )}
                  >
                    {step.name}
                  </span>
                </div>
              ) : (
                <div className="group flex items-center">
                  <span className="flex items-center px-3 py-2 text-sm font-medium">
                    <span className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                      <span className="text-gray-500 group-hover:text-gray-900">
                        {step.id}
                      </span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {step.name}
                    </span>
                  </span>
                </div>
              )}
              {stepIdx !== steps.length - 1 && (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div
                    className="absolute right-0 top-0 hidden h-full w-5 md:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
