export const sampleOutput = {
  487: {
    acronym: 'OTRSR',
    base_id_section: null,
    cycle_num_days: 7,
    description: 'Servicio Genérico - Sin requerimientos',
    name: 'OtrServ',
    section_slots: {
      '9358_0': {
        end: 'Tue, 02 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 01 Jan 2024 00:00:00 GMT',
      },
      '9359_0': {
        end: 'Tue, 02 Jan 2024 10:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 02 Jan 2024 00:00:00 GMT',
      },
      '9359_1': {
        end: 'Tue, 02 Jan 2024 20:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 02 Jan 2024 12:00:00 GMT',
      },
      '9360_0': {
        end: 'Thu, 04 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 03 Jan 2024 00:00:00 GMT',
      },
      '9361_0': {
        end: 'Thu, 04 Jan 2024 09:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 04 Jan 2024 00:00:00 GMT',
      },
      '9361_1': {
        end: 'Thu, 04 Jan 2024 15:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 04 Jan 2024 08:00:00 GMT',
      },
      '9361_2': {
        end: 'Thu, 04 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 04 Jan 2024 12:00:00 GMT',
      },
      '9362_0': {
        end: 'Fri, 05 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 05 Jan 2024 00:00:00 GMT',
      },
      '9362_1': {
        end: 'Fri, 05 Jan 2024 20:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 05 Jan 2024 12:00:00 GMT',
      },
      '9363_0': {
        end: 'Sat, 06 Jan 2024 09:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 00:00:00 GMT',
      },
      '9363_1': {
        end: 'Sat, 06 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 06:00:00 GMT',
      },
      '9363_2': {
        end: 'Sat, 06 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 10:00:00 GMT',
      },
      '9363_3': {
        end: 'Sun, 07 Jan 2024 09:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 22:00:00 GMT',
      },
      '9364_0': {
        end: 'Mon, 08 Jan 2024 17:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 07 Jan 2024 00:00:00 GMT',
      },
      '9365_0': {
        end: 'Mon, 08 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 00:00:00 GMT',
      },
      '9365_1': {
        end: 'Mon, 08 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 12:00:00 GMT',
      },
      '9365_2': {
        end: 'Tue, 09 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 20:00:00 GMT',
      },
      '9366_0': {
        end: 'Tue, 09 Jan 2024 10:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 00:00:00 GMT',
      },
      '9366_1': {
        end: 'Tue, 09 Jan 2024 15:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 08:00:00 GMT',
      },
      '9366_2': {
        end: 'Wed, 10 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 12:00:00 GMT',
      },
      '9367_0': {
        end: 'Wed, 10 Jan 2024 15:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 10 Jan 2024 00:00:00 GMT',
      },
      '9367_1': {
        end: 'Wed, 10 Jan 2024 15:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 10 Jan 2024 08:00:00 GMT',
      },
      '9367_2': {
        end: 'Thu, 11 Jan 2024 22:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 10 Jan 2024 16:00:00 GMT',
      },
      '9368_0': {
        end: 'Thu, 11 Jan 2024 15:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 11 Jan 2024 00:00:00 GMT',
      },
      '9368_1': {
        end: 'Fri, 12 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 11 Jan 2024 12:00:00 GMT',
      },
      '9369_0': {
        end: 'Fri, 12 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 12 Jan 2024 00:00:00 GMT',
      },
      '9369_1': {
        end: 'Fri, 12 Jan 2024 17:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 12 Jan 2024 06:00:00 GMT',
      },
      '9369_2': {
        end: 'Fri, 12 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 12 Jan 2024 08:00:00 GMT',
      },
      '9369_3': {
        end: 'Sat, 13 Jan 2024 05:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 12 Jan 2024 18:00:00 GMT',
      },
      '9370_0': {
        end: 'Sat, 13 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 13 Jan 2024 00:00:00 GMT',
      },
      '9370_1': {
        end: 'Sun, 14 Jan 2024 09:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 13 Jan 2024 20:00:00 GMT',
      },
      '9371_0': {
        end: 'Sun, 14 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 00:00:00 GMT',
      },
      '9371_1': {
        end: 'Sun, 14 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 08:00:00 GMT',
      },
      '9371_2': {
        end: 'Mon, 15 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 16:00:00 GMT',
      },
      '9372_0': {
        end: 'Mon, 15 Jan 2024 15:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 15 Jan 2024 09:00:00 GMT',
      },
      '9372_1': {
        end: 'Mon, 15 Jan 2024 15:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 15 Jan 2024 09:00:00 GMT',
      },
      '9372_2': {
        end: 'Mon, 15 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 15 Jan 2024 12:00:00 GMT',
      },
      '9372_3': {
        end: 'Tue, 16 Jan 2024 10:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 15 Jan 2024 18:00:00 GMT',
      },
      '9373_0': {
        end: 'Wed, 17 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 16 Jan 2024 00:00:00 GMT',
      },
      '9374_0': {
        end: 'Wed, 17 Jan 2024 09:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 17 Jan 2024 00:00:00 GMT',
      },
      '9374_1': {
        end: 'Thu, 18 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 17 Jan 2024 12:00:00 GMT',
      },
      '9375_0': {
        end: 'Fri, 19 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 18 Jan 2024 00:00:00 GMT',
      },
      '9376_0': {
        end: 'Fri, 19 Jan 2024 17:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 19 Jan 2024 00:00:00 GMT',
      },
      '9376_1': {
        end: 'Sat, 20 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 19 Jan 2024 12:00:00 GMT',
      },
      '9377_0': {
        end: 'Sat, 20 Jan 2024 09:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 20 Jan 2024 00:00:00 GMT',
      },
      '9377_1': {
        end: 'Sat, 20 Jan 2024 22:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 20 Jan 2024 15:00:00 GMT',
      },
      '9377_2': {
        end: 'Sun, 21 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 20 Jan 2024 16:00:00 GMT',
      },
      '9378_0': {
        end: 'Sun, 21 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 21 Jan 2024 00:00:00 GMT',
      },
      '9378_1': {
        end: 'Mon, 22 Jan 2024 09:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 21 Jan 2024 17:00:00 GMT',
      },
      '9379_0': {
        end: 'Mon, 22 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 22 Jan 2024 00:00:00 GMT',
      },
      '9379_1': {
        end: 'Tue, 23 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 22 Jan 2024 12:00:00 GMT',
      },
      '9380_0': {
        end: 'Wed, 24 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 23 Jan 2024 00:00:00 GMT',
      },
      '9381_0': {
        end: 'Wed, 24 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 24 Jan 2024 00:00:00 GMT',
      },
      '9381_1': {
        end: 'Wed, 24 Jan 2024 15:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 24 Jan 2024 06:00:00 GMT',
      },
      '9381_2': {
        end: 'Wed, 24 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 24 Jan 2024 12:00:00 GMT',
      },
      '9381_3': {
        end: 'Thu, 25 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 24 Jan 2024 18:00:00 GMT',
      },
      '9382_0': {
        end: 'Thu, 25 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 25 Jan 2024 00:00:00 GMT',
      },
      '9382_1': {
        end: 'Fri, 26 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 25 Jan 2024 12:00:00 GMT',
      },
      '9383_0': {
        end: 'Fri, 26 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 26 Jan 2024 00:00:00 GMT',
      },
      '9383_1': {
        end: 'Fri, 26 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 26 Jan 2024 06:00:00 GMT',
      },
      '9383_2': {
        end: 'Fri, 26 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 26 Jan 2024 12:00:00 GMT',
      },
      '9383_3': {
        end: 'Sat, 27 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 26 Jan 2024 18:00:00 GMT',
      },
      '9384_0': {
        end: 'Sun, 28 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 27 Jan 2024 00:00:00 GMT',
      },
      '9385_0': {
        end: 'Mon, 29 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 28 Jan 2024 00:00:00 GMT',
      },
      '9386_0': {
        end: 'Mon, 29 Jan 2024 15:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 29 Jan 2024 00:00:00 GMT',
      },
      '9386_1': {
        end: 'Tue, 30 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 29 Jan 2024 12:00:00 GMT',
      },
      '9387_0': {
        end: 'Tue, 30 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 00:00:00 GMT',
      },
      '9387_1': {
        end: 'Tue, 30 Jan 2024 17:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 08:00:00 GMT',
      },
      '9387_2': {
        end: 'Wed, 31 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 16:00:00 GMT',
      },
      '9388_0': {
        end: 'Wed, 31 Jan 2024 15:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 31 Jan 2024 00:00:00 GMT',
      },
      '9388_1': {
        end: 'Thu, 01 Feb 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 31 Jan 2024 12:00:00 GMT',
      },
    },
  },
  488: {
    acronym: 'URGEN',
    base_id_section: null,
    cycle_num_days: 7,
    description: 'Urgencias',
    name: 'Urgencias',
    section_slots: {
      '9358_0': {
        end: 'Mon, 01 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 01 Jan 2024 00:00:00 GMT',
      },
      '9358_1': {
        end: 'Mon, 01 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 01 Jan 2024 06:00:00 GMT',
      },
      '9358_2': {
        end: 'Mon, 01 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 01 Jan 2024 12:00:00 GMT',
      },
      '9358_3': {
        end: 'Tue, 02 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 01 Jan 2024 18:00:00 GMT',
      },
      '9359_0': {
        end: 'Tue, 02 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 02 Jan 2024 00:00:00 GMT',
      },
      '9359_1': {
        end: 'Wed, 03 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 02 Jan 2024 12:00:00 GMT',
      },
      '9360_0': {
        end: 'Thu, 04 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 03 Jan 2024 00:00:00 GMT',
      },
      '9361_0': {
        end: 'Fri, 05 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 04 Jan 2024 00:00:00 GMT',
      },
      '9362_0': {
        end: 'Sat, 06 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 05 Jan 2024 00:00:00 GMT',
      },
      '9363_0': {
        end: 'Sat, 06 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 00:00:00 GMT',
      },
      '9363_1': {
        end: 'Sat, 06 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 08:00:00 GMT',
      },
      '9363_2': {
        end: 'Sun, 07 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 16:00:00 GMT',
      },
      '9364_0': {
        end: 'Sun, 07 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 07 Jan 2024 00:00:00 GMT',
      },
      '9364_1': {
        end: 'Sun, 07 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 07 Jan 2024 06:00:00 GMT',
      },
      '9364_2': {
        end: 'Sun, 07 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 07 Jan 2024 12:00:00 GMT',
      },
      '9364_3': {
        end: 'Mon, 08 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 07 Jan 2024 18:00:00 GMT',
      },
      '9365_0': {
        end: 'Mon, 08 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 00:00:00 GMT',
      },
      '9365_1': {
        end: 'Mon, 08 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 06:00:00 GMT',
      },
      '9365_2': {
        end: 'Mon, 08 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 12:00:00 GMT',
      },
      '9365_3': {
        end: 'Tue, 09 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 18:00:00 GMT',
      },
      '9366_0': {
        end: 'Tue, 09 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 00:00:00 GMT',
      },
      '9366_1': {
        end: 'Tue, 09 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 06:00:00 GMT',
      },
      '9366_2': {
        end: 'Tue, 09 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 12:00:00 GMT',
      },
      '9366_3': {
        end: 'Wed, 10 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 18:00:00 GMT',
      },
      '9367_0': {
        end: 'Wed, 10 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 10 Jan 2024 00:00:00 GMT',
      },
      '9367_1': {
        end: 'Thu, 11 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 10 Jan 2024 12:00:00 GMT',
      },
      '9368_0': {
        end: 'Thu, 11 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 11 Jan 2024 00:00:00 GMT',
      },
      '9368_1': {
        end: 'Fri, 12 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 11 Jan 2024 12:00:00 GMT',
      },
      '9369_0': {
        end: 'Fri, 12 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 12 Jan 2024 00:00:00 GMT',
      },
      '9369_1': {
        end: 'Sat, 13 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 12 Jan 2024 12:00:00 GMT',
      },
      '9370_0': {
        end: 'Sun, 14 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 13 Jan 2024 00:00:00 GMT',
      },
      '9371_0': {
        end: 'Sun, 14 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 00:00:00 GMT',
      },
      '9371_1': {
        end: 'Sun, 14 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 08:00:00 GMT',
      },
      '9371_2': {
        end: 'Mon, 15 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 16:00:00 GMT',
      },
      '9372_0': {
        end: 'Mon, 15 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 15 Jan 2024 00:00:00 GMT',
      },
      '9372_1': {
        end: 'Mon, 15 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 15 Jan 2024 06:00:00 GMT',
      },
      '9372_2': {
        end: 'Mon, 15 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 15 Jan 2024 12:00:00 GMT',
      },
      '9372_3': {
        end: 'Tue, 16 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 15 Jan 2024 18:00:00 GMT',
      },
      '9373_0': {
        end: 'Wed, 17 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 16 Jan 2024 00:00:00 GMT',
      },
      '9374_0': {
        end: 'Wed, 17 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 17 Jan 2024 00:00:00 GMT',
      },
      '9374_1': {
        end: 'Thu, 18 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 17 Jan 2024 12:00:00 GMT',
      },
      '9375_0': {
        end: 'Thu, 18 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 18 Jan 2024 00:00:00 GMT',
      },
      '9375_1': {
        end: 'Fri, 19 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 18 Jan 2024 12:00:00 GMT',
      },
      '9376_0': {
        end: 'Sat, 20 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 19 Jan 2024 00:00:00 GMT',
      },
      '9377_0': {
        end: 'Sat, 20 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 20 Jan 2024 00:00:00 GMT',
      },
      '9377_1': {
        end: 'Sat, 20 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 20 Jan 2024 08:00:00 GMT',
      },
      '9377_2': {
        end: 'Sun, 21 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 20 Jan 2024 16:00:00 GMT',
      },
      '9378_0': {
        end: 'Sun, 21 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 21 Jan 2024 00:00:00 GMT',
      },
      '9378_1': {
        end: 'Sun, 21 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 21 Jan 2024 08:00:00 GMT',
      },
      '9378_2': {
        end: 'Mon, 22 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 21 Jan 2024 16:00:00 GMT',
      },
      '9379_0': {
        end: 'Tue, 23 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 22 Jan 2024 00:00:00 GMT',
      },
      '9380_0': {
        end: 'Tue, 23 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 23 Jan 2024 00:00:00 GMT',
      },
      '9380_1': {
        end: 'Tue, 23 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 23 Jan 2024 08:00:00 GMT',
      },
      '9380_2': {
        end: 'Wed, 24 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 23 Jan 2024 16:00:00 GMT',
      },
      '9381_0': {
        end: 'Wed, 24 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 24 Jan 2024 00:00:00 GMT',
      },
      '9381_1': {
        end: 'Thu, 25 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 24 Jan 2024 12:00:00 GMT',
      },
      '9382_0': {
        end: 'Thu, 25 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 25 Jan 2024 00:00:00 GMT',
      },
      '9382_1': {
        end: 'Fri, 26 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 25 Jan 2024 12:00:00 GMT',
      },
      '9383_0': {
        end: 'Fri, 26 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 26 Jan 2024 00:00:00 GMT',
      },
      '9383_1': {
        end: 'Sat, 27 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 26 Jan 2024 12:00:00 GMT',
      },
      '9384_0': {
        end: 'Sat, 27 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 27 Jan 2024 00:00:00 GMT',
      },
      '9384_1': {
        end: 'Sat, 27 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 27 Jan 2024 06:00:00 GMT',
      },
      '9384_2': {
        end: 'Sat, 27 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 27 Jan 2024 12:00:00 GMT',
      },
      '9384_3': {
        end: 'Sun, 28 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 27 Jan 2024 18:00:00 GMT',
      },
      '9385_0': {
        end: 'Sun, 28 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 28 Jan 2024 00:00:00 GMT',
      },
      '9385_1': {
        end: 'Sun, 28 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 28 Jan 2024 08:00:00 GMT',
      },
      '9385_2': {
        end: 'Mon, 29 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 28 Jan 2024 16:00:00 GMT',
      },
      '9386_0': {
        end: 'Mon, 29 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 29 Jan 2024 00:00:00 GMT',
      },
      '9386_1': {
        end: 'Tue, 30 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 29 Jan 2024 12:00:00 GMT',
      },
      '9387_0': {
        end: 'Tue, 30 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 00:00:00 GMT',
      },
      '9387_1': {
        end: 'Tue, 30 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 08:00:00 GMT',
      },
      '9387_2': {
        end: 'Wed, 31 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 16:00:00 GMT',
      },
      '9388_0': {
        end: 'Wed, 31 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 31 Jan 2024 00:00:00 GMT',
      },
      '9388_1': {
        end: 'Wed, 31 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 31 Jan 2024 06:00:00 GMT',
      },
      '9388_2': {
        end: 'Wed, 31 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 31 Jan 2024 12:00:00 GMT',
      },
      '9388_3': {
        end: 'Thu, 01 Feb 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 31 Jan 2024 18:00:00 GMT',
      },
    },
  },
  489: {
    acronym: 'PLANT',
    base_id_section: null,
    cycle_num_days: 7,
    description: 'Planta',
    name: 'Planta Medicina Interna',
    section_slots: {
      '9358_0': {
        end: 'Tue, 02 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 01 Jan 2024 00:00:00 GMT',
      },
      '9359_0': {
        end: 'Tue, 02 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 02 Jan 2024 00:00:00 GMT',
      },
      '9359_1': {
        end: 'Tue, 02 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 02 Jan 2024 06:00:00 GMT',
      },
      '9359_2': {
        end: 'Tue, 02 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 02 Jan 2024 12:00:00 GMT',
      },
      '9359_3': {
        end: 'Wed, 03 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 02 Jan 2024 18:00:00 GMT',
      },
      '9360_0': {
        end: 'Thu, 04 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 03 Jan 2024 00:00:00 GMT',
      },
      '9361_0': {
        end: 'Thu, 04 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 04 Jan 2024 00:00:00 GMT',
      },
      '9361_1': {
        end: 'Thu, 04 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 04 Jan 2024 08:00:00 GMT',
      },
      '9361_2': {
        end: 'Fri, 05 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 04 Jan 2024 16:00:00 GMT',
      },
      '9362_0': {
        end: 'Fri, 05 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 05 Jan 2024 00:00:00 GMT',
      },
      '9362_1': {
        end: 'Fri, 05 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 05 Jan 2024 08:00:00 GMT',
      },
      '9362_2': {
        end: 'Sat, 06 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 05 Jan 2024 16:00:00 GMT',
      },
      '9363_0': {
        end: 'Sat, 06 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 00:00:00 GMT',
      },
      '9363_1': {
        end: 'Sat, 06 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 06:00:00 GMT',
      },
      '9363_2': {
        end: 'Sat, 06 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 12:00:00 GMT',
      },
      '9363_3': {
        end: 'Sun, 07 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 18:00:00 GMT',
      },
      '9364_0': {
        end: 'Sun, 07 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 07 Jan 2024 00:00:00 GMT',
      },
      '9364_1': {
        end: 'Mon, 08 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 07 Jan 2024 12:00:00 GMT',
      },
      '9365_0': {
        end: 'Mon, 08 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 00:00:00 GMT',
      },
      '9365_1': {
        end: 'Mon, 08 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 06:00:00 GMT',
      },
      '9365_2': {
        end: 'Mon, 08 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 12:00:00 GMT',
      },
      '9365_3': {
        end: 'Tue, 09 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 18:00:00 GMT',
      },
      '9366_0': {
        end: 'Tue, 09 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 00:00:00 GMT',
      },
      '9366_1': {
        end: 'Tue, 09 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 08:00:00 GMT',
      },
      '9366_2': {
        end: 'Wed, 10 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 16:00:00 GMT',
      },
      '9367_0': {
        end: 'Wed, 10 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 10 Jan 2024 00:00:00 GMT',
      },
      '9367_1': {
        end: 'Wed, 10 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 10 Jan 2024 06:00:00 GMT',
      },
      '9367_2': {
        end: 'Wed, 10 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 10 Jan 2024 12:00:00 GMT',
      },
      '9367_3': {
        end: 'Thu, 11 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 10 Jan 2024 18:00:00 GMT',
      },
      '9368_0': {
        end: 'Thu, 11 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 11 Jan 2024 00:00:00 GMT',
      },
      '9368_1': {
        end: 'Thu, 11 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 11 Jan 2024 08:00:00 GMT',
      },
      '9368_2': {
        end: 'Fri, 12 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 11 Jan 2024 16:00:00 GMT',
      },
      '9369_0': {
        end: 'Fri, 12 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 12 Jan 2024 00:00:00 GMT',
      },
      '9369_1': {
        end: 'Sat, 13 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 12 Jan 2024 12:00:00 GMT',
      },
      '9370_0': {
        end: 'Sat, 13 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 13 Jan 2024 00:00:00 GMT',
      },
      '9370_1': {
        end: 'Sun, 14 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 13 Jan 2024 12:00:00 GMT',
      },
      '9371_0': {
        end: 'Sun, 14 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 00:00:00 GMT',
      },
      '9371_1': {
        end: 'Sun, 14 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 06:00:00 GMT',
      },
      '9371_2': {
        end: 'Sun, 14 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 12:00:00 GMT',
      },
      '9371_3': {
        end: 'Mon, 15 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 18:00:00 GMT',
      },
      '9372_0': {
        end: 'Tue, 16 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 15 Jan 2024 00:00:00 GMT',
      },
      '9373_0': {
        end: 'Wed, 17 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 16 Jan 2024 00:00:00 GMT',
      },
      '9374_0': {
        end: 'Wed, 17 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 17 Jan 2024 00:00:00 GMT',
      },
      '9374_1': {
        end: 'Thu, 18 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 17 Jan 2024 12:00:00 GMT',
      },
      '9375_0': {
        end: 'Thu, 18 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 18 Jan 2024 00:00:00 GMT',
      },
      '9375_1': {
        end: 'Fri, 19 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 18 Jan 2024 12:00:00 GMT',
      },
      '9376_0': {
        end: 'Fri, 19 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 19 Jan 2024 00:00:00 GMT',
      },
      '9376_1': {
        end: 'Fri, 19 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 19 Jan 2024 08:00:00 GMT',
      },
      '9376_2': {
        end: 'Sat, 20 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 19 Jan 2024 16:00:00 GMT',
      },
      '9377_0': {
        end: 'Sun, 21 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 20 Jan 2024 00:00:00 GMT',
      },
      '9378_0': {
        end: 'Sun, 21 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 21 Jan 2024 00:00:00 GMT',
      },
      '9378_1': {
        end: 'Sun, 21 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 21 Jan 2024 08:00:00 GMT',
      },
      '9378_2': {
        end: 'Mon, 22 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 21 Jan 2024 16:00:00 GMT',
      },
      '9379_0': {
        end: 'Mon, 22 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 22 Jan 2024 00:00:00 GMT',
      },
      '9379_1': {
        end: 'Mon, 22 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 22 Jan 2024 08:00:00 GMT',
      },
      '9379_2': {
        end: 'Tue, 23 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 22 Jan 2024 16:00:00 GMT',
      },
      '9380_0': {
        end: 'Tue, 23 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 23 Jan 2024 00:00:00 GMT',
      },
      '9380_1': {
        end: 'Tue, 23 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 23 Jan 2024 08:00:00 GMT',
      },
      '9380_2': {
        end: 'Wed, 24 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 23 Jan 2024 16:00:00 GMT',
      },
      '9381_0': {
        end: 'Wed, 24 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 24 Jan 2024 00:00:00 GMT',
      },
      '9381_1': {
        end: 'Thu, 25 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 24 Jan 2024 12:00:00 GMT',
      },
      '9382_0': {
        end: 'Fri, 26 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 25 Jan 2024 00:00:00 GMT',
      },
      '9383_0': {
        end: 'Sat, 27 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 26 Jan 2024 00:00:00 GMT',
      },
      '9384_0': {
        end: 'Sat, 27 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 27 Jan 2024 00:00:00 GMT',
      },
      '9384_1': {
        end: 'Sat, 27 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 27 Jan 2024 06:00:00 GMT',
      },
      '9384_2': {
        end: 'Sat, 27 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 27 Jan 2024 12:00:00 GMT',
      },
      '9384_3': {
        end: 'Sun, 28 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 27 Jan 2024 18:00:00 GMT',
      },
      '9385_0': {
        end: 'Mon, 29 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 28 Jan 2024 00:00:00 GMT',
      },
      '9386_0': {
        end: 'Mon, 29 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 29 Jan 2024 00:00:00 GMT',
      },
      '9386_1': {
        end: 'Tue, 30 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 29 Jan 2024 12:00:00 GMT',
      },
      '9387_0': {
        end: 'Tue, 30 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 00:00:00 GMT',
      },
      '9387_1': {
        end: 'Tue, 30 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 08:00:00 GMT',
      },
      '9387_2': {
        end: 'Wed, 31 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 16:00:00 GMT',
      },
      '9388_0': {
        end: 'Wed, 31 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 31 Jan 2024 00:00:00 GMT',
      },
      '9388_1': {
        end: 'Wed, 31 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 31 Jan 2024 08:00:00 GMT',
      },
      '9388_2': {
        end: 'Thu, 01 Feb 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 31 Jan 2024 16:00:00 GMT',
      },
    },
  },
  490: {
    acronym: 'NEURO',
    base_id_section: null,
    cycle_num_days: 7,
    description: 'Planta de Neuro',
    name: 'Neuro',
    section_slots: {
      '9358_0': {
        end: 'Tue, 02 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 01 Jan 2024 00:00:00 GMT',
      },
      '9359_0': {
        end: 'Tue, 02 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 02 Jan 2024 00:00:00 GMT',
      },
      '9359_1': {
        end: 'Tue, 02 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 02 Jan 2024 08:00:00 GMT',
      },
      '9359_2': {
        end: 'Wed, 03 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 02 Jan 2024 16:00:00 GMT',
      },
      '9360_0': {
        end: 'Wed, 03 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 03 Jan 2024 00:00:00 GMT',
      },
      '9360_1': {
        end: 'Wed, 03 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 03 Jan 2024 06:00:00 GMT',
      },
      '9360_2': {
        end: 'Wed, 03 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 03 Jan 2024 12:00:00 GMT',
      },
      '9360_3': {
        end: 'Thu, 04 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 03 Jan 2024 18:00:00 GMT',
      },
      '9361_0': {
        end: 'Thu, 04 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 04 Jan 2024 00:00:00 GMT',
      },
      '9361_1': {
        end: 'Thu, 04 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 04 Jan 2024 06:00:00 GMT',
      },
      '9361_2': {
        end: 'Thu, 04 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 04 Jan 2024 12:00:00 GMT',
      },
      '9361_3': {
        end: 'Fri, 05 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 04 Jan 2024 18:00:00 GMT',
      },
      '9362_0': {
        end: 'Sat, 06 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 05 Jan 2024 00:00:00 GMT',
      },
      '9363_0': {
        end: 'Sun, 07 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 00:00:00 GMT',
      },
      '9364_0': {
        end: 'Sun, 07 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 07 Jan 2024 00:00:00 GMT',
      },
      '9364_1': {
        end: 'Sun, 07 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 07 Jan 2024 08:00:00 GMT',
      },
      '9364_2': {
        end: 'Mon, 08 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 07 Jan 2024 16:00:00 GMT',
      },
      '9365_0': {
        end: 'Mon, 08 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 00:00:00 GMT',
      },
      '9365_1': {
        end: 'Mon, 08 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 08:00:00 GMT',
      },
      '9365_2': {
        end: 'Tue, 09 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 16:00:00 GMT',
      },
      '9366_0': {
        end: 'Tue, 09 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 00:00:00 GMT',
      },
      '9366_1': {
        end: 'Tue, 09 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 08:00:00 GMT',
      },
      '9366_2': {
        end: 'Wed, 10 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 16:00:00 GMT',
      },
      '9367_0': {
        end: 'Thu, 11 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 10 Jan 2024 00:00:00 GMT',
      },
      '9368_0': {
        end: 'Thu, 11 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 11 Jan 2024 00:00:00 GMT',
      },
      '9368_1': {
        end: 'Thu, 11 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 11 Jan 2024 08:00:00 GMT',
      },
      '9368_2': {
        end: 'Fri, 12 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 11 Jan 2024 16:00:00 GMT',
      },
      '9369_0': {
        end: 'Sat, 13 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 12 Jan 2024 00:00:00 GMT',
      },
      '9370_0': {
        end: 'Sat, 13 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 13 Jan 2024 00:00:00 GMT',
      },
      '9370_1': {
        end: 'Sat, 13 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 13 Jan 2024 06:00:00 GMT',
      },
      '9370_2': {
        end: 'Sat, 13 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 13 Jan 2024 12:00:00 GMT',
      },
      '9370_3': {
        end: 'Sun, 14 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 13 Jan 2024 18:00:00 GMT',
      },
      '9371_0': {
        end: 'Sun, 14 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 00:00:00 GMT',
      },
      '9371_1': {
        end: 'Sun, 14 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 06:00:00 GMT',
      },
      '9371_2': {
        end: 'Sun, 14 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 12:00:00 GMT',
      },
      '9371_3': {
        end: 'Mon, 15 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 18:00:00 GMT',
      },
      '9372_0': {
        end: 'Mon, 15 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 15 Jan 2024 00:00:00 GMT',
      },
      '9372_1': {
        end: 'Mon, 15 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 15 Jan 2024 08:00:00 GMT',
      },
      '9372_2': {
        end: 'Tue, 16 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 15 Jan 2024 16:00:00 GMT',
      },
      '9373_0': {
        end: 'Tue, 16 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 16 Jan 2024 00:00:00 GMT',
      },
      '9373_1': {
        end: 'Wed, 17 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 16 Jan 2024 12:00:00 GMT',
      },
      '9374_0': {
        end: 'Thu, 18 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 17 Jan 2024 00:00:00 GMT',
      },
      '9375_0': {
        end: 'Thu, 18 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 18 Jan 2024 00:00:00 GMT',
      },
      '9375_1': {
        end: 'Fri, 19 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 18 Jan 2024 12:00:00 GMT',
      },
      '9376_0': {
        end: 'Fri, 19 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 19 Jan 2024 00:00:00 GMT',
      },
      '9376_1': {
        end: 'Sat, 20 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 19 Jan 2024 12:00:00 GMT',
      },
      '9377_0': {
        end: 'Sun, 21 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 20 Jan 2024 00:00:00 GMT',
      },
      '9378_0': {
        end: 'Mon, 22 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 21 Jan 2024 00:00:00 GMT',
      },
      '9379_0': {
        end: 'Mon, 22 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 22 Jan 2024 00:00:00 GMT',
      },
      '9379_1': {
        end: 'Mon, 22 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 22 Jan 2024 06:00:00 GMT',
      },
      '9379_2': {
        end: 'Mon, 22 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 22 Jan 2024 12:00:00 GMT',
      },
      '9379_3': {
        end: 'Tue, 23 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 22 Jan 2024 18:00:00 GMT',
      },
      '9380_0': {
        end: 'Wed, 24 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 23 Jan 2024 00:00:00 GMT',
      },
      '9381_0': {
        end: 'Wed, 24 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 24 Jan 2024 00:00:00 GMT',
      },
      '9381_1': {
        end: 'Thu, 25 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 24 Jan 2024 12:00:00 GMT',
      },
      '9382_0': {
        end: 'Fri, 26 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 25 Jan 2024 00:00:00 GMT',
      },
      '9383_0': {
        end: 'Fri, 26 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 26 Jan 2024 00:00:00 GMT',
      },
      '9383_1': {
        end: 'Sat, 27 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 26 Jan 2024 12:00:00 GMT',
      },
      '9384_0': {
        end: 'Sat, 27 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 27 Jan 2024 00:00:00 GMT',
      },
      '9384_1': {
        end: 'Sat, 27 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 27 Jan 2024 08:00:00 GMT',
      },
      '9384_2': {
        end: 'Sun, 28 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 27 Jan 2024 16:00:00 GMT',
      },
      '9385_0': {
        end: 'Sun, 28 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 28 Jan 2024 00:00:00 GMT',
      },
      '9385_1': {
        end: 'Mon, 29 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 28 Jan 2024 12:00:00 GMT',
      },
      '9386_0': {
        end: 'Mon, 29 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 29 Jan 2024 00:00:00 GMT',
      },
      '9386_1': {
        end: 'Mon, 29 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 29 Jan 2024 08:00:00 GMT',
      },
      '9386_2': {
        end: 'Tue, 30 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 29 Jan 2024 16:00:00 GMT',
      },
      '9387_0': {
        end: 'Tue, 30 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 00:00:00 GMT',
      },
      '9387_1': {
        end: 'Wed, 31 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 12:00:00 GMT',
      },
      '9388_0': {
        end: 'Wed, 31 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 31 Jan 2024 00:00:00 GMT',
      },
      '9388_1': {
        end: 'Wed, 31 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 31 Jan 2024 08:00:00 GMT',
      },
      '9388_2': {
        end: 'Thu, 01 Feb 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 31 Jan 2024 16:00:00 GMT',
      },
    },
  },
  491: {
    acronym: 'MANAC',
    base_id_section: null,
    cycle_num_days: 7,
    description: 'Manacor',
    name: 'Manacor',
    section_slots: {
      '9358_0': {
        end: 'Mon, 01 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 01 Jan 2024 00:00:00 GMT',
      },
      '9358_1': {
        end: 'Mon, 01 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 01 Jan 2024 06:00:00 GMT',
      },
      '9358_2': {
        end: 'Mon, 01 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 01 Jan 2024 12:00:00 GMT',
      },
      '9358_3': {
        end: 'Tue, 02 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 01 Jan 2024 18:00:00 GMT',
      },
      '9359_0': {
        end: 'Wed, 03 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 02 Jan 2024 00:00:00 GMT',
      },
      '9360_0': {
        end: 'Wed, 03 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 03 Jan 2024 00:00:00 GMT',
      },
      '9360_1': {
        end: 'Wed, 03 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 03 Jan 2024 08:00:00 GMT',
      },
      '9360_2': {
        end: 'Thu, 04 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 03 Jan 2024 16:00:00 GMT',
      },
      '9361_0': {
        end: 'Fri, 05 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 04 Jan 2024 00:00:00 GMT',
      },
      '9362_0': {
        end: 'Fri, 05 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 05 Jan 2024 00:00:00 GMT',
      },
      '9362_1': {
        end: 'Fri, 05 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 05 Jan 2024 06:00:00 GMT',
      },
      '9362_2': {
        end: 'Fri, 05 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 05 Jan 2024 12:00:00 GMT',
      },
      '9362_3': {
        end: 'Sat, 06 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 05 Jan 2024 18:00:00 GMT',
      },
      '9363_0': {
        end: 'Sat, 06 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 00:00:00 GMT',
      },
      '9363_1': {
        end: 'Sat, 06 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 06:00:00 GMT',
      },
      '9363_2': {
        end: 'Sat, 06 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 12:00:00 GMT',
      },
      '9363_3': {
        end: 'Sun, 07 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 06 Jan 2024 18:00:00 GMT',
      },
      '9364_0': {
        end: 'Mon, 08 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 07 Jan 2024 00:00:00 GMT',
      },
      '9365_0': {
        end: 'Mon, 08 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 00:00:00 GMT',
      },
      '9365_1': {
        end: 'Mon, 08 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 08:00:00 GMT',
      },
      '9365_2': {
        end: 'Tue, 09 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 08 Jan 2024 16:00:00 GMT',
      },
      '9366_0': {
        end: 'Tue, 09 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 00:00:00 GMT',
      },
      '9366_1': {
        end: 'Tue, 09 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 08:00:00 GMT',
      },
      '9366_2': {
        end: 'Wed, 10 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 09 Jan 2024 16:00:00 GMT',
      },
      '9367_0': {
        end: 'Wed, 10 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 10 Jan 2024 00:00:00 GMT',
      },
      '9367_1': {
        end: 'Thu, 11 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 10 Jan 2024 12:00:00 GMT',
      },
      '9368_0': {
        end: 'Thu, 11 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 11 Jan 2024 00:00:00 GMT',
      },
      '9368_1': {
        end: 'Thu, 11 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 11 Jan 2024 06:00:00 GMT',
      },
      '9368_2': {
        end: 'Thu, 11 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 11 Jan 2024 12:00:00 GMT',
      },
      '9368_3': {
        end: 'Fri, 12 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 11 Jan 2024 18:00:00 GMT',
      },
      '9369_0': {
        end: 'Fri, 12 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 12 Jan 2024 00:00:00 GMT',
      },
      '9369_1': {
        end: 'Fri, 12 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 12 Jan 2024 06:00:00 GMT',
      },
      '9369_2': {
        end: 'Fri, 12 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 12 Jan 2024 12:00:00 GMT',
      },
      '9369_3': {
        end: 'Sat, 13 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 12 Jan 2024 18:00:00 GMT',
      },
      '9370_0': {
        end: 'Sat, 13 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 13 Jan 2024 00:00:00 GMT',
      },
      '9370_1': {
        end: 'Sat, 13 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 13 Jan 2024 08:00:00 GMT',
      },
      '9370_2': {
        end: 'Sun, 14 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 13 Jan 2024 16:00:00 GMT',
      },
      '9371_0': {
        end: 'Sun, 14 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 00:00:00 GMT',
      },
      '9371_1': {
        end: 'Mon, 15 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 14 Jan 2024 12:00:00 GMT',
      },
      '9372_0': {
        end: 'Tue, 16 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 15 Jan 2024 00:00:00 GMT',
      },
      '9373_0': {
        end: 'Tue, 16 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 16 Jan 2024 00:00:00 GMT',
      },
      '9373_1': {
        end: 'Wed, 17 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 16 Jan 2024 12:00:00 GMT',
      },
      '9374_0': {
        end: 'Wed, 17 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 17 Jan 2024 00:00:00 GMT',
      },
      '9374_1': {
        end: 'Wed, 17 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 17 Jan 2024 08:00:00 GMT',
      },
      '9374_2': {
        end: 'Thu, 18 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 17 Jan 2024 16:00:00 GMT',
      },
      '9375_0': {
        end: 'Thu, 18 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 18 Jan 2024 00:00:00 GMT',
      },
      '9375_1': {
        end: 'Fri, 19 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 18 Jan 2024 12:00:00 GMT',
      },
      '9376_0': {
        end: 'Fri, 19 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 19 Jan 2024 00:00:00 GMT',
      },
      '9376_1': {
        end: 'Sat, 20 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 19 Jan 2024 12:00:00 GMT',
      },
      '9377_0': {
        end: 'Sat, 20 Jan 2024 08:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 20 Jan 2024 00:00:00 GMT',
      },
      '9377_1': {
        end: 'Sat, 20 Jan 2024 16:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 20 Jan 2024 08:00:00 GMT',
      },
      '9377_2': {
        end: 'Sun, 21 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 20 Jan 2024 16:00:00 GMT',
      },
      '9378_0': {
        end: 'Mon, 22 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 21 Jan 2024 00:00:00 GMT',
      },
      '9379_0': {
        end: 'Mon, 22 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 22 Jan 2024 00:00:00 GMT',
      },
      '9379_1': {
        end: 'Tue, 23 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 22 Jan 2024 12:00:00 GMT',
      },
      '9380_0': {
        end: 'Tue, 23 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 23 Jan 2024 00:00:00 GMT',
      },
      '9380_1': {
        end: 'Wed, 24 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 23 Jan 2024 12:00:00 GMT',
      },
      '9381_0': {
        end: 'Wed, 24 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 24 Jan 2024 00:00:00 GMT',
      },
      '9381_1': {
        end: 'Wed, 24 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 24 Jan 2024 06:00:00 GMT',
      },
      '9381_2': {
        end: 'Wed, 24 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 24 Jan 2024 12:00:00 GMT',
      },
      '9381_3': {
        end: 'Thu, 25 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 24 Jan 2024 18:00:00 GMT',
      },
      '9382_0': {
        end: 'Thu, 25 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 25 Jan 2024 00:00:00 GMT',
      },
      '9382_1': {
        end: 'Thu, 25 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 25 Jan 2024 06:00:00 GMT',
      },
      '9382_2': {
        end: 'Thu, 25 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 25 Jan 2024 12:00:00 GMT',
      },
      '9382_3': {
        end: 'Fri, 26 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 53,
        max_need: 3,
        min_need: 1,
        start: 'Thu, 25 Jan 2024 18:00:00 GMT',
      },
      '9383_0': {
        end: 'Sat, 27 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 57,
        max_need: 3,
        min_need: 1,
        start: 'Fri, 26 Jan 2024 00:00:00 GMT',
      },
      '9384_0': {
        end: 'Sun, 28 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 61,
        max_need: 3,
        min_need: 1,
        start: 'Sat, 27 Jan 2024 00:00:00 GMT',
      },
      '9385_0': {
        end: 'Sun, 28 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 28 Jan 2024 00:00:00 GMT',
      },
      '9385_1': {
        end: 'Mon, 29 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 65,
        max_need: 3,
        min_need: 1,
        start: 'Sun, 28 Jan 2024 12:00:00 GMT',
      },
      '9386_0': {
        end: 'Tue, 30 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 41,
        max_need: 3,
        min_need: 1,
        start: 'Mon, 29 Jan 2024 00:00:00 GMT',
      },
      '9387_0': {
        end: 'Tue, 30 Jan 2024 06:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 00:00:00 GMT',
      },
      '9387_1': {
        end: 'Tue, 30 Jan 2024 12:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 06:00:00 GMT',
      },
      '9387_2': {
        end: 'Tue, 30 Jan 2024 18:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 12:00:00 GMT',
      },
      '9387_3': {
        end: 'Wed, 31 Jan 2024 00:00:00 GMT',
        id_section_slot_type: 45,
        max_need: 3,
        min_need: 1,
        start: 'Tue, 30 Jan 2024 18:00:00 GMT',
      },
      '9388_0': {
        end: 'Thu, 01 Feb 2024 00:00:00 GMT',
        id_section_slot_type: 49,
        max_need: 3,
        min_need: 1,
        start: 'Wed, 31 Jan 2024 00:00:00 GMT',
      },
    },
  },
};
