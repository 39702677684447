"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEditUserPreferencesConfig = exports.useExcludeItrUsersMutation = exports.useIncludeItrUsersMutation = void 0;
var react_query_1 = require("@tanstack/react-query");
var api_1 = require("../../api");
var useIncludeItrUsersMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_users = params.id_users, id_itr = params.id_itr;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/users/include"),
                method: "post",
                data: { id_users: id_users },
            });
        } }, options), queryClient);
};
exports.useIncludeItrUsersMutation = useIncludeItrUsersMutation;
var useExcludeItrUsersMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_users = params.id_users, id_itr = params.id_itr;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/users/exclude"),
                method: "post",
                data: { id_users: id_users },
            });
        } }, options), queryClient);
};
exports.useExcludeItrUsersMutation = useExcludeItrUsersMutation;
var useEditUserPreferencesConfig = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, preference_configs = params.preference_configs;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/preferences/edit"),
                method: "patch",
                data: { preference_configs: preference_configs },
            });
        } }, options), queryClient);
};
exports.useEditUserPreferencesConfig = useEditUserPreferencesConfig;
