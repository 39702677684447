import { Listbox } from '@headlessui/react';
import {
  Color,
  ColorIndex,
  ColorName,
  colorPalette,
  returnColor,
} from '@youshift/shared/utils';
import { useTranslation } from 'react-i18next';

type ColorPickerProps = {
  value: ColorName;
  onChange: (color: ColorName) => void;
};

const colors: Color[] = Object.keys(colorPalette).map(color => ({
  name: color as ColorName,
  shade: 500 as ColorIndex,
}));

function ColorPicker({ value, onChange }: ColorPickerProps) {
  const { t } = useTranslation();

  return (
    <Listbox value={value} onChange={onChange}>
      <div className="relative">
        <Listbox.Button className="relative w-full cursor-pointer rounded-md p-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center">
          <span
            className="inline-block w-6 h-6 rounded-full mr-3"
            style={{
              backgroundColor: returnColor(value),
            }}
          />
          <span className="block truncate">{t(`generic.colors.${value}`)}</span>
        </Listbox.Button>
        <Listbox.Options className="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          {colors.map((color, colorIdx) => (
            <Listbox.Option
              key={colorIdx}
              className={({ active }) =>
                `${active ? 'text-white bg-blue-600' : 'text-gray-900'}
                cursor-pointer select-none relative py-2 pl-3 pr-9 flex items-center`
              }
              value={color.name}
            >
              {({ selected, active }) => (
                <>
                  <span
                    className="inline-block w-6 h-6 rounded-full mr-3"
                    style={{
                      backgroundColor: returnColor(color.name, color.shade),
                    }}
                  />
                  <span
                    className={`block truncate ${
                      selected ? 'font-semibold' : 'font-normal'
                    }`}
                  >
                    {t(`generic.colors.${color.name}`)}
                  </span>
                  {selected ? (
                    <span
                      className={`${
                        active ? 'text-white' : 'text-blue-600'
                      } absolute inset-y-0 right-0 flex items-center pr-4`}
                    >
                      ✓
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
}

export default ColorPicker;
