import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { bgColor, textColor } from '../../../utils/constants';

export default function SummaryStatistics() {
  const [
    data,
    allShifts,
    setSelectedParticipant,
    setSelectedSection,
    setSelectedSlot,
    setOpen,
    setOpenExport,
    setDataExport,
    setSuccess,
    slotSubsets,
  ] = useOutletContext();

  const textColors = {};
  const bgColors = {};

  const { t } = useTranslation();

  Object.entries(data.shifts).forEach(([key], index) => {
    textColors[key] = textColor[index % textColor.length];
    bgColors[key] = bgColor[index % bgColor.length];
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const statsLabels = [
    {
      name: t('manager.iterationVerification.shiftsPerSection'),
      key: 'perSection',
      bgColor: 'bg-blue-400',
    },
    {
      name: t('manager.iterationVerification.shiftsPerSubsetSlot'),
      key: 'perSlotSubset',
      bgColor: 'bg-yellow-400',
    },
    {
      name: t('manager.iterationVerification.pointsRespected'),
      key: 'respected',
      bgColor: 'bg-teal-400',
    },
  ];

  const countShiftOutputs = () => {
    // generates counts for sections, subsetslots and points respected
    // outputsCountsPerUser = {
    // userId: {
    //   perSection: {
    //     sectionId: count
    //   },
    //   perSlotSubset: {
    //     slotSubsetId: count
    //   },
    //   respected: {
    //     '+' (positive): count,
    //     '-' (negative): count,
    //   }
    // }
    // }
    const outputsCountsPerUser = {};

    data.itr_prefs.forEach(user => {
      outputsCountsPerUser[user.id_user] = {
        perSection: {},
        perSlotSubset: {},
        respected: {},
      };
    });

    data.slots.forEach(slot => {
      const shiftOutputs = slot.shift_outputs || {};
      const idSlot = slot.id_slot;

      Object.values(shiftOutputs).forEach(output => {
        const idUser = output.id_user;
        const idShift = output.id_shift;
        const itrPref = data.itr_prefs.find(user => user.id_user === idUser);
        if (output.active) {
          const posPercentage = itrPref.total_pos
            ? `${Math.round((itrPref.pos_respected / itrPref.total_pos) * 100)}%`
            : '-';
          const negPercentage = itrPref.total_neg
            ? `${Math.round(
              ((itrPref.total_neg - itrPref.neg_not_respected)
                  / itrPref.total_neg)
                  * 100,
            )}%`
            : '-';

          // Initialize user data structure if not exist
          if (!Object.keys(outputsCountsPerUser[idUser].respected).length) {
            outputsCountsPerUser[idUser].respected = {
              '+': posPercentage,
              '-': negPercentage,
            };
          }

          // Count for perSection
          if (idUser !== undefined && idShift !== undefined) {
            if (!outputsCountsPerUser[idUser].perSection[idShift]) {
              outputsCountsPerUser[idUser].perSection[idShift] = 0;
            }
            outputsCountsPerUser[idUser].perSection[idShift] += 1;
          }

          // Count for perSlotSubset
          Object.values(slotSubsets).forEach(subset => {
            const idSubset = subset.id_slot_subset;
            if (!outputsCountsPerUser[idUser].perSlotSubset[idSubset]) {
              outputsCountsPerUser[idUser].perSlotSubset[idSubset] = 0;
            }
            if (subset.slots.includes(idSlot)) {
              outputsCountsPerUser[idUser].perSlotSubset[idSubset]++;
            }
          });
        }
      });
    });

    return outputsCountsPerUser;
  };

  const returnName = (id, key) => {
    if (key === 'perSection') {
      const shift = Object.values(data.shifts).find(
        shift => shift.id_shift == id,
      );
      return shift?.acronym.length ? shift?.acronym : shift?.name;
    }
    if (key === 'perSlotSubset') {
      return Object.values(slotSubsets).find(
        slotSubset => slotSubset.id_slot_subset == id,
      )?.name;
    }
    return id == '+'
      ? t('manager.iterationVerification.positive')
      : t('manager.iterationVerification.negative');
  };

  const outputsCountsPerUser = countShiftOutputs();

  if (!Object.keys(outputsCountsPerUser).length) {
    return (
      <p className="mx-auto text-center text-gray-600 my-4">
        Este reparto no tiene guardias
      </p>
    );
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-4 overflow-x-auto h-[65vh]">
        <table className="min-w-full">
          <tbody>
            <tr className="sticky top-0 bg-white z-10">
              <th className="left-0 z-10 py-4 pl-4 pr-3 text-right text-sm font-semibold border-b border-r" />
              <div>
                <ul
                  role="list"
                  className="my-3 ml-2 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 items-center"
                >
                  {statsLabels.map(stat => (stat.key === 'perSlotSubset'
                    && !Object.keys(slotSubsets).length ? null : (
                      <li
                        key={stat.name}
                        className="col-span-1 flex rounded-md shadow-sm"
                      >
                        <div
                          className={classNames(
                            stat.bgColor,
                            'flex w-6 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white',
                          )}
                        />
                        <div className="flex flex-1 items-center justify-between truncate rounded-r-md  bg-white">
                          <div className="flex-1 truncate px-4 py-2 text-sm">
                            <p className="font-medium text-gray-900 hover:text-gray-600">
                              {stat.name}
                            </p>
                          </div>
                        </div>
                      </li>
                    )))}
                </ul>
              </div>
            </tr>
            {data.itr_prefs
              .sort((a, b) => `${a.user.firstname} ${a.user.lastname}`.localeCompare(
                `${b.user.firstname} ${b.user.lastname}`,
              ))
              .map(user => {
                // User details for easier access
                const { firstname, lastname, role } = user.user;
                const userId = user.id_user;
                // const userRoleReqs = roleReqs[user.id_user_role]
                if (!outputsCountsPerUser[userId]) {
                  outputsCountsPerUser[userId] = {};
                }

                return (
                  <tr key={userId} className="divide-y">
                    <th className="sticky bg-white whitespace-normal left-0 sm:py-4 sm:pl-4 sm:pr-3 text-right text-sm font-semibold border-b border-r max-w-[120px] md:max-w-[200px]">
                      <p>{`${firstname} ${lastname}`}</p>
                      <p className="text-xs font-normal">{role?.name}</p>
                    </th>
                    <div>
                      <ul
                        role="list"
                        className="my-3 ml-2 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 items-center"
                      >
                        {statsLabels.map(stat => (Object.keys(outputsCountsPerUser[userId][stat.key])
                          .length ? (
                            <li
                              key={stat.name}
                              className="col-span-1 flex rounded-md shadow-sm"
                            >
                              <div
                                className={classNames(
                                  stat.bgColor,
                                  'flex w-6 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white',
                                )}
                              />
                              <div className="flex flex-1 items-center justify-between truncate rounded-r-md  bg-white">
                                <div className="flex-1 truncate px-2 sm:px-4 sm:py-2 py-1 text-sm">
                                  <p className="text-gray-500">
                                    <dl className="mx-auto flex flex-row gap-px justify-start flex-wrap">
                                      {Object.keys(
                                        outputsCountsPerUser[userId][stat.key],
                                      ).map(eId => (
                                        <div
                                          key={eId}
                                          className="flex flex-wrap items-baseline justify-center bg-white px-1"
                                        >
                                          <dt className="text-xs sm:text-sm font-medium leading-6 text-gray-500">
                                            {returnName(eId, stat.key)}
                                          </dt>
                                          <dd className="w-full text-center flex-none text-sm sm:text-xl md:text-2xl font-medium sm:leading-10 tracking-tight text-gray-900 items-center">
                                            {
                                              outputsCountsPerUser[userId][
                                                stat.key
                                              ][eId]
                                            }
                                          </dd>
                                        </div>
                                      ))}
                                    </dl>
                                  </p>
                                </div>
                              </div>
                            </li>
                          ) : null))}
                      </ul>
                    </div>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
