/* eslint-disable max-len */
import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Alert from '../../../../components/FormFeedback/Alert';

export default function AddIncompGroup({
  open,
  setOpen,
  setSuccess,
  participants,
  addIncompGroup,
  editIncompGroup,
  deleteIncompGroup,
  error,
  setError,
  group,
  shifts,
}) {
  const cancelButtonRef = useRef(null);
  const [type, setType] = useState(group?.type || null);
  const [name, setName] = useState(group?.name || '');
  const allShiftsIds = shifts.map(shift => shift.id_section);
  const [checkedSections, setCheckedSections] = useState(
    group?.section_group.map(Number).filter(id => allShiftsIds.includes(id))
    || allShiftsIds,
  );
  const { idItr: id } = useParams();

  const { t } = useTranslation();

  console.log(group);

  const [checkedParticipants, setCheckedParticipants] = useState(
    group?.type === 'single_group_incomp'
      ? group?.user_group
      : [],
  );
  const [maxSimult, setMaxSimult] = useState(group?.max_simult || 0);
  const [minSimult, setMinSimult] = useState(group?.min_simult || 0);

  const [checkedGroupA, setCheckedGroupA] = useState(
    group?.type === 'cross_group_incomp'
      ? group?.user_group || []
      : [],
  );
  const [checkedGroupB, setCheckedGroupB] = useState(
    group?.type === 'cross_group_incomp'
      ? group?.user_group_secondary || []
      : [],
  );

  const handleCheckboxChange = (peopleIds, all, type) => {
    // Helper function to update the state by adding/removing a personId
    const updateState = (prevState, personId) => (prevState.includes(personId)
      ? prevState.filter(id => id !== personId)
      : [...prevState, Number(personId)]);

    // Helper function to update the specified group
    const updateGroup = (group, setGroup) => {
      if (all) {
        // If all flag is set, check if all personIds are included in the group
        if (peopleIds.every(id => group.includes(id))) {
          // If all are included, remove them from the group
          setGroup(prevState => prevState.filter(id => !peopleIds.includes(id)));
        } else {
          // If not all are included, add the missing personIds to the group
          setGroup(prevState => {
            const newState = [...prevState];
            peopleIds.forEach(personId => {
              if (!newState.includes(personId)) {
                newState.push(Number(personId));
              }
            });
            return newState;
          });
        }
      } else {
        // If all flag is not set, toggle each personId individually
        peopleIds.forEach(personId => {
          setGroup(prevState => updateState(prevState, personId));
        });
      }
    };

    // Determine which group to update based on the type
    switch (type) {
      case 'A':
        updateGroup(checkedGroupA, setCheckedGroupA);
        break;
      case 'B':
        updateGroup(checkedGroupB, setCheckedGroupB);
        break;
      default:
        updateGroup(checkedParticipants, setCheckedParticipants);
        break;
    }
  };

  const handleCheckboxChangeSections = sectionId => {
    if (checkedSections.includes(sectionId)) {
      setCheckedSections(prevState => prevState.filter(id => id !== sectionId));
    } else {
      setCheckedSections(prevState => [...prevState, sectionId]);
    }
  };

  const saveChanges = async () => {
    if (name.trim() === '') {
      setError(t('manager.incomp.emptyName'));
      setSuccess(null);
      return;
    }

    if (!type) {
      setError(t('manager.incomp.emptyType'));
      setSuccess(null);
      return;
    }

    if (type === 'single_group_incomp') {
      if (
        Number.isNaN(maxSimult)
        || !Number.isInteger(Number(maxSimult))
        || parseInt(maxSimult, 10) < 1
        || parseInt(maxSimult, 10)
        > Object.values(participants)
          .map(arr => arr.length)
          .reduce((acc, curr) => acc + curr, 0)
      ) {
        setError(t('manager.incomp.invalid#Participants'));
        setSuccess(null);
        return;
      }
    } else if (type === 'cross_group_incomp') {
      if (checkedGroupA.length < 1 || checkedGroupB.length < 1) {
        setError(t('manager.incomp.emptyGroups'));
        setSuccess(null);
        return;
      }
    }

    // check checkedParticipants vs maxsimult?

    if (checkedSections.length < 1) {
      setError(t('manager.incomp.emptySections'));
      return;
    }

    try {
      let data = {
      };
      if (type === 'single_group_incomp') {
        data = {
          name,
          max_simult: maxSimult,
          // min_simult: minSimult,
          user_group: checkedParticipants,
          id_sections: checkedSections,
        };
      } else if (type === 'cross_group_incomp') {
        data = {
          name,
          user_group: checkedGroupA,
          user_group_secondary: checkedGroupB,
          id_sections: checkedSections,
        };
      }
      data.id_itr = id;
      if (group) {
        data.id_rule = group.id_rule;
        editIncompGroup.mutate(data);
      } else {
        data.type = type;
        addIncompGroup.mutate(data);
      }
    } catch (error) {
      // this try-catch doesn't really work so we might as well delete it?
      setSuccess(null);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const types = [
    {
      id: 'single_group_incomp',
      name: t('manager.incomp.singleGroup'),
      description: t('manager.incomp.singleGroupDescription'),
    },
    {
      id: 'cross_group_incomp',
      name: t('manager.incomp.crossGroup'),
      description: t('manager.incomp.crossGroupDescription'),
    },
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilSquareIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
                    <Dialog.Title
                      as="h3"
                      className="text-lg mb-5 mt-2 text-blue-600 font-semibold leading-6"
                    >
                      {group
                        ? t('manager.incomp.edit')
                        : t('manager.incomp.create')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="relative rounded-md px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                        <label
                          htmlFor="name"
                          className="block text-xs font-medium text-gray-900"
                        >
                          {t('generic.firstName')}
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder={t('generic.firstName')}
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                      </div>
                      <div className="my-4 overflow-scroll">
                        <p className="text-base font-semibold text-gray-900 mb-2">
                          {t('manager.incomp.chooseType')}
                        </p>
                        <RadioGroup value={type} onChange={setType}>
                          <div className="-space-y-px rounded-md bg-white">
                            {types.map((setting, settingIdx) => (
                              <RadioGroup.Option
                                key={setting.id}
                                value={setting.id}
                                className={({ checked, disabled }) => classNames(
                                  settingIdx === 0
                                    ? 'rounded-tl-md rounded-tr-md'
                                    : '',
                                  settingIdx === types.length - 1
                                    ? 'rounded-bl-md rounded-br-md'
                                    : '',
                                  checked
                                    ? 'z-10 border-blue-200 bg-blue-50'
                                    : 'border-gray-200',
                                  disabled
                                    ? 'cursor-not-allowed'
                                    : 'cursor-pointer',
                                  'relative flex border p-4 focus:outline-none',
                                )}
                                disabled={group && group?.type}
                              >
                                {({ active, checked, disabled }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        checked
                                          ? 'bg-blue-600 border-transparent'
                                          : 'bg-white border-gray-300',
                                        active
                                          ? 'ring-2 ring-offset-2 ring-blue-600'
                                          : '',
                                        disabled
                                          ? 'cursor-not-allowed'
                                          : 'cursor-pointer',
                                        'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center',
                                      )}
                                      aria-hidden="true"
                                    >
                                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                                    </span>
                                    <span className="ml-3 flex flex-col">
                                      <RadioGroup.Label
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? 'text-blue-900'
                                            : 'text-gray-900',
                                          'block text-sm font-medium',
                                        )}
                                      >
                                        {setting.name}
                                      </RadioGroup.Label>
                                      <RadioGroup.Description
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? 'text-blue-700'
                                            : 'text-gray-500',
                                          'block text-sm',
                                        )}
                                      >
                                        {setting.description}
                                      </RadioGroup.Description>
                                    </span>
                                  </>
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      </div>
                      <fieldset className="mt-4 max-h-[50vh] overflow-scroll">
                        <legend className="text-base font-semibold text-gray-900 my-2">
                          {t('manager.incomp.chooseSections')}
                        </legend>
                        <div className="my-2 pb-3 px-3 overflow-scroll">
                          {shifts
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(shift => (
                              <div key={shift.id_section}>
                                <div className="flex flex-row items-center">
                                  <input
                                    id={shift.id_section}
                                    name={shift.id_section}
                                    type="checkbox"
                                    checked={checkedSections.includes(
                                      shift.id_section,
                                    )}
                                    onChange={() => handleCheckboxChangeSections(
                                      shift.id_section,
                                    )}
                                    className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                  />
                                  <h3 className=" font-medium text-gray-900">
                                    {shift.name}
                                  </h3>
                                </div>
                              </div>
                            ))}
                        </div>
                      </fieldset>
                      {type === 'single_group_incomp' ? (
                        <>
                          <div className="relative rounded-md mb-3 px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                            <label htmlFor="job-title" className="block text-xs font-medium text-gray-900">
                              {t('manager.incomp.minNumber')}
                            </label>
                            <input
                              type="number"
                              name="minSimult"
                              id="minSimult"
                              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              placeholder="#"
                              min="0"
                              value={minSimult}
                              onChange={e => setMinSimult(e.target.value)}
                            />
                          </div>
                          <div className="relative rounded-md mb-3 px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                            <label
                              htmlFor="job-title"
                              className="block text-xs font-medium text-gray-900"
                            >
                              {t('manager.incomp.maxNumber')}
                            </label>
                            <input
                              type="number"
                              name="description"
                              id="description"
                              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              placeholder="#"
                              min="0"
                              max={Object.values(participants)
                                .map(arr => arr.length)
                                .reduce((acc, curr) => acc + curr, 0)}
                              value={maxSimult}
                              onChange={e => setMaxSimult(e.target.value)}
                            />
                          </div>
                        </>
                      ) : null}
                      <fieldset className="mt-1 max-h-[50vh] overflow-scroll">
                        {type ? (
                          <legend className="text-base font-semibold text-gray-900 my-2">
                            {type === 'single_group_incomp'
                              ? t('manager.incomp.choosePeople')
                              : t('manager.incomp.chooseGroups')}
                          </legend>
                        ) : null}
                        {type === 'single_group_incomp' ? (
                          <div className="my-2 pb-3 px-3 overflow-scroll">
                            {Object.keys(participants)
                              .sort((a, b) => a.localeCompare(b))
                              .map(role => {
                                const roleIds = participants[role].map(
                                  person => Number(person.id),
                                );
                                return (
                                  <div key={role}>
                                    <div className="flex flex-row items-center">
                                      <input
                                        id={role}
                                        name={role}
                                        type="checkbox"
                                        checked={roleIds.every(id => checkedParticipants.includes(id))}
                                        onChange={() => handleCheckboxChange(roleIds, true)}
                                        className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                      />
                                      <h3 className="text-base text-blue-600 font-semibold my-2">
                                        {role}
                                      </h3>
                                    </div>
                                    {participants[role]
                                      .sort((a, b) => a.fullName.localeCompare(b.fullName))
                                      .map(person => (
                                        // <p key={person.id}>{person.fullName}</p>
                                        <div
                                          key={person.id}
                                          className="relative flex items-start"
                                        >
                                          <div className="ml-3 flex h-6 items-center">
                                            <input
                                              id={`person-${person.id}`}
                                              name={`person-${person.id}`}
                                              type="checkbox"
                                              checked={checkedParticipants.includes(
                                                person.id,
                                              )}
                                              onChange={() => handleCheckboxChange(
                                                [person.id],
                                                false,
                                              )}
                                              className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                            />
                                          </div>
                                          <div className="min-w-0 flex-1 text-sm leading-6">
                                            <label
                                              htmlFor={`person-${person.id}`}
                                              className="select-none font-medium text-gray-900"
                                            >
                                              {person.fullName}
                                            </label>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                );
                              })}
                          </div>
                        ) : type === 'cross_group_incomp' ? (
                          <div className="my-2 pb-3 px-3 overflow-scroll flex flex-row justify-around">
                            {/* LHS */}
                            <div className="shadow-md bg-gray-50 rounded-md p-4">
                              <p className="text-center font-semibold text-teal-600">
                                {t('manager.incomp.groupA')}
                              </p>
                              {Object.keys(participants)
                                .sort((a, b) => a.localeCompare(b))
                                .map(role => {
                                  const roleIds = participants[role].map(
                                    person => Number(person.id),
                                  );
                                  const roleDisabled = roleIds.every(id => checkedGroupB.includes(id));
                                  return (
                                    <div key={role}>
                                      <div className="flex flex-row items-center">
                                        <input
                                          id={role}
                                          name={role}
                                          type="checkbox"
                                          disabled={roleDisabled}
                                          checked={roleIds.every(id => checkedGroupA.includes(id))}
                                          onChange={() => handleCheckboxChange(
                                            roleIds,
                                            true,
                                            'A',
                                          )}
                                          className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600 disabled:bg-gray-200"
                                        />
                                        <h3
                                          className={`text-base text-blue-600 font-semibold my-2 ${roleDisabled ? 'line-through' : ''}`}
                                        >
                                          {role}
                                        </h3>
                                      </div>
                                      {participants[role]
                                        .sort((a, b) => a.fullName.localeCompare(b.fullName))
                                        .map(person => {
                                          const disabled = checkedGroupB.includes(person.id);
                                          return (
                                            <div
                                              key={person.id}
                                              className="relative flex items-start"
                                            >
                                              <div className="ml-3 flex h-6 items-center">
                                                <input
                                                  id={`person-${person.id}`}
                                                  name={`person-${person.id}`}
                                                  type="checkbox"
                                                  disabled={disabled}
                                                  checked={checkedGroupA.includes(
                                                    person.id,
                                                  )}
                                                  onChange={() => handleCheckboxChange(
                                                    [person.id],
                                                    false,
                                                    'A',
                                                  )}
                                                  className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600 disabled:bg-gray-200"
                                                />
                                              </div>
                                              <div className="min-w-0 flex-1 text-sm leading-6">
                                                <label
                                                  htmlFor={`person-${person.id}`}
                                                  className={`select-none font-medium text-gray-900 ${disabled ? 'line-through' : ''}`}
                                                >
                                                  {person.fullName}
                                                </label>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="border-r-2 border-blue-500 w-1" />
                            {/* RHS */}
                            <div className="shadow-md bg-gray-50 rounded-md p-4">
                              <p className="text-center font-semibold text-teal-600">
                                {t('manager.incomp.groupB')}
                              </p>
                              {Object.keys(participants)
                                .sort((a, b) => a.localeCompare(b))
                                .map(role => {
                                  const roleIds = participants[role].map(
                                    person => Number(person.id),
                                  );
                                  const roleDisabled = roleIds.every(id => checkedGroupA.includes(id));
                                  return (
                                    <div key={role}>
                                      <div className="flex flex-row items-center">
                                        <input
                                          id={role}
                                          name={role}
                                          type="checkbox"
                                          disabled={roleDisabled}
                                          checked={roleIds.every(id => checkedGroupB.includes(id))}
                                          onChange={() => handleCheckboxChange(
                                            roleIds,
                                            true,
                                            'B',
                                          )}
                                          className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600 disabled:bg-gray-200"
                                        />
                                        <h3
                                          className={`text-base text-blue-600 font-semibold my-2 ${roleDisabled ? 'line-through' : ''}`}
                                        >
                                          {role}
                                        </h3>
                                      </div>
                                      {participants[role]
                                        .sort((a, b) => a.fullName.localeCompare(b.fullName))
                                        .map(person => {
                                          const disabled = checkedGroupA.includes(person.id);
                                          return (
                                            <div
                                              key={person.id}
                                              className="relative flex items-start"
                                            >
                                              <div className="ml-3 flex h-6 items-center">
                                                <input
                                                  id={`person-${person.id}`}
                                                  name={`person-${person.id}`}
                                                  type="checkbox"
                                                  disabled={disabled}
                                                  checked={checkedGroupB.includes(
                                                    person.id,
                                                  )}
                                                  onChange={() => handleCheckboxChange(
                                                    [person.id],
                                                    false,
                                                    'B',
                                                  )}
                                                  className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600 disabled:bg-gray-200"
                                                />
                                              </div>
                                              <div className="min-w-0 flex-1 text-sm leading-6">
                                                <label
                                                  htmlFor={`person-${person.id}`}
                                                  className={`select-none font-medium text-gray-900 ${disabled ? 'line-through' : ''}`}
                                                >
                                                  {person.fullName}
                                                </label>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : null}
                      </fieldset>
                    </div>
                  </div>
                </div>
                {error ? <Alert success={false} text={error} /> : null}
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4 justify-between">
                  <div>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                      onClick={() => {
                        saveChanges();
                      }}
                    >
                      {t('generic.save')}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      {t('generic.cancel')}
                    </button>
                  </div>
                  {group ? (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto ml-6"
                      onClick={() => {
                        deleteIncompGroup.mutate({
                          id_rule: group.id_rule,
                        });
                      }}
                    >
                      {t('generic.eliminate')}
                    </button>
                  ) : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
