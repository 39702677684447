"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteLabelMutation = exports.useEditLabelMutation = exports.useCreateLabelMutation = void 0;
var react_query_1 = require("@tanstack/react-query");
var api_1 = require("../../api");
var useCreateLabelMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, name = params.name, shade = params.shade;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/labels/create"),
                method: "post",
                data: { name: name, shade: shade },
            });
        } }, options), queryClient);
};
exports.useCreateLabelMutation = useCreateLabelMutation;
var useEditLabelMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, id_slot_label = params.id_slot_label, name = params.name, shade = params.shade;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/labels/").concat(id_slot_label, "/edit"),
                method: "patch",
                data: { name: name, shade: shade },
            });
        } }, options), queryClient);
};
exports.useEditLabelMutation = useEditLabelMutation;
var useDeleteLabelMutation = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, id_slot_label = params.id_slot_label;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/labels/").concat(id_slot_label, "/delete"),
                method: "delete",
            });
        } }, options), queryClient);
};
exports.useDeleteLabelMutation = useDeleteLabelMutation;
