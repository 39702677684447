import { Dialog, Transition } from '@headlessui/react';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { request } from '@youshift/shared/api';

import Alert from '../../../../components/FormFeedback/Alert';
// import { useTutorial } from '../../../../utils/TutorialContext';

export default function DaysOff({
  open,
  setOpen,
  slotsOff,
  slotTypes,
  setSuccess,
  selectedShift,
  disabled,
}) {
  const cancelButtonRef = useRef(null);
  const { idItr: id } = useParams();
  // acommodate resolution adding times and + 5 * slotsPerDay
  const { t } = useTranslation();
  const days = [
    t('calendars.monday'),
    t('calendars.tuesday'),
    t('calendars.wednesday'),
    t('calendars.thursday'),
    t('calendars.friday'),
    t('calendars.saturday'),
    t('calendars.sunday'),
  ];
  const shortDays = [
    t('calendars.mo'),
    t('calendars.tu'),
    t('calendars.we'),
    t('calendars.th'),
    t('calendars.fr'),
    t('calendars.sa'),
    t('calendars.su'),
  ];
  const [error, setError] = useState(false);

  const subseqSlots = idx => {
    const output = [];
    for (let i = idx + 1; i < idx + 5; i++) {
      output.push(slotTypes[i % slotTypes.length]);
    }
    return output;
  };

  // const {
  //   next, currentTutorial, stepId, isRunning,
  // } = useTutorial();

  const [selectedSlots, setSelectedSlots] = useState(
    slotsOff.map(({ id_day_off, ...rest }) => rest),
  );

  const queryClient = useQueryClient();

  const saveDaysOff = useMutation({
    mutationFn: () => request({
      url: `manager/itrs/${id}/shifts/${selectedShift}/slots_off`,
      method: 'post',
      data: { slots_off: selectedSlots },
    }),
    onSuccess: () => {
      queryClient.invalidateQueries(['shifts', id]);
      setSuccess(t('manager.servicesConfig.daysOffSuccess'));
      setError(false);
      setOpen(false);
      // if (
      //   isRunning
      //   && currentTutorial?.id === 'manager_iteration_configuration'
      //   && ((currentTutorial?.params?.existingSections
      //     && stepId === 'createShift')
      //     || stepId === 'daysOffSetUp')
      // ) {
      //   next('manager_iteration_configuration');
      // }
    },
    onError: () => {
      setError(t('manager.servicesConfig.daysOffError'));
      setSuccess(false);
    },
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                id="daysOffSetUp"
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
              >
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-teal-100 sm:mx-0 sm:h-10 sm:w-10">
                    <Cog8ToothIcon
                      className="h-6 w-6 text-teal-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {t('manager.servicesConfig.daysOffSetup')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {t('manager.servicesConfig.daysOffSetupDescription')}
                      </p>
                      <div className="my-10 mx-auto">
                        {slotTypes.map((slot, idx) => (
                          <div
                            className="flex flex-row gap-3 my-4 items-center justify-center"
                            key={slot.id_slot_type}
                          >
                            <p className="w-20 text-blue-600">
                              {days[slot.cycle_idx]}
                            </p>
                            {subseqSlots(idx).map(subseqSlot => {
                              const selected = selectedSlots.find(
                                e => e.id_slot_type === slot.id_slot_type
                                  && e.slot_off_slot_type
                                  === subseqSlot.id_slot_type,
                              );
                              // grid cols
                              return (
                                <button
                                  className={`${selected ? 'bg-blue-200' : 'hover:bg-blue-50'} px-4 py-2 rounded-lg border border-blue-600`}
                                  onClick={() => {
                                    if (selected) {
                                      setSelectedSlots(
                                        selectedSlots.filter(
                                          e => e.id_slot_type
                                            !== slot.id_slot_type
                                            || e.slot_off_slot_type
                                            !== subseqSlot.id_slot_type,
                                        ),
                                      );
                                    } else {
                                      setSelectedSlots([
                                        ...selectedSlots,
                                        {
                                          id_slot_type: slot.id_slot_type,
                                          slot_off_slot_type:
                                            subseqSlot.id_slot_type,
                                        },
                                      ]);
                                    }
                                  }}
                                  key={subseqSlot.id_slot_type}
                                  disabled={disabled}
                                >
                                  {shortDays[subseqSlot.cycle_idx]}
                                </button>
                              );
                            })}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                  {disabled ? null : (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                      onClick={() => saveDaysOff.mutate()}
                    >
                      {t('generic.save')}
                    </button>
                  )}
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    {t('generic.cancel')}
                  </button>
                </div>
                {error ? <Alert text={error} /> : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
