import { PlusIcon } from '@heroicons/react/20/solid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import { request } from '@youshift/shared/api';
import {
  crossShiftReqsQuery,
  itrRolesQuery,
  shiftsQuery,
} from '@youshift/shared/hooks/jsQueries';

import Alert from '../../../../components/FormFeedback/Alert';
import { requireApproved, requireManager } from '../../../../utils/checks';
import { square } from '../../../../utils/constants';
import AddCluster from './AddCluster';

export const crossShiftReqsLoader = queryClient => async ({ params }) => {
  const user = await requireManager(queryClient);
  await requireApproved(user);
  const crossShiftsQuery = crossShiftReqsQuery(params.id);
  return (
    queryClient.getQueryData(crossShiftsQuery.queryKey)
    ?? (await queryClient.fetchQuery(crossShiftsQuery))
  );
};

export default function CrossShiftReqs() {
  const { idItr: id } = useParams();
  const { data, isLoading } = useQuery(crossShiftReqsQuery(id));
  const { data: shifts } = useQuery(shiftsQuery(id));
  const { data: itrRoles } = useQuery(itrRolesQuery(id));

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [cluster, setCluster] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const queryClient = useQueryClient();

  const addShiftCluster = useMutation({
    mutationFn: params => request({
      url: `/manager/itrs/${id}/shift_clusters/new`,
      method: 'post',
      data: params,
    }),
    onSuccess: () => {
      setSuccess(t('manager.clusters.successCreate'));
      setOpen(false);
      setError(false);
      queryClient.invalidateQueries({ queryKey: ['shiftClusters', id] });
    },
    onError: () => {
      setSuccess(false);
      setError(t('manager.clusters.errorCreate'));
    },
  });

  const editShiftCluster = useMutation({
    mutationFn: ({ id_shift_cluster_requirement, ...rest }) => request({
      url: `/manager/itrs/${id}/shift_clusters/${id_shift_cluster_requirement}/edit`,
      method: 'patch',
      data: rest,
    }),
    onSuccess: () => {
      setSuccess(t('manager.clusters.successEdit'));
      setOpen(false);
      setError(false);
      queryClient.invalidateQueries({ queryKey: ['shiftClusters', id] });
    },
    onError: () => {
      setSuccess(false);
      setError(t('manager.clusters.errorEdit'));
    },
  });

  const deleteShiftCluster = useMutation({
    mutationFn: ({ id_shift_cluster_requirement }) => request({
      url: `/manager/itrs/${id}/shift_clusters/${id_shift_cluster_requirement}/delete`,
      method: 'delete',
    }),
    onSuccess: () => {
      setSuccess(t('manager.clusters.successEliminate'));
      setOpen(false);
      setError(false);
      queryClient.invalidateQueries({ queryKey: ['shiftClusters', id] });
    },
    onError: () => {
      setSuccess(false);
      setError(t('manager.clusters.errorEliminate'));
    },
  });

  const context = useOutletContext();

  const roleToShiftMap = useMemo(() => {
    const map = {};

    shifts.shifts.forEach(shift => {
      shift.role_reqs.forEach(roleReq => {
        if (!map[roleReq.id]) {
          map[roleReq.id] = [];
        }
        map[roleReq.id].push(shift.id_shift);
      });
    });

    return map;
  }, [shifts]);

  return (
    <>
      {open ? (
        <AddCluster
          roles={itrRoles.roles.filter(role => itrRoles.itr_roles[role.id])}
          shifts={shifts.shifts}
          cluster={cluster}
          open={open}
          setOpen={setOpen}
          addShiftCluster={addShiftCluster}
          editShiftCluster={editShiftCluster}
          deleteShiftCluster={deleteShiftCluster}
          setSuccess={setSuccess}
          error={error}
          setError={setError}
          roleToShiftMap={roleToShiftMap}
        />
      ) : null}
      {success ? (
        <div className="mt-3">
          <Alert success text={success} />
        </div>
      ) : null}
      {!isLoading
        && (data.shift_clusters_reqs.length ? (
          <div className="lg:mx-10">
            {context ? (
              <button
                type="button"
                className="block rounded-md bg-blue-600 py-2 px-3 mt-6 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() => {
                  setOpen(true);
                  setCluster(null);
                }}
              >
                {t('manager.clusters.createNew')}
              </button>
            ) : null}
            {data.shift_clusters_reqs.map(cluster => (
              <div className="overflow-hidden rounded-lg bg-white shadow mt-6">
                <div className="px-4 py-5 sm:px-6">
                  <div className="flex flex-row gap-2 text-blue-600 font-semibold">
                    <p>
                      {t('manager.clusters.clusterTitle', {
                        role: itrRoles.roles.find(
                          role => role.id == cluster.id_user_role,
                        )?.name,
                      })}
                    </p>
                    <button
                      onClick={() => {
                        setCluster(cluster);
                        setOpen(true);
                      }}
                      disabled={!context}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-gray-600"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex flex-row justify-center gap-5">
                    <div className="flex flex-col items-center gap-2">
                      <p>{t('manager.clusters.minReq')}</p>
                      <div style={square}>{cluster.min_req}</div>
                    </div>
                    <div className="flex flex-col items-center gap-2">
                      <p>{t('manager.clusters.maxReq')}</p>
                      <div style={square}>{cluster.max_req}</div>
                    </div>
                  </div>
                  <p className="mt-2 text-gray-500">
                    {t('manager.clusters.participatingSections')}
                  </p>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:p-6">
                  <div className="flex flex-row gap-8 flex-wrap">
                    {cluster.cluster_elements.map(cluster_element => (
                      <div className="bg-teal-400/20 text-gray-900 py-4 px-3 whitespace-nowrap rounded-md">
                        {
                          shifts.shifts.find(
                            shift => shift.id_shift == cluster_element.id_shift,
                          )?.name
                        }
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : context ? (
          <div className="text-center mt-24">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 0 1-1.125-1.125v-3.75ZM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-8.25ZM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-2.25Z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              {t('manager.clusters.createPrompt')}
            </h3>
            <p className="text-sm text-gray-500 text-whitespace max-w-lg my-4 mx-auto align-center">
              {t('manager.clusters.definition')}
            </p>
            <div className="mt-6">
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={() => {
                  setOpen(true);
                  setCluster(null);
                }}
              >
                <PlusIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                {t('manager.clusters.newGroup')}
              </button>
            </div>
          </div>
        ) : (
          <p className="text-center">{t('manager.clusters.noClusters')}</p>
        ))}
    </>
  );
}
