/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
import { PlusIcon } from '@heroicons/react/20/solid';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useReducer, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import { request } from '@youshift/shared/api';
import { classNames } from '@youshift/shared/utils';
import { itrRolesQuery, shiftsQuery } from '@youshift/shared/hooks/jsQueries';

import RoleReqsCounter, {
  UpdateMinMaxRoles,
} from '../../../../components/Calendars/RoleReqsCounter';
import ServicesCounter, {
  UpdateMinMaxServices,
} from '../../../../components/Calendars/ServicesCounter';
import Alert from '../../../../components/FormFeedback/Alert';
// import { useTutorial } from '../../../../utils/TutorialContext';
import { requireApproved, requireManager } from '../../../../utils/checks';
import AddService from './AddService';
import DaysOff from './DaysOff';
import EditService from './EditService';

const { v4: uuidv4 } = require('uuid');

export const iterationShiftsLoader = queryClient => async ({ params }) => {
  const user = await requireManager(queryClient);
  await requireApproved(user);
  const query = shiftsQuery(params.id);
  return (
    queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
  );
};

export default function ServicesConfig() {
  const { idItr: id } = useParams();
  const { data } = useQuery(shiftsQuery(id));
  const [editOpen, setEditOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [offOpen, setOffOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [addError, setAddError] = useState(false);
  const [editError, setEditError] = useState(false);
  // const [infoOpen, setInfoOpen] = useState(false)
  const [selectedShift, setSelectedShift] = useState(
    data?.shifts[0]?.id_shift || '',
  );
  const queryClient = useQueryClient();

  // const {
  //   next, currentTutorial, stepId, isRunning,
  // } = useTutorial();

  const { t } = useTranslation();

  const context = useOutletContext();

  const parseWeekDay = start => {
    const weekdays = [
      t('calendars.sunday'),
      t('calendars.monday'),
      t('calendars.tuesday'),
      t('calendars.wednesday'),
      t('calendars.thursday'),
      t('calendars.friday'),
      t('calendars.saturday'),
    ];

    // Create a Date object with the provided start time in GMT
    const date = new Date(start);

    // Get the weekday and day
    const weekday = weekdays[date.getUTCDay()];
    const day = date.getUTCDate();

    return `${weekday} ${day}`;
  };

  const { data: itrRoles } = useQuery(itrRolesQuery(id));

  function cleanRoles(obj) {
    const itrRoles = Object.keys(obj.itr_roles);
    const cleanedRoles = obj.roles
      .filter(role => itrRoles.includes(String(role.id)))
      .map(role => ({
        id: role.id,
        name: role.name,
      }));
    return cleanedRoles;
  }

  const resetSelected = () => {
    setSelectedShift(data?.shifts[0]?.id_shift);
  };

  function needsReducer(state, action) {
    if (!context) {
      return state;
    }
    switch (action.type) {
      case 'UPDATE_NEEDS':
        return action.payload;
      case 'EDIT_MAX_NEED':
        return state.map(need => {
          if (
            need.id_slot === action.id_slot
            && need.shift_needs[selectedShift]
          ) {
            const currentMinNeed = need.shift_needs[selectedShift].min_need;
            const updatedMaxNeed = Math.max(action.maxNeed, currentMinNeed, 0);
            const updatedMinNeed = Math.min(currentMinNeed, updatedMaxNeed);
            return {
              ...need,
              shift_needs: {
                ...need.shift_needs,
                [selectedShift]: {
                  ...need.shift_needs[selectedShift],
                  min_need: updatedMinNeed,
                  max_need: updatedMaxNeed,
                },
              },
            };
          }
          return need;
        });

      case 'EDIT_MIN_NEED':
        return state.map(need => {
          if (
            need.id_slot === action.id_slot
            && need.shift_needs[selectedShift]
          ) {
            const currentMaxNeed = need.shift_needs[selectedShift].max_need;
            const updatedMinNeed = Math.max(action.minNeed, 0);
            const updatedMaxNeed = Math.max(currentMaxNeed, updatedMinNeed);
            return {
              ...need,
              shift_needs: {
                ...need.shift_needs,
                [selectedShift]: {
                  ...need.shift_needs[selectedShift],
                  min_need: updatedMinNeed,
                  max_need: updatedMaxNeed,
                },
              },
            };
          }
          return need;
        });

      case 'EDIT_ALL_MAX_NEED':
        return state.map(need => {
          if (
            need.shift_needs
            && Object.prototype.hasOwnProperty.call(
              need.shift_needs,
              selectedShift,
            )
          ) {
            const maxNeed = Math.max(
              0,
              need.shift_needs[selectedShift].max_need + action.increment,
            );
            const minNeed = Math.min(
              maxNeed,
              need.shift_needs[selectedShift].min_need,
            );
            return {
              ...need,
              shift_needs: {
                ...need.shift_needs,
                [selectedShift]: {
                  ...need.shift_needs[selectedShift],
                  max_need: maxNeed,
                  min_need: minNeed,
                },
              },
            };
          }
          return need;
        });

      case 'EDIT_ALL_MIN_NEED':
        return state.map(need => {
          if (
            need.shift_needs
            && Object.prototype.hasOwnProperty.call(
              need.shift_needs,
              selectedShift,
            )
          ) {
            const minNeed = Math.max(
              0,
              need.shift_needs[selectedShift].min_need + action.increment,
            );
            const maxNeed = Math.max(
              minNeed,
              need.shift_needs[selectedShift].max_need,
            );
            return {
              ...need,
              shift_needs: {
                ...need.shift_needs,
                [selectedShift]: {
                  ...need.shift_needs[selectedShift],
                  min_need: minNeed,
                  max_need: maxNeed,
                },
              },
            };
          }
          return need;
        });

      default:
        return state;
    }
  }

  function rolesReducer(state, action) {
    if (!context) {
      return state;
    }
    switch (action.type) {
      case 'UPDATE_ROLES':
        return action.payload;
      case 'EDIT_MAX_REQ':
        return state.map(shift => {
          if (shift.id_shift === selectedShift) {
            return {
              ...shift,
              role_reqs: shift.role_reqs.map(role => {
                if (role.id_role_req === action.id_role_req) {
                  const currentMinReq = role.min_req;
                  const updatedMaxReq = Math.max(
                    action.maxReq,
                    currentMinReq,
                    0,
                  );
                  const updatedMinReq = Math.min(currentMinReq, updatedMaxReq);
                  return {
                    ...role,
                    min_req: updatedMinReq,
                    max_req: updatedMaxReq,
                  };
                }
                return role;
              }),
            };
          }
          return shift;
        });

      case 'EDIT_MIN_REQ':
        return state.map(shift => {
          if (shift.id_shift === selectedShift) {
            return {
              ...shift,
              role_reqs: shift.role_reqs.map(role => {
                if (role.id_role_req === action.id_role_req) {
                  const currentMaxReq = role.max_req;
                  const updatedMinReq = Math.max(action.minReq, 0);
                  const updatedMaxReq = Math.max(currentMaxReq, updatedMinReq);
                  return {
                    ...role,
                    min_req: updatedMinReq,
                    max_req: updatedMaxReq,
                  };
                }
                return role;
              }),
            };
          }
          return shift;
        });

      case 'EDIT_ALL_MAX_REQ':
        return state.map(shift => {
          if (shift.id_shift === selectedShift) {
            return {
              ...shift,
              role_reqs: shift.role_reqs.map(role => {
                const maxReq = Math.max(0, role.max_req + action.increment);
                const minReq = Math.min(maxReq, role.min_req);
                return {
                  ...role,
                  max_req: maxReq,
                  min_req: minReq,
                };
              }),
            };
          }
          return shift;
        });

      case 'EDIT_ALL_MIN_REQ':
        return state.map(shift => {
          if (shift.id_shift === selectedShift) {
            return {
              ...shift,
              role_reqs: shift.role_reqs.map(role => {
                const minReq = Math.max(0, role.min_req + action.increment);
                const maxReq = Math.max(minReq, role.max_req);
                return {
                  ...role,
                  min_req: minReq,
                  max_req: maxReq,
                };
              }),
            };
          }
          return shift;
        });

      default:
        return state;
    }
  }

  const adjustWeekDay = shifts_needs => {
    const weekdayAbbreviations = {
      Mon: 0,
      Tue: 1,
      Wed: 2,
      Thu: 3,
      Fri: 4,
      Sat: 5,
      Sun: 6,
    };

    const startWeekDay = shifts_needs[0].start.substring(0, 3);
    const endWeekDay = shifts_needs[shifts_needs.length - 1].start.substring(
      0,
      3,
    );
    const startCount = weekdayAbbreviations[startWeekDay];
    const endCount = 6 - weekdayAbbreviations[endWeekDay];
    const fillersStart = Array(startCount)
      .fill(null)
      .map(() => ({
        id_slot: uuidv4(),
        start: '',
      }));

    const fillersEnd = Array(endCount)
      .fill(null)
      .map(() => ({
        id_slot: uuidv4(),
        start: '',
      }));

    const adjustedShiftsNeeds = [
      ...fillersStart,
      ...shifts_needs,
      ...fillersEnd,
    ];
    return adjustedShiftsNeeds;
  };

  const [needs, needsDispatch] = useReducer(
    needsReducer,
    adjustWeekDay(data.shifts_needs),
  );
  const [roles, rolesDispatch] = useReducer(rolesReducer, data.shifts);

  const supplyDemandChecks = () => {
    let sumMinNeeds = 0;
    let sumMaxNeeds = 0;
    let sumMinReqs = 0;
    let sumMaxReqs = 0;
    let potentialPartakers = 0;

    if (data.shifts.length > 0 && selectedShift) {
      const { role_reqs } = roles.find(r => r.id_shift == selectedShift);

      for (let i = 0; i < role_reqs.length; i++) {
        const {
          partakers, min_req, max_req, volunteers,
        } = role_reqs[i];

        sumMinReqs += partakers * min_req;
        sumMaxReqs += partakers * max_req + volunteers;
        potentialPartakers += partakers + volunteers;
      }

      for (let i = 0; i < needs.length; i++) {
        if (needs[i].shift_needs) {
          const need = needs[i].shift_needs[selectedShift];
          if (need.min_need > potentialPartakers) {
            return t('manager.servicesConfig.supplyDemandError1', {
              minNeed: need.min_need,
              day: parseWeekDay(needs[i].start),
            });
          }
          sumMinNeeds += need.min_need;
          sumMaxNeeds += need.max_need;
        }
      }
    }

    if (sumMinNeeds > sumMaxReqs) {
      return t('manager.servicesConfig.supplyDemandError2', {
        sumMinNeeds,
        sumMaxReqs,
      });
    }
    if (sumMinReqs > sumMaxNeeds) {
      return t('manager.servicesConfig.supplyDemandError3', {
        sumMinReqs,
        sumMaxNeeds,
      });
    }

    return false;
  };

  const supplyDemand = supplyDemandChecks();

  useEffect(() => {
    const adjustedNeeds = adjustWeekDay(data?.shifts_needs) || [];
    needsDispatch({ type: 'UPDATE_NEEDS', payload: adjustedNeeds });
  }, [data?.shifts_needs]);

  useEffect(() => {
    if (data?.shifts) {
      rolesDispatch({ type: 'UPDATE_ROLES', payload: data.shifts });
    }
  }, [data?.shifts]);

  const addShift = useMutation({
    mutationFn: params => request({
      url: `/manager/itrs/${id}/shifts/new`,
      method: 'post',
      data: params,
    }),
    onSuccess: (data, variables) => {
      setSuccess(t('manager.servicesConfig.successCreate'));
      setAddOpen(false);
      setAddError(false);
    },
    onError: () => {
      setSuccess(false);
      setAddError(t('manager.servicesConfig.errorCreate'));
    },
    onSettled: async (data, error, variables) => {
      await queryClient.invalidateQueries({ queryKey: ['shifts', String(id)] });
      setSelectedShift(data.shift_id);
      // is this problematic when adding more shifts (not the firsts one)?
    },
  });

  const eliminateShift = useMutation({
    mutationFn: shiftId => request({
      url: `/manager/itrs/${id}/shifts/${shiftId}`,
      method: 'delete',
    }),
    onSuccess: (_, variables) => {
      setSuccess(t('manager.servicesConfig.successEliminate'));
      setEditOpen(false);
      setEditError(false);
      if (variables === data?.shifts[0]?.id_shift) {
        setSelectedShift(data?.shifts[1]?.id_shift);
      } else {
        setSelectedShift(data?.shifts[0]?.id_shift);
      }
      queryClient.invalidateQueries({ queryKey: ['shifts', id] });
      queryClient.invalidateQueries({ queryKey: ['incompatibilities', id] });
      queryClient.invalidateQueries({ queryKey: ['slotSubsets', id] });
      queryClient.invalidateQueries({ queryKey: ['shiftClusters', id] });
    },
    onError: e => {
      if (e.response.data.type === 'ITR_UNLOCKED') {
        setEditError(t('manager.servicesConfig.errorEliminateItrUnlocked'));
      } else {
        setEditError(t('manager.servicesConfig.errorEliminate'));
      }
      setSuccess(false);
    },
  });

  const editShift = useMutation({
    mutationFn: async ({ shift_id, ...rest }) => {
      await request({
        url: `/manager/itrs/${id}/shifts/${shift_id}`,
        method: 'patch',
        data: rest,
      });
    },
    onSuccess: (_, variables) => {
      setSuccess(t('manager.servicesConfig.successEdit'));
      setEditOpen(false);
      setEditError(false);
      queryClient.invalidateQueries({ queryKey: ['shifts', id] });
    },
    onError: () => {
      setSuccess(false);
      setEditError(t('manager.servicesConfig.errorEdit'));
    },
  });

  function getUpdatedRoleReqs(updatedRoles, originalRoles) {
    const originalRole = originalRoles.find(
      role => role.id_shift === selectedShift,
    );

    if (!originalRole) {
      return []; // Return empty array if original role for selected shift is not found
    }

    const result = [];

    const updatedReqs = updatedRoles
      // eslint-disable-next-line max-len
      .filter(
        updatedRole => updatedRole.id_shift === selectedShift
          && updatedRole.role_reqs.length > 0,
      )
      .map(updatedRole => updatedRole.role_reqs
        .filter(updatedReq => {
          // eslint-disable-next-line max-len
          const originalReq = originalRole.role_reqs.find(
            req => req.id_role_req === updatedReq.id_role_req,
          );
          // eslint-disable-next-line max-len
          if (
            originalReq
            && (originalReq.min_req !== updatedReq.min_req
              || originalReq.max_req !== updatedReq.max_req)
          ) {
            return true;
          }

          return false;
        })
        .map(req => ({
          id_role_req: req.id_role_req,
          min_req: req.min_req,
          max_req: req.max_req,
        })))
      .flat();

    if (updatedReqs.length > 0) {
      result.push(...updatedReqs);
    }

    return result;
  }

  function getUpdatedNeeds(updatedNeeds, originalNeeds) {
    const originalNeedsDict = {};
    const changes = [];

    // Create a dictionary for original needs for efficient lookup
    originalNeeds.forEach(slot => {
      originalNeedsDict[slot.id_slot] = slot;
    });

    updatedNeeds.forEach(stateSlot => {
      if ('shift_needs' in stateSlot) {
        const shiftNeeds = stateSlot.shift_needs;

        if (selectedShift in shiftNeeds) {
          // Check if selected shift exists in updated needs
          const originalSlot = originalNeedsDict[stateSlot.id_slot];
          const originalShiftNeeds = originalSlot.shift_needs[selectedShift];
          const stateShiftNeeds = shiftNeeds[selectedShift];
          if (
            originalShiftNeeds?.min_need !== stateShiftNeeds?.min_need
            || originalShiftNeeds?.max_need !== stateShiftNeeds?.max_need
          ) {
            let found = false;

            // Check if the shift_id already exists in changes
            for (let i = 0; i < changes.length; i++) {
              if (changes[i].id_shift === selectedShift) {
                changes[i].needs.push({
                  id_shift_need: originalShiftNeeds.id_shift_need,
                  max_need: stateShiftNeeds?.max_need,
                  min_need: stateShiftNeeds?.min_need,
                });
                found = true;
                break;
              }
            }

            if (!found) {
              changes.push({
                id_shift_need: originalShiftNeeds.id_shift_need,
                max_need: stateShiftNeeds?.max_need,
                min_need: stateShiftNeeds?.min_need,
              });
            }
          }
        }
      }
    });

    return changes;
  }

  const returnChanges = () => {
    const updatedNeeds = getUpdatedNeeds(
      needs,
      adjustWeekDay(data?.shifts_needs),
    );
    const updatedReqs = getUpdatedRoleReqs(roles, data?.shifts);
    return {
      id_shift: selectedShift,
      shift_needs: updatedNeeds,
      role_reqs: updatedReqs,
    };
  };

  const saveChanges = useMutation({
    mutationFn: ({ id_shift, ...rest }) => request({
      url: `/manager/itrs/${id}/shifts/${id_shift}/needs_reqs`,
      method: 'patch',
      data: rest,
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shifts', id] });
    },
  });

  const changes = returnChanges();

  return (
    <>
      {editOpen ? (
        <EditService
          roles={cleanRoles(itrRoles)}
          open={editOpen}
          setOpen={setEditOpen}
          shift={
            data.shifts
              // eslint-disable-next-line eqeqeq
              .filter(shift => shift.id_shift === selectedShift)
              .map(({
                id_shift, name, description, acronym, role_reqs,
              }) => ({
                id: id_shift,
                name,
                description,
                acronym,
                role_reqs,
              }))[0]
          }
          setSuccess={setSuccess}
          allShifts={data.shifts}
          eliminateShift={eliminateShift}
          editShift={editShift}
          resetSelected={resetSelected}
          error={editError}
          setError={setEditError}
        />
      ) : null}
      {addOpen ? (
        <AddService
          roles={cleanRoles(itrRoles)}
          open={addOpen}
          setOpen={setAddOpen}
          setSuccess={setSuccess}
          allShifts={data.shifts}
          addShift={addShift}
          error={addError}
          setError={setAddError}
        />
      ) : null}
      {success ? (
        <div className="mt-3">
          <Alert success text={success} />
        </div>
      ) : null}
      {supplyDemand ? <Alert text={supplyDemand} /> : null}
      {offOpen ? (
        <DaysOff
          open={offOpen}
          setOpen={setOffOpen}
          slotsOff={
            roles.find(shift => shift.id_shift === selectedShift)
              ?.shift_slots_off
          }
          slotTypes={data.slot_types}
          setSuccess={setSuccess}
          selectedShift={selectedShift}
          disabled={!context}
        />
      ) : null}
      {/* <Information
        open={infoOpen}
        setOpen={setInfoOpen}
        title={servicesConfigInformation.title}
        text={servicesConfigInformation.text}
      /> */}
      <div className="lg:flex lg:h-full lg:flex-col">
        {selectedShift ? (
          <>
            <div className="flex flex-row justify-around items-center">
              <div className="pb-3 flex flex-row align-end mt-8 mx-auto gap-1">
                {context ? (
                  <button
                    className="self-center text-blue-600 text-xs mr-4"
                    onClick={() => setAddOpen(true)}
                  >
                    {t('manager.servicesConfig.create')}
                  </button>
                ) : null}
                <select
                  id="service"
                  name="service"
                  className="block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  value={selectedShift}
                  onChange={e => setSelectedShift(parseInt(e.target.value, 10))}
                >
                  {data?.shifts.map(shift => (
                    <option key={shift.id_shift} value={shift.id_shift}>
                      {shift.name}
                    </option>
                  ))}
                </select>
                {selectedShift && context ? (
                  <button
                    className="self-center text-teal-600 text-xs ml-4"
                    onClick={() => setEditOpen(true)}
                  >
                    {t('manager.servicesConfig.edit')}
                  </button>
                ) : null}
              </div>
            </div>
            <div className="flex flex-row justify-between mx-3">
              <button
                type="button"
                className="flex bg-teal-100 hover:bg-teal-100/70 w-fit rounded-md p-2 flex-row text-teal-600 my-3 gap-1 items-center"
                onClick={() => {
                  setOffOpen(true);
                  // if (
                  //   isRunning
                  //   && currentTutorial?.id === 'manager_iteration_configuration'
                  //   && ((currentTutorial?.params?.existingSections
                  //     && stepId === 'createFirstShift')
                  //     || stepId === 'daysOff')
                  // ) {
                  //   next('manager_iteration_configuration');
                  // }
                }}
                id="daysOff"
              >
                <Cog8ToothIcon className="h-5" />
                <p>{t('manager.servicesConfig.daysOffSetup')}</p>
              </button>
            </div>
            <div id="needs">
              <div className="flex flex-row justify-between mb-3 items-center">
                <p className="font-semibold">
                  {t('manager.servicesConfig.minMax')}
                </p>
                <div className="sm:mr-20">
                  {context
                    && (changes.shift_needs.length !== 0
                      || changes.role_reqs.length !== 0) ? (
                    <button
                      type="button"
                      className="block rounded-md bg-blue-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                      onClick={() => saveChanges.mutate(changes)}
                    >
                      {t('generic.saveChanges')}
                    </button>
                  ) : (
                    <p className="p-3 rounded-xl">
                      {t('generic.savedChanges')}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                  <div className="hidden sticky top-0 z-10 lg:grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                    <div className="bg-white py-2">
                      <Trans i18nKey="calendars.mon">
                        L
                        <span className="sr-only sm:not-sr-only">unes</span>
                      </Trans>
                    </div>
                    <div className="bg-white py-2">
                      <Trans i18nKey="calendars.tue">
                        M
                        <span className="sr-only sm:not-sr-only">artes</span>
                      </Trans>
                    </div>
                    <div className="bg-white py-2">
                      <Trans i18nKey="calendars.wed">
                        M
                        <span className="sr-only sm:not-sr-only">iércoles</span>
                      </Trans>
                    </div>
                    <div className="bg-white py-2">
                      <Trans i18nKey="calendars.thu">
                        J
                        <span className="sr-only sm:not-sr-only">ueves</span>
                      </Trans>
                    </div>
                    <div className="bg-white py-2">
                      <Trans i18nKey="calendars.fri">
                        V
                        <span className="sr-only sm:not-sr-only">iernes</span>
                      </Trans>
                    </div>
                    <div className="bg-white py-2">
                      <Trans i18nKey="calendars.sat">
                        S
                        <span className="sr-only sm:not-sr-only">ábado</span>
                      </Trans>
                    </div>
                    <div className="bg-white py-2">
                      <Trans i18nKey="calendars.sun">
                        D
                        <span className="sr-only sm:not-sr-only">omingo</span>
                      </Trans>
                    </div>
                  </div>
                  <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                    <div
                      className={`w-full lg:grid lg:grid-cols-7 lg:grid-rows-${Math.ceil(needs.length / 7)} lg:gap-px`}
                    >
                      {needs.map(slot => (
                        <div
                          key={slot.id_slot}
                          className={classNames(
                            slot.start
                              ? 'bg-white'
                              : 'bg-gray-50 text-gray-500',
                            'relative py-2 px-3 flex flex-col justify-around',
                          )}
                        >
                          <time dateTime={slot.start}>
                            {slot.start ? slot.start.slice(5, 7) : ''}
                          </time>
                          <div className="flex flex-row justify-center">
                            {slot.start ? (
                              <ServicesCounter
                                disabled={!context}
                                needs={slot.shift_needs[selectedShift]}
                                dispatch={needsDispatch}
                              />
                            ) : null}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <UpdateMinMaxServices dispatch={needsDispatch} />
              </div>
            </div>
          </>
        ) : (
          <div className="text-center mt-24">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              {t('manager.servicesConfig.createPrompt')}
            </h3>
            <p className="text-sm text-gray-500 text-whitespace max-w-lg my-4 mx-auto align-center">
              {t('manager.servicesConfig.definition')}
            </p>
            <div className="mt-6">
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                disabled={!context}
                onClick={() => {
                  setAddOpen(true);
                  next('manager_iteration_configuration');
                }}
                id="createFirstShift"
              >
                <PlusIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                {t('manager.servicesConfig.newShift')}
              </button>
            </div>
          </div>
        )}
      </div>
      {selectedShift ? (
        <div id="reqs">
          <div className="flex flex-row mt-6 mb-2 gap-2 text-gray-800">
            <p className="font-semibold">
              {t('manager.servicesConfig.minMax2')}
            </p>
          </div>
          <div className="flex flex-row items-center justify-center w-auto gap-4">
            <ul
              className={`my-6 grid grid-cols-2 gap-6 sm:grid-cols-3 lg:grid-cols-${roles.find(shift => shift.id_shift === selectedShift)?.role_reqs.length < 4 ? roles.find(shift => shift.id_shift === selectedShift)?.role_reqs.length : 4}`}
            >
              {roles
                .find(shift => shift.id_shift === selectedShift)
                ?.role_reqs.map(role => (
                  <li
                    key={role.id}
                    className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
                  >
                    <div className="flex w-full items-center justify-between space-x-6 p-6">
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <h3 className="truncate text-sm font-medium text-gray-900">
                            {role.name}
                          </h3>
                        </div>
                        <p className="mt-1 truncate text-sm text-gray-500">
                          {t(
                            'manager.servicesConfig.participantsAndVolunteers',
                            {
                              partakers: role.partakers,
                              volunteers: role.volunteers,
                            },
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <RoleReqsCounter
                        role_reqs={role}
                        dispatch={rolesDispatch}
                        disabled={!context}
                      />
                    </div>
                  </li>
                ))}
              {!roles.find(shift => shift.id_shift === selectedShift)
                ?.role_reqs.length && (
                  <li className="col-span-full text-center text-gray-500 py-4">
                    {t('manager.servicesConfig.noRole')}
                  </li>
                )}
            </ul>
            <UpdateMinMaxRoles dispatch={rolesDispatch} />
          </div>
        </div>
      ) : null}
      <div className="mx-auto">
        {context
          && (changes.shift_needs.length !== 0 || changes.role_reqs.length !== 0) ? (
          <button
            type="button"
            className="block rounded-md mx-auto bg-blue-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={() => saveChanges.mutate(changes)}
          >
            {t('generic.saveChanges')}
          </button>
        ) : null}
      </div>
    </>
  );
}
