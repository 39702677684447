/* eslint-disable max-len */
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  LoaderFunctionArgs,
  Outlet,
  useLoaderData,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { request } from '@youshift/shared/api';
import { parseIterationDates } from '@youshift/shared/utils';
import {
  itrRolesQuery,
  shiftsQuery,
  slotSubsetsQuery,
} from '@youshift/shared/hooks/jsQueries';
import { Iteration, ItrStatuses } from '@youshift/shared/types';
import { preLoadQuery } from '@youshift/shared/hooks';
import { iterationQuery, statusQuery } from '@youshift/shared/hooks/queries';

import Alert from '../components/FormFeedback/Alert';
import ConfigurationPreview from '../components/Iteration/ConfigurationPreview';
import Steps from '../components/Iteration/Steps';
import Toggle from '../components/Iteration/Toggle';
import DeleteItr from '../pages/Manager/IterationConfig/DeleteItr';
import ArchiveConfirmation from '../pages/Manager/IterationVerification/ArchiveConfirmation';
import PublishOrDelete from '../pages/Manager/IterationVerification/PublishOrDelete';
import Unpublish from '../pages/Manager/IterationVerification/Unpublish';
import { useAppOptions } from '../utils/AppOptionsContext';
import { requireManager } from '../utils/checks';
import Wrapper from '../components/Wrapper';

type ItrContextType = {
  status: ItrStatuses | undefined;
  locked: boolean;
  errors: undefined;
  infeasible: undefined;
  nameAndDate: string;
};

type IterationLoaderReturn = {
  status: ItrStatuses;
  iteration: Iteration;
};

export const iterationLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
  }: LoaderFunctionArgs): Promise<IterationLoaderReturn | null> => {
    await requireManager(queryClient);
    if (params.idItr === undefined) {
      return null;
    }
    const iteration = await preLoadQuery(
      queryClient,
      iterationQuery(params.idItr),
    );
    const status = await preLoadQuery(queryClient, statusQuery(params.idItr));
    return { iteration, status };
  };

export default function IterationLayout() {
  const { idItr: id } = useParams();
  const {
    iteration: {
      start_day,
      end_day,
      locked,
      id_itr,
      message: itrMessage,
      chain,
      itr_type,
    },
  } = useLoaderData() as IterationLoaderReturn;
  const { t } = useTranslation();
  const { appOptions } = useAppOptions();
  const { data: status } = useQuery(statusQuery(id || ''));
  const [deleteItrOpen, setDeleteItrOpen] = useState(false);
  const [unpublishOpen, setUnpublishOpen] = useState(false);
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [configPreviewOpen, setConfigPreviewOpen] = useState(false);
  const [error, setError] = useState(
    appOptions?.app_config.allow_alg_executions
      ? false
      : t('manager.iterationConfig.runningAlgDisabled'),
  );
  const [publish, setPublish] = useState(null);
  const [published, setPublished] = useState(status === 'published');
  const [archived, setArchived] = useState(status === 'archived');
  const [success, setSuccess] = useState(false);
  const [toggleEnabled, setToggleEnabled] = useState(locked ?? false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [message, setMessage] = useState(itrMessage);
  const [messageSaved, setMessageSaved] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // const runAlgMutation = useMutation({
  //   mutationFn: () =>
  //     request({ url: `/manager/itrs/${id}/flow/run_alg`, method: 'post' }),
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({ queryKey: ['iteration', id] });
  //     queryClient.invalidateQueries({ queryKey: ['status', id] });
  //     navigate(`/manager/iteration/${id}/running`);
  //   },
  //   onError: e => {
  //     switch (e.response.data.type) {
  //       case 'PRE_EXEC_ERRORS':
  //         setError(
  //           'La iteración está mal configurada. Apareceran errores más concretos más adelante',
  //         );
  //         break;
  //       case 'ITR_UNLOCKED':
  //         setError(t('manager.iterationLayouts.itrUnlocked'));
  //         break;
  //       default:
  //         setError(t('generic.error'));
  //         break;
  //     }
  //   },
  // });

  // const preRunningChecks = () => {
  //   setError(null);
  //   const errors = [];

  //   // Iteration is unlocked
  //   if (!locked) {
  //     errors.push(t('manager.iterationLayouts.itrUnlocked'));
  //   }

  //   // # Algorithm execution requires at least one shift on top of SINK.
  //   // MISSING_SHIFTS = "missing_shifts"
  //   if (allShifts.shifts.length === 0) {
  //     errors.push(t('manager.iterationConfig.noShifts'));
  //   }

  //   // # At least one slot required.
  //   // MISSING_ITR_SLOTS = "missing_itr_slots"
  //   if (allShifts.shifts_needs.length === 0) {
  //     errors.push(t('manager.iterationConfig.noSlots'));
  //   }

  //   // # At least one itr role required.
  //   // MISSING_ITR_ROLES = "missing_itr_roles"
  //   if (allParticipants.itr_roles.length === 0) {
  //     errors.push(t('manager.iterationConfig.noRoles'));
  //   }

  //   // # At least one user required.
  //   // MISSING_ITR_USERS = "missing_itr_users"
  //   if (allParticipants.itr_prefs.length === 0) {
  //     errors.push(t('manager.iterationConfig.noParticipants'));
  //   }

  //   // # Empty slot subset a slot subset must include at least 1 slot.
  //   // EMPTY_SLOT_SUBSET = "empty_slot_subset"
  //   const slotSubsetsAreEmpty = Object.values(slotSubsets.slot_subsets).some(
  //     slotSubset => slotSubset.slots.length === 0,
  //   );
  //   if (slotSubsetsAreEmpty) {
  //     errors.push(t('manager.iterationConfig.noEmptySubsetSlots'));
  //   }

  //   if (errors.length > 0) {
  //     setError(errors.join(' | '));
  //     return false;
  //   }

  //   runAlgMutation.mutate();
  //   return true;
  // };

  const deleteResults = useMutation({
    mutationFn: () =>
      request({
        url: `/manager/itrs/${id}/flow/verif_to_config`,
        method: 'post',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] });
      queryClient.invalidateQueries({ queryKey: ['status', id] });
      navigate(`/manager/iteration/${id}/configuration`);
    },
    onError: () => {
      setError(t('generic.error'));
    },
  });

  const publishResults = useMutation({
    mutationFn: send_users_notification =>
      request({
        url: `/manager/itrs/${id}/flow/publish`,
        method: 'post',
        data: { send_users_notification },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] });
      queryClient.invalidateQueries({ queryKey: ['status', id] });
      setPublished(true);
    },
    onError: () => {
      setError(t('generic.error'));
    },
  });

  const unpublishResults = useMutation({
    mutationFn: () =>
      request({ url: `/manager/itrs/${id}/flow/unpublish`, method: 'post' }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] });
      queryClient.invalidateQueries({ queryKey: ['status', id] });
      setPublished(false);
    },
    onError: () => {
      setError(t('generic.error'));
    },
  });

  const archiveItr = useMutation({
    mutationFn: () =>
      request({ url: `/manager/itrs/${id}/flow/close`, method: 'post' }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] });
      queryClient.invalidateQueries({ queryKey: ['status', id] });
      setArchived(true);
    },
    onError: () => {
      setError(t('generic.error'));
    },
  });

  const unarchiveItr = useMutation({
    mutationFn: () =>
      request({ url: `/manager/itrs/${id}/flow/unarchive`, method: 'post' }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] });
      queryClient.invalidateQueries({ queryKey: ['status', id] });
      setArchived(false);
      setPublished(true);
    },
    onError: () => {
      setError(t('generic.error'));
    },
  });

  const editMessage = useMutation({
    mutationFn: () =>
      request({
        url: `/manager/itrs/${id}/edit_message`,
        method: 'patch',
        data: { message },
      }),
    onSuccess: () => {
      setMessageSaved(true);
    },
    onError: () => {
      setError('TODO');
    },
  });

  // const errorsReturned =
  //   (algorithmError || postChecks) &&
  //   returnErrors(
  //     allShifts,
  //     allParticipants,
  //     slotSubsets,
  //     algorithmError,
  //     postChecks,
  //     t,
  //   );
  // const errors = errorsReturned?.output;
  // const infeasible = errorsReturned?.infeasible;

  // useEffect(() => {
  //   // eslint-disable-next-line no-unused-expressions
  //   locked && setToggleEnabled(locked);
  // }, [locked]);

  // if (isLoading) {
  //   return <p>{t('generic.loading')}</p>;
  // }

  const configuration = status === 'configuration';
  // const verification = status === 'verification';

  const nameAndDate = `${chain.chain_name}: ${parseIterationDates(start_day, end_day, itr_type)}`;

  return (
    <Wrapper>
      {configPreviewOpen ? (
        <ConfigurationPreview
          open={configPreviewOpen}
          setOpen={setConfigPreviewOpen}
        />
      ) : null}
      <PublishOrDelete
        open={warningOpen}
        setOpen={setWarningOpen}
        deleteResults={deleteResults}
        publishResults={publishResults}
        publish={publish}
      />
      <Unpublish
        open={unpublishOpen}
        setOpen={setUnpublishOpen}
        unpublishResults={unpublishResults}
      />
      <DeleteItr
        open={deleteItrOpen}
        setOpen={setDeleteItrOpen}
        id_itr={id_itr}
        setSuccess={setSuccess}
      />
      <ArchiveConfirmation
        open={archiveOpen}
        setOpen={setArchiveOpen}
        archiveItr={archiveItr}
      />
      <div className="flex flex-row justify-between mb-3 items-center">
        <p className="text-2xl text-blue-600 font-bold">{nameAndDate}</p>
        {configuration ? (
          <div className="flex flex-row gap-2">
            <button
              type="button"
              className="block text-xl rounded-xl bg-red-600 py-2 px-6 text-center font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              onClick={() => setDeleteItrOpen(true)}
            >
              {t('manager.iterationLayouts.delete')}
            </button>
            <button
              type="button"
              className="block text-xl rounded-xl bg-blue-600 disabled:bg-blue-600/40 py-2 px-6 text-center font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              disabled={!appOptions?.app_config.allow_alg_executions}
              // onClick={() => preRunningChecks()}
            >
              {t('manager.iterationLayouts.calculate')}
            </button>
            <Link
              to={`/manager/iteration/${id}/manual`}
              className="block text-xl rounded-xl bg-blue-600 disabled:bg-blue-600/40 py-2 px-6 text-center font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              {t('manager.iterationLayouts.manual')}
            </Link>
          </div>
        ) : null}
        {/* {verification ? (
          <div className="flex flex-row gap-2">
            <button
              type="button"
              className="block text-base rounded-xl bg-red-600 py-2 px-4 text-center text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              onClick={() => {
                setPublish(false);
                setWarningOpen(true);
              }}
            >
              {t('manager.iterationLayouts.eliminateResults')}
            </button>
            <button
              type="button"
              className="block text-xl rounded-xl bg-blue-600 py-2 px-6 text-center font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={() => {
                setPublish(true);
                setWarningOpen(true);
              }}
            >
              {t('manager.iterationLayouts.publish')}
            </button>
          </div>
        ) : null} */}
        {published && !archived ? (
          <div className="flex flex-row gap-2">
            <button
              type="button"
              className="block text-base rounded-xl bg-gray-600 py-2 px-4 text-center text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              onClick={() => {
                setArchiveOpen(true);
              }}
            >
              {t('generic.archive')}
            </button>
            <button
              type="button"
              className="block text-base rounded-xl bg-amber-600 py-2 px-4 text-center text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              onClick={() => {
                setUnpublishOpen(true);
              }}
            >
              {t('generic.unpublish')}
            </button>
          </div>
        ) : null}
        {archived ? (
          <button
            type="button"
            className="block text-base rounded-xl bg-gray-600 py-2 px-4 text-center text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            onClick={() => {
              unarchiveItr.mutate();
            }}
          >
            {t('generic.unarchive')}
          </button>
        ) : null}
      </div>
      {archived ? (
        <div className="bg-gray-400 h-8 rounded-md flex justify-center items-center text-white font-bold">
          {t('generic.archived')}
        </div>
      ) : null}
      <Steps
        currentStep={status}
        // failed={algorithmError}
        failed={false}
        setOpen={setConfigPreviewOpen}
      />
      {error ? <Alert text={error} success={false} /> : null}
      {configuration ? (
        <div className="mt-10 my-5 flex justify-end">
          <Toggle enabled={toggleEnabled} setEnabled={setToggleEnabled} />
        </div>
      ) : null}
      {success ? <Alert success text={success} /> : null}
      {published ? (
        <Alert success text={t('manager.iterationLayouts.success')} />
      ) : null}
      {configuration || published ? (
        <div className="flex items-end mb-5">
          <div className="relative flex-1">
            <label
              htmlFor="message"
              className="absolute -top-1 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
            >
              {t('manager.iterationLayouts.message')}
            </label>
            <div className="mt-2">
              <textarea
                name="message"
                id="message"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                placeholder={t('manager.iterationLayouts.messagePlaceholder')}
                maxLength={250}
                rows={1}
                value={message || undefined}
                onChange={e => {
                  setMessageSaved(false);
                  setMessage(e.target.value);
                }}
              />
            </div>
          </div>
          <button
            type="button"
            disabled={messageSaved || message === itrMessage}
            className="ml-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-400"
            onClick={() => editMessage.mutate()}
          >
            {t('generic.save')}
          </button>
        </div>
      ) : null}

      {/* {(algorithmError && configuration) ||
      (postChecks &&
        Object.keys(postChecks).length !== 0 &&
        (verification || published)) ? (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-red-100 px-4 mt-2 py-2 mb-4 text-left text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75">
                {algorithmError ? (
                  <span>
                    Hay errores en la configuración de la iteración. Despliega
                    para conocer más detalles.
                  </span>
                ) : (
                  <span>
                    El reparto no cumple los requisitos. Despliega para conocer
                    más detalles.
                  </span>
                )}
                <ChevronUpIcon
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-red-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-4 text-sm text-gray-500">
                {errors.map(error => (
                  <div className="flex flex-row items-center mt-3 gap-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 text-red-600"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <p className="max-w-[95%]">{error}</p>
                  </div>
                ))}
                {infeasible.map(comb => (
                  <div className="flex flex-row items-center mt-3 ml-3 gap-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-amber-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                      />
                    </svg>
                    <p>{comb}</p>
                  </div>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ) : null} */}
      {/* <Outlet context={[status, locked, errors, infeasible, nameAndDate]} /> */}
      <Outlet
        context={
          {
            status,
            locked,
            errors: undefined,
            infeasible: undefined,
            nameAndDate,
          } satisfies ItrContextType
        }
      />
    </Wrapper>
  );
}

export function useItrContext() {
  return useOutletContext<ItrContextType>();
}
