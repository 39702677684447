import { Listbox } from '@headlessui/react';
import { ColorIndex, ColorName, returnColor } from '@youshift/shared/utils';
import { useTranslation } from 'react-i18next';

type ShadePickerProps = {
  color: ColorName;
  shade: ColorIndex;
  onChange: (color: ColorIndex) => void;
};

function ShadePicker({ color, shade, onChange }: ShadePickerProps) {
  const { t } = useTranslation();

  const shades = [
    50, 100, 200, 300, 400, 500, 600, 700, 800, 900,
  ] as ColorIndex[];

  return (
    <Listbox value={shade} onChange={onChange}>
      <div className="relative">
        <Listbox.Button className="relative cursor-pointer rounded-md p-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center">
          <span
            className="inline-block w-6 h-6 rounded-full mr-3"
            style={{
              backgroundColor: returnColor(color, shade),
            }}
          />
        </Listbox.Button>
        <Listbox.Options className="absolute mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          {shades.map(shade => (
            <Listbox.Option
              key={shade}
              className={({ active }) =>
                `${active ? 'text-white bg-blue-600' : 'text-gray-900'}
                cursor-pointer select-none relative py-2 pl-3 pr-9 flex items-center`
              }
              value={shade}
            >
              {({ selected, active }) => (
                <>
                  <span
                    className="inline-block w-6 h-6 rounded-full mr-3"
                    style={{
                      backgroundColor: returnColor(color, shade),
                    }}
                  />
                  {selected ? (
                    <span
                      className={`${
                        active ? 'text-white' : 'text-blue-600'
                      } absolute inset-y-0 right-0 flex items-center pr-4`}
                    >
                      ✓
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
}

export default ShadePicker;
