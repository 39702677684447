import { useState } from 'react';
import {
  useDeleteSectionMutation,
  useEditSectionMutation,
} from '@youshift/shared/hooks/mutations';
import { Section } from '@youshift/shared/types';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { ColorName } from '@youshift/shared/utils';
import { useTranslation } from 'react-i18next';

import { YSButton } from '../Buttons';
import Modal from '../Modal';
import ColorPicker from '../ColorPicker';

interface EditSectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  details: Section;
}

export default function EditSectionModal({
  isOpen,
  onClose,
  details,
}: EditSectionModalProps) {
  const {
    name: sectionName,
    acronym: sectionAcronym,
    color: sectionColor,
    id_itr,
    id_section,
  } = details;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [name, setName] = useState(sectionName);
  const [acronym, setAcronym] = useState(sectionAcronym);
  const [color, setColor] = useState<ColorName>(sectionColor);
  const editSectionMutation = useEditSectionMutation(queryClient, {
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({
        queryKey: ['section', id_section.toString()],
      });
    },
    onError: (error: Error) => {
      // Handle the error as needed
    },
  });

  const deleteSectionMutation = useDeleteSectionMutation(queryClient, {
    onSuccess: () => {
      onClose();
      navigate('..');
    },
    onError: (error: Error) => {
      // Handle the error as needed
    },
  });

  const handleSave = () => {
    editSectionMutation.mutate({
      id_itr,
      id_section,
      name,
      acronym,
      color,
    });
  };

  const handleDelete = () => {
    deleteSectionMutation.mutate({
      id_itr,
      id_section,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      editButtons
      handleDelete={handleDelete}
      handleSave={handleSave}
    >
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">Editar Sección</h2>
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            {t('manager.servicesConfig.name')}
          </label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="acronym"
            className="block text-sm font-medium text-gray-700"
          >
            {t('manager.servicesConfig.acronym')}
          </label>
          <input
            id="acronym"
            type="text"
            value={acronym}
            maxLength={5}
            onChange={e => setAcronym(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="color"
            className="block text-sm font-medium text-gray-700"
          >
            {t('generic.color')}
          </label>
          <ColorPicker value={color} onChange={setColor} />
        </div>
      </div>
    </Modal>
  );
}
