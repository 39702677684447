import React, { useMemo, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { request } from '@youshift/shared/api';
import { itrRolesQuery } from '@youshift/shared/hooks/jsQueries';

import CustomCombobox from '../../components/ManualAssignment/Combobox';
import ToolDrawer from '../../components/ManualAssignment/ToolDrawer';
import { requireManager } from '../../utils/checks';
import {
  addDays,
  getCustomDateRange,
  getDifferenceInHours,
} from '../../utils/helpers';
import { sampleOutput as sampleShifts } from './sample';

const fetchMockSectionQuery = id => ({
  queryKey: ['mock', id],
  queryFn: async () => request({ url: `/manager/itrs/${id}/mock_section`, method: 'get' }),
});

export const manualAssignmentLoader = queryClient => async ({ params }) => {
  await requireManager(queryClient);
  // eslint-disable-next-line no-unused-vars
  const mock = fetchMockSectionQuery(params.id);
  const itrPrefs = itrRolesQuery(params.id);
  const itrPrefsFetch = queryClient.getQueryData(itrPrefs.queryKey)
    ?? (await queryClient.fetchQuery(itrPrefs));
  return {
    mock:
      queryClient.getQueryData(mock.queryKey)
      ?? (await queryClient.fetchQuery(mock)),
    itrPrefs: itrPrefsFetch.itr_prefs,
  };
};

const people = [
  {
    id: 1,
    value: 'Tom Cook',
    preference: 'V',
    points: 0,
  },
  {
    id: 2,
    value: 'Wade Cooper',
    preference: 'P',
    points: 10,
  },
  {
    id: 3,
    value: 'Tanya Fox',
    preference: 'E',
    points: 0,
  },
  {
    id: 4,
    value: 'Arlene Mccoy',
    preference: 'P',
    points: -10,
  },
  {
    id: 5,
    value: 'Devon Webb',
    preference: 'B',
    points: 0,
  },
];

export default function ManualAssignment() {
  const { idItr: id } = useParams();
  const { mock, itrPrefs } = useLoaderData();
  const {
    itr_info: { itr_end, itr_start },
    shifts,
  } = mock;
  const weeks = getCustomDateRange(itr_start, itr_end);
  const RESOLUTION = 1;

  const [drawerOpen, setDrawerOpen] = useState(true);

  const arrangeSectionSlots = React.useCallback(slots => {
    const slotArray = Object.values(slots).map(slot => ({
      ...slot,
      start: new Date(slot.start),
      end: new Date(slot.end),
    }));

    // Sort the slots by their start time
    slotArray.sort((a, b) => {
      if (a.start - b.start === 0) {
        return b.end - a.end; // Longer duration first
      }
      return a.start - b.start;
    });
    // Array to hold the rows, each row is an array of non-overlapping slots
    const rows = [];

    // Iterate over each slot
    for (const slot of slotArray) {
      let placed = false;
      // Try to place the slot in an existing row
      for (const row of rows) {
        if (row[row.length - 1].end <= slot.start) {
          row.push(slot);
          placed = true;
          break;
        }
      }
      // If the slot couldn't be placed in any existing row, create a new row
      if (!placed) {
        rows.push([slot]);
      }
    }
    return rows;
  }, []);

  const divideSlotsIntoWeeks = slots => {
    const slotsByWeek = weeks.map(() => []);

    Object.keys(slots).forEach(slotKey => {
      const slot = slots[slotKey];
      const startTime = new Date(slot.start);
      const endTime = new Date(slot.end);
      for (let weekIndex = 0; weekIndex < weeks.length; weekIndex++) {
        const week = weeks[weekIndex];
        const weekStart = new Date(week[0]);
        const weekEnd = weekIndex < weeks.length - 1
          ? new Date(weeks[weekIndex + 1][0])
          : addDays(new Date(week[6]), 1);

        if (weekStart <= startTime && startTime < weekEnd) {
          if (endTime <= weekEnd) {
            // Non-spanning slot: starts and ends within the week
            slotsByWeek[weekIndex].push({ ...slot, slot_id: slotKey });
          } else {
            // Spanning slot: split the slot
            const splitSlot1 = {
              ...slot,
              end: weekEnd,
              slot_id: slotKey,
            };
            const splitSlot2 = {
              ...slot,
              start: weekEnd,
              slot_id: slotKey,
            };
            slotsByWeek[weekIndex].push(splitSlot1);
            if (weekIndex < weeks.length - 1) {
              slotsByWeek[weekIndex + 1].push(splitSlot2);
            }
          }
          break; // Exit the loop since the slot has been handled
        }
      }
    });
    return slotsByWeek;
  };

  const people2 = useMemo(
    () => itrPrefs.map(({
      user: {
        email, firstname, id, lastname,
      },
    }) => ({
      email,
      name: `${firstname} ${lastname}`,
      id,
    })),
    [itrPrefs],
  );

  console.log(people2, itrPrefs);

  return (
    <div>
      {drawerOpen ? null : (
        <button
          onClick={() => setDrawerOpen(true)}
          className="absolute bg-white text-gray-800 right-0 border border-gray-400 shadow-md rounded-md p-3"
        >
          <ChevronLeftIcon className="w-5 h-5" />
        </button>
      )}
      <ToolDrawer open={drawerOpen} setOpen={setDrawerOpen} people={people2} />
      {weeks.map((week, weekIdx) => (
        <div
          className="grid auto-cols-auto auto-rows-min mb-2 pb-2"
          style={{ gridTemplateColumns: 'minmax(75px, auto) repeat(7, 1fr)' }}
        >
          <p />
          {week.map(day => (
            <div className="border text-white p-1 rounded-md bg-blue-600">
              <p className="text-center font-bold">
                {day.toLocaleString(undefined, {
                  day: '2-digit',
                  timeZone: 'GMT',
                })}
              </p>
            </div>
          ))}
          {Object.values(sampleShifts).map((shift, idx) => {
            const slotsPerWeek = divideSlotsIntoWeeks(shift.section_slots);
            return (
              <>
                <div
                  className={`flex justify-center items-center border-b-2 bg-teal-500/90 ${idx === Object.values(sampleShifts).length - 1 ? 'rounded-b-md' : idx === 0 ? 'rounded-t-md' : ''}`}
                >
                  <p className="font-semibold">{shift.acronym}</p>
                </div>
                <div className="col-span-7 grid text-center auto-rows-min border-l">
                  {arrangeSectionSlots(slotsPerWeek[weekIdx]).map(
                    (row, rowIndex) => (
                      <div
                        key={rowIndex}
                        className="grid"
                        style={{
                          gridTemplateColumns: `repeat(${168 / RESOLUTION}, minmax(5px, 1fr))`,
                        }}
                      >
                        {row.map(slot => {
                          const diffFromStart = getDifferenceInHours(
                            week[0],
                            slot.start,
                          );
                          const duration = getDifferenceInHours(
                            slot.start,
                            slot.end,
                          );
                          const maxNeedsArray = Array.from(
                            { length: slot.max_need },
                            (_, idx) => idx,
                          );
                          return (
                            <div
                              key={slot.start}
                              className="border border-gray-300 rounded-lg bg-gray-100 shadow-md relative items-center"
                              style={{
                                gridColumn: `${diffFromStart + 1} / span ${duration}`,
                              }}
                            >
                              <div className="relative flex flex-row gap-1 justify-center top-1">
                                <p className="text-gray-700 text-xxs">
                                  {slot.start.toLocaleString(undefined, {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    timeZone: 'GMT',
                                  })}
                                  {' - '}
                                  {slot.end.toLocaleString(undefined, {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    timeZone: 'GMT',
                                  })}
                                </p>
                              </div>
                              <div className="text-start px-1 text-xs flex flex-col gap-1 my-2">
                                {maxNeedsArray.map(needIdx => (
                                  <CustomCombobox
                                    data={people}
                                    needIdx={needIdx}
                                    slot={slot}
                                  />
                                ))}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ),
                  )}
                </div>
              </>
            );
          })}
        </div>
      ))}
    </div>
  );
}
